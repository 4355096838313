export default [
  {
    path: '/settings/radar/devices',
    name: 'radar-list',
    component: () => import('@/views/settings/devices/List.vue'),
    props: true,
    meta: {
      pageTitle: 'DeviceList',
      breadcrumb: [
        {
          text: 'DeviceList',
          active: true,
        },
      ],
      resource: 'Basic',
      type: 'radar',
    },
  },
  {
    path: '/settings/:type/devices',
    name: 'speeding-list',
    component: () => import('@/views/settings/devices/List.vue'),
    props: true,
    meta: {
      pageTitle: 'DeviceList',
      breadcrumb: [
        {
          text: 'DeviceList',
          active: true,
        },
      ],
      resource: 'Device',
    },
  },
  {
    path: '/settings/:type/devices',
    name: 'cyclist-list',
    component: () => import('@/views/settings/devices/List.vue'),
    props: true,
    meta: {
      pageTitle: 'DeviceList',
      breadcrumb: [
        {
          text: 'DeviceList',
          active: true,
        },
      ],
      resource: 'Device',
    },
  },
  {
    path: '/settings/:type/devices',
    name: 'vehicle-list',
    component: () => import('@/views/settings/devices/List.vue'),
    props: true,
    meta: {
      pageTitle: 'DeviceList',
      breadcrumb: [
        {
          text: 'DeviceList',
          active: true,
        },
      ],
      resource: 'Device',
    },
  },
  {
    path: '/settings/:type/devices/create',
    name: 'device-create',
    component: () => import('@/views/settings/devices/Create.vue'),
    meta: {
      pageTitle: 'DeviceCreate',
      breadcrumb: [
        {
          text: 'DeviceCreate',
          active: true,
        },
      ],
      resource: 'Device',
    },
  },
  {
    path: '/settings/ftp',
    name: 'ftp-credentials',
    component: () => import('@/views/settings/radar/FtpCredentials.vue'),
    meta: {
      pageTitle: 'FtpCredentials',
      breadcrumb: [
        {
          text: 'FtpCredentials',
          active: true,
        },
      ],
      resource: 'Device',
    },
  },
  {
    path: '/settings/ftp/create/:id?',
    name: 'ftp-details',
    component: () => import('@/views/settings/radar/FtpCreate.vue'),
    meta: {
      pageTitle: 'FtpCreate',
      breadcrumb: [
        {
          text: 'FtpCreate',
          active: true,
        },
      ],
      resource: 'Device',
    },
  },
  {
    path: '/settings/users',
    name: 'users',
    component: () => import('@/views/settings/general/Users.vue'),
    meta: {
      pageTitle: 'UsersList',
      breadcrumb: [
        {
          text: 'UsersList',
          active: true,
        },
      ],
      resource: 'User',
    },
  },
  {
    path: '/settings/users/:id?',
    name: 'user-details',
    component: () => import('@/views/settings/general/UserCreate.vue'),
    meta: {
      pageTitle: 'UserCreate',
      breadcrumb: [
        {
          text: 'UserCreate',
          active: true,
        },
      ],
      resource: 'User',
    },
  },
  {
    path: '/settings/companies',
    name: 'companies',
    component: () => import('@/views/settings/general/Companies.vue'),
    meta: {
      pageTitle: 'CompaniesList',
      breadcrumb: [
        {
          text: 'CompaniesList',
          active: true,
        },
      ],
    },
  },
  {
    path: '/settings/companies/:companyId/users',
    name: 'company-users',
    component: () => import('@/views/settings/general/CompanyUsers.vue'),
    meta: {
      pageTitle: 'CompanyUsersList',
      breadcrumb: [
        {
          text: 'CompanyUsersList',
          active: true,
        },
      ],
    },
  },
  {
    path: '/settings/companies/:id?',
    name: 'company-details',
    component: () => import('@/views/settings/general/CompanyCreate.vue'),
    meta: {
      pageTitle: 'CompanyCreate',
      breadcrumb: [
        {
          text: 'CompanyCreate',
          active: true,
        },
      ],
    },
  },
  {
    path: '/settings/:companyId/groups',
    name: 'groups',
    component: () => import('@/views/settings/general/Groups.vue'),
    meta: {
      pageTitle: 'GroupsList',
      breadcrumb: [
        {
          text: 'GroupsList',
          active: true,
        },
      ],
    },
  },
  {
    path: '/settings/groups/:groupId/users',
    name: 'group-users',
    component: () => import('@/views/settings/general/GroupUsers.vue'),
    meta: {
      pageTitle: 'GroupUsersList',
      breadcrumb: [
        {
          text: 'GroupUsersList',
          active: true,
        },
      ],
    },
  },
  {
    path: '/settings/:companyId/groups/:id?',
    name: 'group-details',
    component: () => import('@/views/settings/general/GroupCreate.vue'),
    meta: {
      pageTitle: 'GroupCreate',
      breadcrumb: [
        {
          text: 'GroupCreate',
          active: true,
        },
      ],
    },
  },
  {
    path: '/settings/users/:userId/remotegui/:id?',
    name: 'remotegui-details',
    component: () => import('@/views/settings/general/RemoteGuiCreate.vue'),
    meta: {
      pageTitle: 'RemoteGuiCreate',
      breadcrumb: [
        {
          text: 'RemoteGuiCreate',
          active: true,
        },
      ],
    },
  },
  {
    path: '/settings/audit/',
    name: 'audit-log',
    component: () => import('@/views/settings/general/AuditLog.vue'),
    meta: {
      pageTitle: 'AuditLog',
      breadcrumb: [
        {
          text: 'AuditLog',
          active: true,
        },
      ],
    },
  },
  /*{
    path: '/settings/audit/action',
    name: 'action-audit-log',
    component: () => import('@/views/settings/general/ActionAuditLog.vue'),
    meta: {
      pageTitle: 'ActionAuditLog',
      breadcrumb: [
        {
          text: 'ActionAuditLog',
          active: true,
        },
      ],
    },
  },*/
  {
    path: '/settings/ldap/',
    name: 'ldap',
    component: () => import('@/views/settings/general/LdapSettings.vue'),
    meta: {
      pageTitle: 'LDAP',
      breadcrumb: [
        {
          text: 'LDAP',
          active: true,
        },
      ],
    },
  },
  /*{
    path: '/settings/general/',
    name: 'general-settings',
    component: () => import('@/views/settings/general/GeneralSettings.vue'),
    meta: {
      pageTitle: 'General',
      breadcrumb: [
        {
          text: 'General settings',
          active: true,
        },
      ],
    },
  },*/
  {
    path: '/settings/:type/iframe',
    name: 'iframe-list',
    component: () => import('@/views/settings/devices/Iframe.vue'),
    props: true,
    meta: {
      resource: 'Device',
      pageTitle: 'IframeList',
      breadcrumb: [
        {
          text: 'IframeList',
          active: true,
        },
      ],
    },
  },
  {
    path: '/settings/:type/iframes/create/:id?',
    name: 'iframe-create',
    component: () => import('@/views/settings/devices/CreateIframe.vue'),
    meta: {
      resource: 'Device',
      pageTitle: 'IframeCreate',
      breadcrumb: [
        {
          text: 'IframeCreate',
          active: true,
        },
      ],
    },
  },
  {
    path: '/settings/manual/',
    name: 'settings-manual',
    component: () => import('@/views/settings/general/SettingsManual.vue'),
    meta: {
      pageTitle: 'SettingsManual',
      breadcrumb: [
        {
          text: 'SettingsManual',
          active: true,
        },
      ],
    },
  },
];
