<template>
  <div>
    <b-form-group
      label-cols="4"
      label-cols-lg="3"
      :label="$t('vehicle.layout_type')"
    >
      <b-form-select
        class="bordered"
        v-model="selectedState"
        :options="stateOptions"
        @change="layoutChanged"
      />
    </b-form-group>
    <b-form-group
      label-cols="4"
      label-cols-lg="3"
      :label="$t('vehicle.correction_factor')"
    >
      <b-form-input
        disabled
        placeholder=""
        v-model="settings.correctionFactor"
      />
    </b-form-group>
    <b-form-group
      label-cols="4"
      label-cols-lg="3"
      :label="$t('vehicle.detection_range')"
    >
      <b-form-input disabled placeholder="" v-model="settings.detectionRange" />
    </b-form-group>

    <b-form-group
      label-cols="4"
      label-cols-lg="3"
      :label="$t('vehicle.distance_from_carriageway')"
    >
      <b-form-input placeholder="" v-model="settings.distanceFromEdge" />
    </b-form-group>

    <div class="d-flex justify-content-between">
      <span>{{ $t('last_updated') }} {{ lastUpdated }}</span>
      <div class="float-right mb-2">
        <b-button @click="updateData" variant="primary">{{
          $t('save')
        }}</b-button>
        <b-button @click="refreshData" variant="outline-primary" class="ml-1">{{
          $t('refresh')
        }}</b-button>
      </div>
    </div>
  </div>
</template>

<script>
import {
  BFormGroup,
  BFormInput,
  BButton,
  BFormSelect,
  BRow,
  BCol,
} from 'bootstrap-vue';
import { onMounted, ref, computed } from '@vue/composition-api';
import i18n from '@/libs/i18n';
import { useToast } from 'vue-toastification/composition';
import { dangerToast, successToast } from '@/utils/toast';
import store from '@/store';
import { formatDateFromAPI } from '@/utils';

export default {
  props: {
    id: {
      type: String,
    },
  },
  components: {
    BFormGroup,
    BFormInput,
    BButton,
    BFormSelect,
    BRow,
    BCol,
  },
  setup(props, context) {
    const toast = useToast();

    const settings = ref({});
    const selectedState = ref({});
    const stateOptions = [
      {
        value: '1',
        text: i18n.t('generalSettings.measure_layout.above_height'),
      },
      { value: '2', text: i18n.t('generalSettings.measure_layout.in_height') },
    ];

    const lastUpdated = computed(() => {
      return settings.value.updatedAt
        ? formatDateFromAPI(settings.value.updatedAt)
        : '- x';
    });

    onMounted(async () => {
      try {
        const response = await store.dispatch(
          'devices/GET_DEVICE_VEHICLE',
          context.root.$route.params.id,
        );

        settings.value = response.data;

        selectedState.value = settings.value.measurementHeight;
      } catch (e) {
        dangerToast(toast, i18n.t('failed'), i18n.t(e.response.data.message));
      }
    });

    const updateData = async () => {
      try {
        await store.dispatch('devices/UPDATE_VEHICLE_CORRECTION_FACTOR', {
          deviceId: context.root.$route.params.id,
          data: {
            measurementHeight: selectedState.value,
            distanceFromEdge: settings.value.distanceFromEdge,
          },
        });

        successToast(
          toast,
          i18n.t('success'),
          i18n.t('update_success_descirption'),
        );
      } catch (e) {
        dangerToast(toast, i18n.t('failed'), i18n.t(e.response.data.message));
      }
    };

    const refreshData = async () => {
      try {
        const response = await store.dispatch(
          'devices/REFRESH_VEHICLE_CORRECTION_FACTOR',
          {
            deviceId: context.root.$route.params.id,
            data: {
              measurementHeight: selectedState.value,
              distanceFromEdge: settings.value.distanceFromEdge,
            },
          },
        );

        if (response) {
          settings.value = response.data;
          selectedState.value = settings.value.measurementHeight;
        }

        successToast(
          toast,
          i18n.t('success'),
          i18n.t('update_success_descirption'),
        );
      } catch (e) {
        dangerToast(toast, i18n.t('failed'), i18n.t(e.response.data.message));
      }
    };

    const layoutChanged = () => {
      const layout = {
        1: {
          correctionFactor: 1.4407,
          detectionRange: 4,
        },
        2: {
          correctionFactor: 1.4142,
          detectionRange: 16,
        },
      };
      const factor = layout[selectedState.value];
      if (factor) {
        settings.value.correctionFactor = factor.correctionFactor;
        settings.value.detectionRange = factor.detectionRange;
      }
    };

    return {
      selectedState,
      stateOptions,
      lastUpdated,
      settings,
      updateData,
      layoutChanged,
      refreshData,
    };
  },
};
</script>

<style></style>
