<template>
  <b-card :title="title">
    <div class="d-flex justify-content-between flex-wrap">
      <div class="mb-1 mb-sm-0">
        <span class="text-muted">{{ subtitle }}</span>
      </div>
      <div class="d-flex align-items-center" v-if="!nodatepicker">
        <slot name="additional-tools"></slot>
        <date-range-picker class="ml-2" @date-changed="dateChanged">
        </date-range-picker>
      </div>
    </div>

    <!-- echart -->
    <!--<app-echart-line :option-data="option" />-->
    <vue-apex-charts
      type="line"
      height="400"
      :options="graphOption"
      :series="option.series"
    />
  </b-card>
</template>

<script>
import { BCard, BBadge } from 'bootstrap-vue';
import AppEchartLine from '@core/components/charts/echart/AppEchartLine.vue';
import DateRangePicker from './DateRangePicker.vue';
import VueApexCharts from 'vue-apexcharts';

export default {
  components: {
    BCard,
    AppEchartLine,
    BBadge,
    DateRangePicker,
    VueApexCharts,
  },
  props: {
    option: {
      type: Object,
    },
    title: {
      type: String,
    },
    subtitle: {
      type: String,
    },
    nodatepicker: {
      type: Boolean,
    },
  },
  data() {
    return {
      graphOption: {
        stroke: {
          width: 2,
        },
        chart: {
          type: 'bar',
          toolbar: {
            show: false,
          },
          zoom: {
            enabled: false,
          },
        },
        xaxis: {
          type: 'datetime',
          tooltip: {
            enabled: true,
          },
        },
        yaxis: {
          tooltip: {
            enabled: true,
          },
          labels: {
            formatter(val) {
              return parseFloat(val).toFixed(0);
            },
          },
        },
        tooltip: {
          x: {
            show: true,
            format: 'dd.MM.yyyy',
            formatter: undefined,
          },
        },

        grid: {
          xaxis: {
            lines: {
              show: true,
            },
            categories: ['Test'],
          },
        },
        legend: {
          show: true,
        },
      },
    };
  },
  methods: {
    dateChanged(dateRange) {
      this.$emit('date-changed', dateRange);
    },
  },
};
</script>
