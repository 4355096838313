<template>
  <div class="d-flex align-items-center mb-2 justify-content-between">
    <div style="font-size: 18px">{{ $t('statistics') }} - {{ deviceName }}</div>
    <div class="d-flex align-items-center justify-content-between">
      <slot name="additional-item"></slot>
      <v-select
        v-if="vehicleDropdown"
        v-model="vehicleType"
        :reduce="veh => veh.code"
        :options="vehicleTypeOptions"
        :clearable="false"
        class="per-page-selector d-inline-block mx-50 select-width"
        style="background-color: white"
        @input="vehicleTypeChanged"
      >
        <div slot="no-options">{{ $t('no-options') }}</div>
      </v-select>
      <v-select
        v-if="directionDropdown"
        :reduce="dir => dir.code"
        v-model="direction"
        :options="directionOptions"
        :clearable="false"
        class="per-page-selector d-inline-block mx-50 select-width"
        style="background-color: white"
        @input="directionChanged"
      >
        <div slot="no-options">{{ $t('no-options') }}</div>
      </v-select>
      <date-range-picker
        v-if="datepicker"
        class="ml-1"
        @date-changed="dateChanged"
        :style="{ border: compare ? '1px solid #0391e5' : 'none' }"
      >
      </date-range-picker>
      <div class="d-flex" v-if="compare != null">
        <date-range-picker
          :style="{ border: compare ? '1px solid red' : 'none' }"
          v-if="datepicker && compare"
          class="ml-1 mr-1"
          @date-changed="compareDateChanged"
        >
        </date-range-picker>
        <b-button
          @click="changeCompareState"
          style="padding: 10px"
          :variant="compare ? 'primary' : 'outline-secondary'"
          ><feather-icon icon="RepeatIcon" size="12"
        /></b-button>
      </div>
    </div>
  </div>
</template>

<script>
import DateRangePicker from '@/views/components/DateRangePicker.vue';
import vSelect from 'vue-select';
import { BButton } from 'bootstrap-vue';
export default {
  components: {
    DateRangePicker,
    vSelect,
    BButton,
  },
  props: {
    datepicker: {
      type: Boolean,
    },
    directionDropdown: {
      type: Boolean,
      default: false,
    },
    vehicleDropdown: {
      type: Boolean,
      default: false,
    },
    compare: {
      type: Boolean,
      default: null,
    },
  },
  data() {
    return {
      direction: null,
      directionOptions: [
        { label: this.$t('show_both_directions'), code: null },
        { label: this.$t('incoming'), code: '0' },
        { label: this.$t('outgoing'), code: '1' },
      ],
      vehicleType: null,
      vehicleTypeOptions: [
        { label: this.$t('vehicle.all'), code: null },
        { label: this.$t('vehicle.single_track'), code: '1' },
        { label: this.$t('vehicle.car'), code: '2' },
        { label: this.$t('vehicle.combined'), code: '3' },
        { label: this.$t('vehicle.truck'), code: '4' },
        { label: this.$t('vehicle.truck_with_trailer'), code: '5' },
      ],
    };
  },
  computed: {
    deviceName() {
      return this.$store.state.devices.currentDevice
        ? this.$store.state.devices.currentDevice.name
        : '';
    },
  },
  methods: {
    dateChanged(date) {
      this.$emit('dateChanged', date);
    },
    compareDateChanged(date) {
      this.$emit('compareDateChanged', date);
    },
    vehicleTypeChanged(vehicleType) {
      this.$emit('vehicleTypeChanged', vehicleType);
    },
    directionChanged(direction) {
      this.$emit('directionChanged', direction);
    },
    changeCompareState() {
      this.$emit('compareChanged', !this.compare);
    },
  },
};
</script>

<style></style>
