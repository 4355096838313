import { ref, watch, computed, onMounted } from '@vue/composition-api';
import store from '@/store';

// Notification
import { useToast } from 'vue-toastification/composition';
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue';

export default function useDataList(props) {
  // Use toast
  const toast = useToast();

  const perPage = ref(10);
  const totalData = ref(0);
  const currentPage = ref(1);
  const perPageOptions = [1, 10, 25, 50, 100];
  const searchQuery = ref('');
  const sortBy = ref(props.columnSortBy ? props.columnSortBy : 'id');
  const isSortDirDesc = ref(
    props.columnIsSortDirDesc ? props.columnIsSortDirDesc : true,
  );
  const data = ref([]);

  const dataMeta = computed(() => {
    const perPageMaxCount =
      dataFiltered.value.length - perPage.value * currentPage.value;

    let totalPerPage =
      perPageMaxCount < 0 ? perPageMaxCount + perPage.value : perPage.value;
    return { to: totalPerPage, of: dataFiltered.value.length };
  });

  const dataFiltered = computed(() => {
    return data.value.filter(data => {
      let found = false;
      for (const property in data) {
        if (
          data[property] &&
          (typeof data[property] === 'string' ||
            data[property] instanceof String ||
            property == 'id') &&
          data[property]
            .toString()
            .toLowerCase()
            .includes(searchQuery.value.toLowerCase())
        )
          found = true;
      }
      return found;
    });
  });

  const dataCount = computed(() => {
    return dataFiltered.value.length;
  });

  const fetchData = (ctx, callback) => {
    store
      .dispatch(props.dataAction, props.dataURLParams)
      .then(response => {
        // data.value = response.data;
        if (response.data == null) {
          data.value = [];
        } else {
          data.value = response.data;
        }
      })
      .catch(e => {
        toast({
          component: ToastificationContent,
          props: {
            title: 'Napaka pri nalaganju podatkov',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        });
      });
  };

  onMounted(() => {
    fetchData();
  });

  // *===============================================---*
  // *--------- UI ---------------------------------------*
  // *===============================================---*

  return {
    fetchData,
    perPage,
    currentPage,
    totalData,
    dataCount,
    dataMeta,
    perPageOptions,
    searchQuery,
    sortBy,
    isSortDirDesc,
    data,

    dataFiltered,
  };
}
