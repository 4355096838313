import useJwt from '@/auth/jwt/useJwt';
import store from '@/store';
import ability from '@/libs/acl/ability';
import { initialAbility } from '@/libs/acl/config';

/**
 * Return if user is logged in
 */
// eslint-disable-next-line arrow-body-style
export const isUserLoggedIn = () => {
  return store.state.auth.userData &&
    store.state.auth[useJwt.jwtConfig.storageTokenKeyName]
    ? true
    : false;
};

export const getUserData = () => store.state.auth.userData;

export const isCompanyModuleActive = moduleName => {
  return store.state.auth.company && store.state.auth.company[moduleName];
};

/**
 * This function is used for demo purpose route navigation
 * In real app you won't need this function because your app will navigate to same route for each users regardless of ability
 * Please note role field is just for showing purpose it's not used by anything in frontend
 * We are checking role just for ease
 * NOTE: If you have different pages to navigate based on user ability then this function can be useful. However, you need to update it.
 * @param {String} userRole Role of user
 */
export const getHomeRouteForLoggedInUser = userRole => {
  //if (userRole === 'admin') return { name: 'locations' };
  //if (userRole === 'client') return { name: 'locations' };
  return userRole ? { name: 'locations' } : { name: 'login' };
};

export const userLogout = () => {
  //send request to server so we log logout action
  store.dispatch('auth/USER_LOGOUT');

  // Remove userData from localStorage
  store.commit('auth/SET_ACCESS_TOKEN', null);

  // Remove userData from localStorage
  store.commit('auth/SET_USER_DATA', null);

  // Reset ability
  ability.update(initialAbility);
};
