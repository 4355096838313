import { ref, computed, onMounted } from '@vue/composition-api';
import store from '@/store';
import i18n from '@/libs/i18n';
import { formatDateFromAPI } from '@/utils';

// Notification
import { useToast } from 'vue-toastification/composition';
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue';

export default function useDeviceList(
  { root, emit },
  settingsName,
  loadAfterMount = null,
) {
  // Use toast
  const toast = useToast();
  //Data
  const settings = ref({});

  const deviceId = root.$route.params.id;

  //Computed methods
  const lastUpdated = computed(() => {
    return settings.value.last_updated
      ? formatDateFromAPI(settings.value.last_updated)
      : '- x';
  });

  //Methods
  const fetchSettings = async () => {
    return store
      .dispatch('devices/GET_SETTINGS', {
        deviceId: deviceId,
        settingsName,
      })
      .then(response => {
        settings.value = response.data;
      })
      .catch(e => {
        toast({
          component: ToastificationContent,
          props: {
            title: i18n.t('failed'),
            icon: 'AlertTriangleIcon',
            variant: 'danger',
            text: i18n.t(e.response.data.message),
          },
        });
      });
  };

  const updateData = async () => {
    const { last_updated, ...data } = settings.value;
    store
      .dispatch('devices/UPDATE_SETTINGS', {
        userId: store.state.auth.userData.userId,
        deviceId,
        updateData: data,
      })
      .then(response => {
        emit('updated');
        toast({
          component: ToastificationContent,
          position: 'top-right',
          props: {
            title: i18n.t('success'),
            icon: 'CheckIcon',
            variant: 'success',
            text: i18n.t('update_success_descirption'),
          },
        });
      })
      .catch(e => {
        emit('updated');
        toast({
          component: ToastificationContent,
          position: 'top-right',
          props: {
            title: i18n.t('failed'),
            icon: 'AlertTriangleIcon',
            variant: 'danger',
            text: i18n.t(e.response.data.message),
          },
        });
      });
  };

  const refreshData = async () => {
    store
      .dispatch('devices/REFRESH_SETTINGS', {
        deviceId: deviceId,
        userId: store.state.auth.userData.userId,
        settingsName,
      })
      .then(response => {
        settings.value = response.data;
        toast({
          component: ToastificationContent,
          position: 'top-right',
          props: {
            title: i18n.t('success'),
            icon: 'CheckIcon',
            variant: 'success',
            text: i18n.t('update_success_descirption'),
          },
        });
        emit('updated');
      })
      .catch(e => {
        emit('updated');
        toast({
          component: ToastificationContent,
          position: 'top-right',
          props: {
            title: i18n.t('failed'),
            icon: 'AlertTriangleIcon',
            variant: 'danger',
            text: i18n.t(e.response.data.message),
          },
        });
      });
  };

  onMounted(async () => {
    await fetchSettings();

    if (loadAfterMount != null) {
      loadAfterMount();
    }
  });

  // *===============================================---*
  // *--------- UI ---------------------------------------*
  // *===============================================---*

  return {
    lastUpdated,
    settings,
    updateData,
    refreshData,
  };
}
