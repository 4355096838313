<template>
  <div>
    <!-- Table Container Card -->
    <!-- <b-card no-body class="mb-0">-->
    <div class="m-2" v-if="!hideHeader">
      <!-- Table Top -->
      <b-row>
        <!-- Per Page -->
        <b-col
          cols="12"
          md="6"
          class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
          v-if="!hidePagination"
        >
          <label>{{ $t('show') }}</label>
          <v-select
            v-model="perPage"
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            :options="perPageOptions"
            :clearable="false"
            class="per-page-selector d-inline-block mx-50"
          >
            <div slot="no-options">{{ $t('no-options') }}</div>
          </v-select>
          <label>{{ $t('entries') }}</label>
        </b-col>

        <!-- Search -->
        <b-col cols="12" md="6" v-if="!hideSearch">
          <div class="d-flex align-items-center justify-content-end">
            <b-form-input
              v-model="searchQuery"
              class="d-inline-block"
              :placeholder="$t('search')"
              :class="{ 'mr-1': createRoute }"
            />
            <b-button v-if="createRoute" :to="createRoute" variant="primary">{{
              $t('new')
            }}</b-button>
            <slot name="additional-buttons"></slot>
          </div>
        </b-col>
      </b-row>
    </div>

    <b-table
      ref="refDataListTable"
      class="position-relative"
      :items="dataFiltered"
      :responsive="responsive"
      :fields="tableColumns"
      primary-key="id"
      :sort-by.sync="sortBy"
      show-empty
      empty-text="No matching records found"
      :sort-desc.sync="isSortDirDesc"
      :perPage="perPage"
      :currentPage="currentPage"
    >
      <template v-for="(_, slot) in $scopedSlots" v-slot:[slot]="props">
        <slot :name="slot" v-bind="props" />
      </template>
      <template #table-colgroup="scope">
        <col
          v-for="field in scope.fields"
          :key="field.key"
          :style="{ width: field.key === 'actions' ? '50px' : '400px' }"
        />
      </template>
    </b-table>
    <div class="mx-2 mb-2">
      <b-row>
        <b-col
          cols="12"
          sm="6"
          class="
            d-flex
            align-items-center
            justify-content-center justify-content-sm-start
          "
          v-if="!hidePagination"
        >
          <span class="text-muted"
            >{{ $t('showing') }} {{ dataMeta.to }} {{ $t('of') }}
            {{ dataMeta.of }}
            {{ $t('entries') }}
          </span>
        </b-col>
        <!-- Pagination -->
        <b-col
          cols="12"
          sm="6"
          class="
            d-flex
            align-items-center
            justify-content-center justify-content-sm-end
          "
        >
          <b-pagination
            v-model="currentPage"
            :total-rows="dataCount"
            :per-page="perPage"
            first-number
            last-number
            class="mb-0 mt-1 mt-sm-0"
            prev-class="prev-item"
            next-class="next-item"
            v-if="!hidePagination"
          >
            <template #prev-text>
              <feather-icon icon="ChevronLeftIcon" size="18" />
            </template>
            <template #next-text>
              <feather-icon icon="ChevronRightIcon" size="18" />
            </template>
          </b-pagination>
        </b-col>
      </b-row>
    </div>
    <!-- </b-card>-->
  </div>
</template>

<script>
import {
  BCard,
  BRow,
  BCol,
  BFormInput,
  BButton,
  BTable,
  BMedia,
  BAvatar,
  BLink,
  BBadge,
  BPagination,
} from 'bootstrap-vue';
import vSelect from 'vue-select';
import { ref } from '@vue/composition-api';
import { avatarText } from '@core/utils/filter';
import useDataList from './useDataList';
import { required, email } from '@validations';
export default {
  components: {
    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BMedia,
    BAvatar,
    BLink,
    BBadge,
    BPagination,
    vSelect,
  },
  props: {
    tableColumns: {
      type: Array,
    },
    dataAction: {
      type: String,
    },
    dataURLParams: {
      type: Object,
      required: false,
    },
    createRoute: {
      type: Object,
      required: false,
    },
    hideHeader: false,
    hideSearch: false,
    hidePagination: false,
    responsive: true,
    columnSortBy: { default: 'id', type: String },
    columnIsSortDirDesc: true,
  },
  setup(props, context) {
    const isAddNewDataSidebarActive = ref(false);

    const {
      fetchData,
      perPage,
      currentPage,
      totalData,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refDataListTable,
      refetchData,
      data,
      dataCount,

      // UI
      resolveDataRoleVariant,
      resolveDataRoleIcon,
      resolveDataStatusVariant,

      dataFiltered,
    } = useDataList(props);

    return {
      // Sidebar
      isAddNewDataSidebarActive,

      fetchData,
      perPage,
      currentPage,
      totalData,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refDataListTable,
      refetchData,
      data,
      dataCount,

      // Filter
      avatarText,

      // UI
      resolveDataRoleVariant,
      resolveDataRoleIcon,
      resolveDataStatusVariant,

      dataFiltered,

      required,
    };
  },
  methods: {
    refresh() {
      this.fetchData();
    },
  },
  watch: {
    data: {
      handler: function (val, oldVal) {
        this.$emit('data-loaded', val);
      },
      deep: true,
    },
  },
};
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
