import { $GET, $PATCH, $POST, $DELETE } from './methods';

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    GET_PAGE_TEXT(state, payload) {
      return $GET(
        'devices/' + payload.deviceId + '/settings/speeding/pagetext',
      );
    },
    REFRESH_PAGE_TEXT(state, payload) {
      return $GET(
        'devices/' + payload.deviceId + '/settings/speeding/pagetext/refresh',
      );
    },
    UPDATE_PAGE_TEXT(state, payload) {
      return $PATCH(
        'devices/' + payload.deviceId + '/settings/speeding/pagetext',
        payload.pages,
      );
    },
  },
};
