<template>
  <div>
    <b-row>
      <b-col md="6">
        <b-form-group
          label-cols="4"
          label-cols-lg="2"
          :label="$t('settings.device_name')"
        >
          <b-form-input disabled :value="settings.model" />
        </b-form-group>
        <b-form-group
          label-cols="4"
          label-cols-lg="2"
          :label="$t('settings.serial_num')"
        >
          <b-form-input disabled :value="settings.serialNumber" />
        </b-form-group>
        <b-form-group
          label-cols="4"
          label-cols-lg="2"
          :label="$t('settings.firmware_version')"
        >
          <b-form-input disabled :value="settings.firmware" />
        </b-form-group>
      </b-col>
      <b-col md="6">
        <b-form-group label-cols="4" label-cols-lg="2" :label="$t('title')">
          <b-form-input v-model="settings.name" />
        </b-form-group>
        <b-form-group
          label-cols="4"
          label-cols-lg="2"
          :label="$t('speeding.speed_limit')"
        >
          <b-form-input v-model="settings.speedLimit" />
        </b-form-group>
        <b-form-group
          label-cols="4"
          label-cols-lg="2"
          :label="$t('settings.direction')"
        >
          <b-form-select
            class="bordered"
            v-model="selectedState"
            :options="stateOptions"
          />
        </b-form-group>
      </b-col>
    </b-row>
    <div class="d-flex justify-content-between">
      <span>{{ $t('last_updated') }} {{ lastUpdated }}</span>
      <div>
        <b-button @click="updateData" variant="primary"
          >{{ $t('save') }}
        </b-button>
        <b-button @click="refreshData" variant="outline-primary" class="ml-1"
          >{{ $t('refresh') }}
        </b-button>
      </div>
    </div>
  </div>
</template>

<script>
import {
  BFormGroup,
  BFormInput,
  BButton,
  BFormSelect,
  BRow,
  BCol,
} from 'bootstrap-vue';
import useDeviceSetttings from './useDeviceSettings';
import { ref, computed } from '@vue/composition-api';
import store from '@/store';
import i18n from '@/libs/i18n';
import { onMounted } from '@vue/composition-api';

// Notification
import { useToast } from 'vue-toastification/composition';
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue';
import { dangerToast, successToast } from '@/utils/toast';
import { formatDateFromAPI } from '@/utils';
import { CommandType } from '@/types/commandType';

export default {
  props: {
    id: {
      type: String,
    },
  },
  components: {
    BFormGroup,
    BFormInput,
    BButton,
    BFormSelect,
    BRow,
    BCol,
  },
  setup(props, context) {
    const toast = useToast();
    const selectedState = ref(null);
    const stateOptions = [
      { value: null, text: i18n.t('generalSettings.select_state') },
      { value: '1', text: i18n.t('generalSettings.direction.incoming') },
      { value: '2', text: i18n.t('generalSettings.direction.outgoing') },
      { value: '3', text: i18n.t('generalSettings.direction.both') },
    ];

    const settings = ref({});
    const lastUpdatedDate = ref(null);

    const updateData = async () => {
      try {
        let updatePromises = [];
        updatePromises.push(
          store.dispatch('devices/UPDATE_VEHICLE_SETTINGS', {
            deviceId: context.root.$route.params.id,
            data: {
              name: settings.value.name,
              location: settings.value.location,
              speedLimit: settings.value.speedLimit,
              measureDirection: selectedState.value,
            },
          }),
        );

        await Promise.all(updatePromises);

        successToast(
          toast,
          i18n.t('success'),
          i18n.t('update_success_descirption'),
        );
      } catch (e) {
        dangerToast(toast, i18n.t('failed'), i18n.t(e.response.data.message));
      }
    };

    const refreshData = async () => {
      try {
        const response = await store.dispatch('devices/GET_VEHICLE_SETTINGS', {
          deviceId: context.root.$route.params.id,
          userId: store.state.auth.userData.userId,
        });
        successToast(toast, i18n.t('success'), i18n.t('update_device_success'));

        loadData();
      } catch (e) {
        dangerToast(toast, i18n.t('failed'), i18n.t(e.response.data.message));
      }
    };

    const loadData = async () => {
      try {
        const response = await store.dispatch(
          'devices/GET_DEVICE',
          context.root.$route.params.id,
        );

        settings.value = response.data;
        selectedState.value = settings.value.measureDirection;

        const lastDateResponse = await store.dispatch(
          'devices/GET_LAST_UPDATED_DATE',
          {
            deviceId: context.root.$route.params.id,
            command: CommandType['GET_VEHICLE_SPEED'],
          },
        );
        lastUpdatedDate.value = lastDateResponse.data;
      } catch (e) {
        dangerToast(toast, i18n.t('failed'), i18n.t(e.response.data.message));
      }
    };

    onMounted(() => {
      loadData();
    });

    const lastUpdated = computed(() => {
      return lastUpdatedDate.value
        ? formatDateFromAPI(lastUpdatedDate.value)
        : '- x';
    });

    return {
      selectedState,
      stateOptions,
      lastUpdated,
      settings,
      updateData,
      refreshData,
    };
  },
};
</script>

<style></style>
