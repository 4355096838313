import Settings from '@/views/apps/vehicle/settings/Settings.vue';
import Report from '@/views/apps/shared/Report.vue';
import Import from '@/views/apps/speeding/Import.vue';
import AverageStats from '@/views/apps/vehicle/AverageStats.vue';
import ClassStats from '@/views/apps/vehicle/ClassStats.vue';
import CounterStats from '@/views/apps/vehicle/CounterStats.vue';
import PercentilStats from '@/views/apps/vehicle/PercentilStats.vue';

export default [
  {
    path: '/vehicle/:id',
    name: 'vehicle.dashboard',
    props: true,
    component: () => import('@/views/apps/Index.vue'),
    children: [
      {
        path: 'settings',
        name: 'vehicle-settings',
        component: Settings,
        meta: {
          preloadDevice: true,
          resource: 'VehicleStats',
          read: false,
          manage: true,
        },
      },
      {
        path: 'import',
        component: Import,
        meta: {
          preloadDevice: true,
          resource: 'Device',
          read: false,
          manage: true,
          type: 'vehicle',
        },
      },
      {
        path: 'average',
        component: AverageStats,
        meta: {
          preloadDevice: true,
          resource: 'VehicleStats',
          read: true,
          manage: false,
        },
      },
      {
        path: 'classes',
        component: ClassStats,
        meta: {
          preloadDevice: true,
          resource: 'VehicleStats',
          read: true,
          manage: false,
        },
      },
      {
        path: 'counter',
        component: CounterStats,
        meta: {
          preloadDevice: true,
          resource: 'VehicleStats',
          read: true,
          manage: false,
        },
      },
      {
        path: 'percentil',
        component: PercentilStats,
        meta: {
          preloadDevice: true,
          resource: 'VehicleStats',
          read: true,
          manage: false,
        },
      },
      {
        path: 'report',
        component: Report,
        meta: {
          preloadDevice: true,
          resource: 'VehicleStats',
          read: true,
          manage: false,
        },
      },
    ],
    meta: {
      pageTitle: 'Dashboard',
      breadcrumb: [
        {
          text: 'Dashboard',
          active: true,
        },
      ],
      preloadDevice: true,
    },
  },
];
