<template>
  <b-card class="p-2">
    <div>
      <div>
        <div class="d-flex justify-content-between align-items-center pb-1">
          <div class="d-flex align-items-center justify-center">
            <h4>{{ $t('import_data') }}</h4>
          </div>
        </div>
      </div>
      <div>
        <b-form-group
          label-cols="4"
          label-cols-lg="1"
          :label="$t('import_file')"
          class="mt-2"
        >
          <div class="d-flex align-items-center ml-2 input-size">
            <b-form-file
              v-model="file"
              :state="Boolean(file)"
              :placeholder="$t('choose_file')"
              :browse-text="$t('select')"
              accept=".txt"
              class="input-text"
            ></b-form-file>
          </div>
        </b-form-group>
        <b-form-group
          label-cols="4"
          label-cols-lg="1"
          :label="$t('year')"
          class="mt-2"
          v-if="showYear"
        >
          <div class="d-flex align-items-center ml-2 input-size">
            <b-form-input placeholder="2022" v-model="year"></b-form-input>
          </div>
        </b-form-group>
        <b-form-group
          label-cols="4"
          label-cols-lg="1"
          :label="$t('speeding.speed_limit')"
          class="mt-2"
          v-if="showSpeedLimit"
        >
          <div class="d-flex align-items-center ml-2 input-size">
            <b-form-input
              placeholder="km/h"
              v-model="speedLimit"
            ></b-form-input>
          </div>
        </b-form-group>
        <b-form-group
          label-cols="4"
          label-cols-lg="1"
          :label="$t('settings.device_id')"
          class="mt-2"
          v-if="showLocationCode"
        >
          <div class="d-flex align-items-center ml-2 input-size">
            <b-form-input v-model="locationCode"></b-form-input>
          </div>
        </b-form-group>
        <b-button @click="importData" class="mt-2" variant="primary">{{
          $t('import')
        }}</b-button>
      </div>
    </div>
  </b-card>
</template>

<script>
import axios from '@/libs/axios';
import { dangerToast, successToast } from '@/utils/toast';
import {
  BCard,
  BButton,
  BFormSelect,
  BFormGroup,
  BFormInput,
  BFormCheckbox,
  BFormFile,
} from 'bootstrap-vue';
import i18n from '@/libs/i18n';

export default {
  components: {
    BCard,
    BButton,
    BFormSelect,
    BFormGroup,
    BFormInput,
    BFormCheckbox,
    BFormFile,
  },
  data() {
    return { file: null, speedLimit: null, year: null, locationCode: null };
  },
  methods: {
    importData() {
      let formData = new FormData();
      formData.append('file', this.file);
      formData.append('speedLimit', this.speedLimit);
      formData.append('locationCode', this.locationCode);
      formData.append('year', this.year);
      axios
        .post('devices/' + this.$route.meta.type + '/import', formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        })
        .then(r => {
          if (r) {
            this.file = null;
            successToast(
              this.$toast,
              this.$t('success'),
              this.$t('import_succes'),
            );
          }
        })
        .catch(e => {
          dangerToast(
            this.$toast,
            this.$t('error'),
            i18n.t(e.response.data.message),
          );
        });
    },
  },
  computed: {
    showSpeedLimit() {
      return this.$route.meta.type == 'speeding';
    },
    showLocationCode() {
      return this.$route.meta.type == 'vehicle';
    },
    showYear() {
      return this.$route.meta.type != 'vehicle';
    },
  },
};
</script>

<style>
.not-in-version {
  pointer-events: none;
  opacity: 0.2;
}

.bordered {
  border: 1px solid #d8d6de;
  border-radius: 5px;
}
.input-size {
  width: 300px;
}
.input-text {
  font-family: Montserrat, Helvetica, Arial, serif !important;
  font-size: 14px !important;
}
</style>
