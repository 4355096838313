import { $GET, $POST } from './methods';

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    GET_AUDIT(state, payload) {
      return $GET('audit');
    },
    CREATE_AUDIT(state, payload) {
      return $POST('audit', payload.user);
    },
    SAVE_ACTION(state, payload) {
      return $POST('audit/action', { ...payload });
    },
    GET_ACTION_AUDIT(state, payload) {
      return payload.locationCode ? $GET('audit/action/' + payload.locationCode) : [];
    },
  },
};
