import Vue from 'vue';
import Vuex from 'vuex';
import createPersistedState from 'vuex-persistedstate';
import SecureLS from 'secure-ls';
var ls = new SecureLS({ isCompression: false });

// Modules
import app from './app';
import appConfig from './app-config';
import verticalMenu from './vertical-menu';
import userData from './auth';
import stats from './stats';
import devices from './devices';
import users from './users';
import ftp from './ftp';
import companies from './companies';
import audit from './audit';
import remotegui from './remotegui';
import pagetext from './page-text';
import ldap from './ldap';
import groups from './groups';

Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    app,
    appConfig,
    verticalMenu,
    auth: userData,
    stats,
    devices,
    users,
    companies,
    ftp,
    audit,
    remotegui,
    pagetext,
    ldap,
    groups,
  },
  strict: process.env.DEV,
  plugins: [
    createPersistedState({
      storage: {
        getItem: key => ls.get(key),
        setItem: (key, value) => ls.set(key, value),
        removeItem: key => ls.remove(key),
      },
      paths: ['auth'],
    }),
  ],
});
