import axiosIns from '@/libs/axios';

export const $GET = (url, after) => {
  return new Promise((resolve, reject) => {
    axiosIns
      .get(url)
      .then(response => {
        if (after) after(response);
        resolve(response);
      })
      .catch(error => reject(error));
  });
};

export const $POST = (url, data, after) => {
  return new Promise((resolve, reject) => {
    axiosIns
      .post(url, data)
      .then(response => {
        if (after) after(response);
        resolve(response);
      })
      .catch(error => reject(error));
  });
};

export const $PATCH = (url, data, after) => {
  return new Promise((resolve, reject) => {
    axiosIns
      .patch(url, data)
      .then(response => {
        if (after) after(response);
        resolve(response);
      })
      .catch(error => reject(error));
  });
};

export const $DELETE = url => {
  return new Promise((resolve, reject) => {
    axiosIns
      .delete(url)
      .then(response => {
        resolve(response);
      })
      .catch(error => reject(error));
  });
};
