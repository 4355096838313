<template>
  <div>
    <b-row>
      <b-col md="6">
        <b-form-group
          label-cols="4"
          label-cols-lg="2"
          :label="$t('settings.device_id')"
        >
          <b-form-input disabled :value="settings.id" />
        </b-form-group>
        <b-form-group
          label-cols="4"
          label-cols-lg="2"
          :label="$t('settings.device_voltage')"
        >
          <b-form-input disabled :value="voltage" />
        </b-form-group>
      </b-col>
      <b-col md="6">
        <b-form-group label-cols="4" label-cols-lg="2" label="SIM ICCID">
          <b-form-input disabled :value="settings.simIccid" />
        </b-form-group>
        <b-form-group
          label-cols="4"
          label-cols-lg="2"
          :label="$t('cyclist.temperature')"
        >
          <b-form-input disabled type="text" :value="temperature" />
        </b-form-group>
      </b-col>
    </b-row>
    <diagnostic ref="diagnosticChart"></diagnostic>
    <span>{{ $t('last_updated') }} {{ lastUpdated }}</span>
    <div class="float-right mb-1">
      <b-button @click="reboot(2)" variant="danger">{{
        $t('settings.reboot_counter')
      }}</b-button>
      <b-button @click="reboot(1)" class="ml-1" variant="danger">{{
        $t('settings.reboot_microcontroller')
      }}</b-button>
      <b-button
        @click="loadData(true)"
        variant="outline-primary"
        class="ml-1"
        >{{ $t('refresh') }}</b-button
      >
    </div>
  </div>
</template>

<script>
import {
  BFormGroup,
  BFormInput,
  BButton,
  BFormSelect,
  BRow,
  BCol,
} from 'bootstrap-vue';
import useDeviceSetttings from './useDeviceSettings';
import store from '@/store';
import { dangerToast, successToast } from '@/utils/toast';
import { useToast } from 'vue-toastification/composition';
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue';
import i18n from '@/libs/i18n';
import { computed, ref, onMounted } from '@vue/composition-api';
import Diagnostic from './DiagnosticChart.vue';
import { CommandType } from '@/types/commandType';
import { formatDateFromAPI } from '@/utils';

export default {
  props: {
    id: {
      type: String,
    },
  },
  components: {
    BFormGroup,
    BFormInput,
    BButton,
    BFormSelect,
    BRow,
    BCol,
    Diagnostic,
  },
  setup(props, context) {
    const toast = useToast();

    const settings = ref({});
    const lastUpdatedDate = ref(null);

    const diagnosticChart = ref();

    const updateChart = () => {
      diagnosticChart.value.updateChart();
    };

    onMounted(() => {
      loadData(false);
    });

    // 1- reboot device
    // 2- reboot microcontroller
    const reboot = async rebootType => {
      try {
        const response = await store.dispatch('devices/REBOOT', {
          deviceId: context.root.$route.params.id,
          userId: store.state.auth.userData.userId,
          type: rebootType,
        });

        successToast(
          toast,
          rebootType == 1
            ? i18n.t('settings.reboot_in_progress')
            : i18n.t('settings.micro_reboot_in_progress'),
        );
      } catch (e) {
        dangerToast(toast, i18n.t(e.response.data.message));
      }
    };

    const loadData = async (refresh = false) => {
      try {
        const response = await store.dispatch(
          'devices/GET_VEHICLE_DIAGNOSTIC',
          {
            deviceId: context.root.$route.params.id,
            refresh,
          },
        );

        settings.value = response.data;

        if (refresh) {
          successToast(
            toast,
            i18n.t('success'),
            i18n.t('update_success_descirption'),
          );
        }

        //get last updated
        const lastDateResponse = await store.dispatch(
          'devices/GET_LAST_UPDATED_DATE',
          {
            deviceId: context.root.$route.params.id,
            command: CommandType['GET_VEHICLE_ICCID_SIM'],
          },
        );
        lastUpdatedDate.value = lastDateResponse.data;
      } catch (e) {
        console.log(e);
        dangerToast(toast, i18n.t(e.response.data.message));
      }
    };

    const voltage = computed(() => {
      return settings.value.voltage + 'V';
    });

    const temperature = computed(() => {
      return settings.value.temperature + '°C';
    });

    const lastUpdated = computed(() => {
      return lastUpdatedDate.value
        ? formatDateFromAPI(lastUpdatedDate.value)
        : '- x';
    });

    return {
      diagnosticChart,
      reboot,
      lastUpdated,
      settings,
      updateChart,
      voltage,
      temperature,
      loadData,
    };
  },
};
</script>

<style></style>
