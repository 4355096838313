<template>
  <b-card>
    <b-card-header>
      <b-card-title>{{ title }}</b-card-title>
      <!-- datepicker -->
      <date-range-picker
        v-if="!nodatepicker"
        @date-changed="dateChanged"
      ></date-range-picker>
      <!-- datepicker -->
    </b-card-header>
    <b-card-body>
      <div style="position:relative;">
        <app-echart-bar ref="barChart" :option-data="graphOptions.option">
        </app-echart-bar>
        <div style="position:absolute; top:0;">
          <slot name="additional-header"></slot>
        </div>
        <div
          class="apexcharts-toolbar"
          style="position:absolute; top: 0px; right: 3px;"
        >
          <div @click="toggleToolbar" class="apexcharts-menu-icon" title="Menu">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
            >
              <path fill="none" d="M0 0h24v24H0V0z"></path>
              <path d="M3 18h18v-2H3v2zm0-5h18v-2H3v2zm0-7v2h18V6H3z"></path>
            </svg>
          </div>
          <div
            v-if="toolbar"
            class="apexcharts-menu apexcharts-menu-open"
            style="width: 120px;"
          >
            <div
              @click="exportPNG"
              class="apexcharts-menu-item exportPNG"
              title="Download PNG"
            >
              {{ $t('download') }} PNG
            </div>
            <div
              @click="exportPDF"
              class="apexcharts-menu-item exportPDF"
              title="Download PDF"
            >
              {{ $t('download') }} PDF
            </div>
            <div
              @click="exportCSV"
              class="apexcharts-menu-item exportCSV"
              title="Download CSV"
            >
              {{ $t('download') }} CSV
            </div>
          </div>
        </div>
      </div>
    </b-card-body>
  </b-card>
</template>

<script>
import { BCard, BCardHeader, BCardTitle, BCardBody } from 'bootstrap-vue';
import AppEchartBar from '@core/components/charts/echart/AppEchartBar.vue';
import DateRangePicker from '@/views/components/DateRangePicker.vue';
import jsPDF from 'jspdf';
import { getStartDateFromStore, getEndDateFromStore } from '@/utils';

export default {
  props: {
    title: {
      type: String,
    },
    subtitle: {
      type: String,
    },
    data: {
      type: Array,
    },
    lables: {
      type: Array,
    },
    nodatepicker: {
      type: Boolean,
    },
    markLine: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      toolbar: false,
      chartTitle: '',
    };
  },
  components: {
    BCard,
    BCardHeader,
    BCardTitle,
    BCardBody,
    AppEchartBar,
    DateRangePicker,
  },
  methods: {
    toggleToolbar() {
      this.toolbar = !this.toolbar;
    },
    exportCSV() {
      let outputArray = [];
      for (let i = 0; i < this.data[0].data.length; i++) {
        let item = [this.lables[i]];
        this.data.forEach(s => {
          item.push(s.data[i]);
        });
        outputArray.push(item);
      }
      let csvContent =
        'data:text/csv;charset=utf-8,' +
        outputArray.map(e => e.join(',')).join('\n');
      var encodedUri = encodeURI(csvContent);
      var link = document.createElement('a');
      link.setAttribute('href', encodedUri);
      link.setAttribute('download', 'data.csv');
      document.body.appendChild(link); // Required for FF

      link.click(); // This will download the data file named "my_data.csv".
    },
    exportPDF() {
      this.chartTitle = this.$store.state.devices.currentDevice
        ? this.$store.state.devices.currentDevice.name
        : '';
      this.subtitleTmp =
        getStartDateFromStore() + ' - ' + getEndDateFromStore();

      this.$nextTick(function() {
        const pdf = new jsPDF('landscape');
        pdf.addImage(
          this.$refs.barChart.$refs.line.chart.getDataURL(),
          'PNG',
          0,
          0,
          290,
          80,
        );
        pdf.save('chart.pdf');
        this.chartTitle = '';
        this.subtitleTmp = '';
      });
    },
    exportPNG() {
      this.chartTitle = this.$store.state.devices.currentDevice
        ? this.$store.state.devices.currentDevice.name
        : '';
      this.subtitleTmp =
        getStartDateFromStore() + ' - ' + getEndDateFromStore();

      this.$nextTick(function() {
        var dl = document.createElement('a');
        document.body.appendChild(dl); // This line makes it work in Firefox.
        dl.setAttribute(
          'href',
          this.$refs.barChart.$refs.line.chart.getDataURL(),
        );
        dl.setAttribute('download', 'test.png');
        dl.click();
        this.chartTitle = '';
        this.subtitleTmp = '';
      });
    },
    dateChanged(dateRange) {
      this.$emit('date-changed', dateRange);
    },
    getSeries() {
      const colors = ['#0391e5', '#ea5455', '#28c76f', '#ffc107', '#7367f0'];

      return this.data.map((d, i) => {
        return {
          name: d.name,
          type: d.type ? d.type : 'bar',
          data: d.data,
          markLine: this.markLine
            ? {
                data: [{ type: 'average', name: 'Avg' }],
                lineStyle: { color: 'rgb(0, 227, 150)' },
              }
            : undefined,
          barMaxWidth: '20%',
          barMinWidth: '10px',
          symbolSize: 6,
          lineStyle: {
            color: d.lineColor ? d.lineColor : 'rgb(0, 227, 150)',
            width: 2,
            type: 'dashed',
          },
          itemStyle: {
            barBorderRadius: [10, 10, 0, 0],
            color:
              d.type == 'line'
                ? d.lineColor
                  ? d.lineColor
                  : 'rgb(0, 227, 150)'
                : colors[i],
          },
        };
      });
    },
  },
  computed: {
    graphOptions() {
      return {
        option: {
          title: this.chartTitle,
          subtitle: this.subtitle,
          legend: {
            show: false,
            offSetY: 20,
          },
          xAxis: [
            {
              type: 'category',
              data: this.lables,
            },
          ],
          yAxis: [
            {
              type: 'value',
              splitLine: { show: true },
            },
          ],
          grid: {
            left: '80px',
            right: '30px',
            bottom: '50px',
          },
          series: this.getSeries(),
        },
      };
    },
  },
};
</script>
