import { $GET, $PATCH, $POST, $DELETE } from './methods';

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    GET_LDAP(state, payload) {
      return $GET('ldap/1');
    },
    CHANGE_STATE(state, payload) {
      return $POST('ldap/1/turnoffon', {
        enabled: payload.enabled,
      });
    },
    UPDATE_LDAP(state, payload) {
      return $PATCH('ldap/' + payload.ldapId, payload.ldap);
    },
    TEST_LDAP(state, payload) {
      return $POST('ldap/test', payload.ldap);
    },
    CREATE_LDAP(state, payload) {
      return $POST('ldap', payload.ldap);
    },
    DELETE_LDAP(state, ldapId) {
      return $DELETE('ldap/' + ldapId);
    },
  },
};
