// Notification
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue';

export function dangerToast(toastInstance, title, text) {
  generateToast(toastInstance, title, text, 'danger');
}

export function successToast(toastInstance, title, text) {
  generateToast(toastInstance, title, text, 'success');
}

export function warningToast(toastInstance, title, text) {
  generateToast(toastInstance, title, text, 'warning');
}

export function generateToast(toastInstance, title, text, type) {
  toastInstance({
    component: ToastificationContent,
    position: 'top-right',
    props: {
      title: title,
      icon: 'AlertTriangleIcon',
      variant: type,
      text: text,
    },
  });
}
