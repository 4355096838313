<template>
  <div>
    <b-row>
      <b-col md="6"
        ><mymap height="400px" ref="myMap" :locations="locations"></mymap>
      </b-col>
      <b-col md="6">
        <b-form-group
          label-cols="4"
          label-cols-lg="2"
          :label="$t('generalSettings.latitude_gpshw')"
          disabled
        >
          <b-form-input v-model="gpshw.latitude" />
        </b-form-group>
        <b-form-group
          label-cols="4"
          label-cols-lg="2"
          :label="$t('generalSettings.longitude_gpshw')"
          disabled
        >
          <b-form-input v-model="gpshw.longitude" />
        </b-form-group>
        <b-form-group
          label-cols="4"
          label-cols-lg="2"
          :label="$t('generalSettings.latitude')"
        >
          <b-form-input v-model="settings.latitude" />
        </b-form-group>
        <b-form-group
          label-cols="4"
          label-cols-lg="2"
          :label="$t('generalSettings.longitude')"
        >
          <b-form-input v-model="settings.longitude" />
        </b-form-group>
        <!--<span>{{ $t('last_updated') }} {{ lastUpdated }}</span>-->
        <div class="float-right mb-1">
          <b-button @click="updateData" variant="primary">{{
            $t('save')
          }}</b-button>
          <b-button
            @click="refreshData"
            variant="outline-primary"
            class="ml-1"
            >{{ $t('refresh') }}</b-button
          >
        </div>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import {
  BFormGroup,
  BFormInput,
  BButton,
  BFormSelect,
  BRow,
  BCol,
} from 'bootstrap-vue';
import useDeviceSetttings from './useDeviceSettings';
import store from '@/store';
import i18n from '@/libs/i18n';

import { useToast } from 'vue-toastification/composition';
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue';
import Map from '@/views/components/Map.vue';
import { onMounted, ref } from '@vue/composition-api';
import Vue from 'vue';

export default {
  props: {
    id: {
      type: String,
    },
  },
  components: {
    BFormGroup,
    BFormInput,
    BButton,
    BFormSelect,
    BRow,
    BCol,
    mymap: Map,
  },
  setup(props, context) {
    const { lastUpdated, settings } = useDeviceSetttings(
      context,
      'geolocation',
    );

    const toast = useToast();

    const locations = ref([]);

    const myMap = ref(null);

    const device = ref({});

    const gpshw = ref({ latitude: null, longitude: null });

    const updateMap = () => {
      myMap.value.center = [device.value.latitude, device.value.longitude];
      myMap.value.zoom = 10;
      myMap.value.forceUpdate();

      Vue.nextTick(() => {
        myMap.value.$refs.marker.forEach(m => {
          if (m.$vnode.key == device.value.id) m.mapObject.openTooltip();
        });
      });
    };

    const loadDevice = async () => {
      const response = await store.dispatch(
        'devices/GET_DEVICE',
        context.root.$route.params.id,
      );
      device.value = response.data;
      if (device.value && device.value.latitude && device.value.longitude) {
        locations.value = [device.value];
        updateMap();
      }
    };

    onMounted(async () => {
      loadDevice();

      //try to read gps coordinates from device
      try {
        const r = await store.dispatch('devices/GET_GPSHW', {
          userId: store.state.auth.userData.userId,
          deviceId: context.root.$route.params.id,
        });
        if (r.data) gpshw.value = r.data;
      } catch (e) {}
    });

    const updateData = async () => {
      store
        .dispatch('devices/UPDATE', {
          id: context.root.$route.params.id,
          longitude: settings.value.longitude,
          latitude: settings.value.latitude,
        })
        .then(response => {
          loadDevice();
          toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: i18n.t('success'),
              icon: 'CheckIcon',
              variant: 'success',
              text: i18n.t('update_success_descirption'),
            },
          });
        })
        .catch(e => {
          toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: i18n.t('failed'),
              icon: 'AlertTriangleIcon',
              variant: 'danger',
              text: i18n.t(e.response.data.message),
            },
          });
        });
    };

    const refreshData = () => {
      settings.value.longitude = gpshw.value.longitude;
      settings.value.latitude = gpshw.value.latitude;
    };

    return {
      gpshw,
      lastUpdated,
      settings,
      updateData,
      refreshData,
      locations,
      updateMap,
      myMap,
    };
  },
};
</script>

<style></style>
