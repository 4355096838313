<template>
  <div>
    <mymap height="400px" ref="myMap" :locations="locations"></mymap>
  </div>
</template>

<script>
import {
  BFormGroup,
  BFormInput,
  BButton,
  BFormSelect,
  BRow,
  BCol,
} from 'bootstrap-vue';
import Map from '@/views/components/Map.vue';
import { computed, ref } from '@vue/composition-api';

export default {
  props: {
    id: {
      type: String,
    },
  },
  components: {
    BFormGroup,
    BFormInput,
    BButton,
    BFormSelect,
    BRow,
    BCol,
    mymap: Map,
  },
  props: ['device'],
  setup(props, context) {
    const locations = computed(() => {
      return props.device.id != null ? [props.device] : [];
    });

    const myMap = ref(null);

    const updateMap = () => {
      myMap.value.center = [props.device.latitude, props.device.longitude];
      myMap.value.zoom = 10;
      myMap.value.forceUpdate();
    };

    return {
      locations,
      updateMap,
      myMap,
    };
  },
};
</script>

<style></style>
