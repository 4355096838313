<template>
  <div>
    <content-header
      datepicker
      nomargin
      @dateChanged="dateChanged"
    ></content-header>
    <b-row>
      <b-col md="6"
        ><bar-statistics :title="$t('cars')" :data="itemsCar"
      /></b-col>
      <b-col md="6"
        ><bar-statistics :title="$t('trucks')" :data="itemsTruck"
      /></b-col>
    </b-row>

    <apex-line-chart
      :option="allGraph"
      :title="
        $t('speeding.average_and_max_speed') + ' - ' + $t('cars_and_trucks')
      "
      @date-changed="dateChanged"
      nodatepicker
    ></apex-line-chart>
    <apex-line-chart
      :option="carGraph"
      :title="$t('speeding.average_and_max_speed') + ' - ' + $t('cars')"
      @date-changed="dateChanged"
      nodatepicker
    ></apex-line-chart>
    <apex-line-chart
      :option="truckGraph"
      :title="$t('speeding.average_and_max_speed') + ' - ' + $t('trucks')"
      @date-changed="dateChanged"
      nodatepicker
    ></apex-line-chart>
  </div>
</template>

<script>
import BarStatistics from '@/views/components/BarStatistics';

import ContentHeader from '@/views/components/ContentHeader.vue';
import ApexLineChart from '@/views/components/ApexLineChart.vue';
import { BCol, BRow } from 'bootstrap-vue';
import {
  formatDateFromAPI,
  getStartDateFromStore,
  getEndDateFromStore,
} from '@/utils';
import DynamicChart from '@/views/components/DynamicChart.vue';

export default {
  props: {
    id: { type: String, required: false },
  },
  components: {
    BarStatistics,
    ContentHeader,
    ApexLineChart,
    BCol,
    BRow,
    DynamicChart,
  },
  data() {
    return {
      dateRange: [getStartDateFromStore(), getEndDateFromStore()],
      stats: {
        avg: 0,
        speedLimit: 0,
        max: 0,
        underLimit: 0,
        aboveLimit: 0,
      },
    };
  },
  async mounted() {
    this.loadData();
  },
  computed: {
    itemsCar() {
      let carBar = this.stats?.average?.barData?.find(
        (e) => e.vehicleType == 'Car',
      );
      if (carBar) carBar.speedLimit = this.stats.average?.carSpeedLimit;
      return this.getBarData(carBar);
    },
    itemsTruck() {
      let truckBar = this.stats?.average?.barData?.find(
        (e) => e.vehicleType == 'Trk',
      );
      if (truckBar) truckBar.speedLimit = this.stats.average?.truckSpeedLimit;
      return this.getBarData(truckBar);
    },
    allBarChartData() {
      return [
        {
          name: this.$t('speeding.average_speed'),
          data: this.stats?.all?.graph.map((item) => Math.round(item.avg)),
        },
        {
          name: this.$t('speeding.max_speed'),
          data: this.stats?.all?.graph.map((item) => item.max),
        },
        {
          name: this.$t('speeding.speed_limit'),
          data: this.stats?.all?.graph.map((item) => item.speedLimit),
        },
      ];
    },
    carBarChartData() {
      return [
        {
          name: this.$t('speeding.average_speed'),
          data: this.stats?.average?.graph
            .filter((el) => el.vehicleType == 'Car')
            .map((item) => item.avg.toFixed(1)),
        },
        {
          name: this.$t('speeding.max_speed'),
          data: this.stats?.average?.graph
            .filter((el) => el.vehicleType == 'Car')
            .map((item) => item.max),
        },
        {
          name: this.$t('speeding.speed_limit'),
          data: this.stats?.average?.graph
            .filter((el) => el.vehicleType == 'Car')
            .map((item) => item.speedLimit),
        },
      ];
    },
    truckBarChartData() {
      return [
        {
          name: this.$t('speeding.average_speed'),
          data: this.stats?.average?.graph
            .filter((el) => el.vehicleType == 'Trk')
            .map((item) => item.avg.toFixed(1)),
        },
        {
          name: this.$t('speeding.max_speed'),
          data: this.stats?.average?.graph
            .filter((el) => el.vehicleType == 'Trk')
            .map((item) => item.max),
        },
        {
          name: this.$t('speeding.speed_limit'),
          data: this.stats?.average?.graph
            .filter((el) => el.vehicleType == 'Trk')
            .map((item) => item.speedLimit),
        },
      ];
    },
    allGraph() {
      return this.getSeries(this.stats?.all?.graph);
    },
    carGraph() {
      return this.getSeries(
        this.stats?.average?.graph.filter((el) => el.vehicleType == 'Car'),
      );
    },
    truckGraph() {
      return this.getSeries(
        this.stats?.average?.graph.filter((el) => el.vehicleType == 'Trk'),
      );
    },
  },
  methods: {
    async dateChanged(dateRange) {
      const dates = dateRange.split('to');
      this.dateRange = [dates[0], dates[1]];
      this.loadData(dates[0], dates[1]);
    },
    async loadData(dateFrom = null, dateTo = null) {
      //Bar statistics
      const response = await this.$store.dispatch('stats/GET_BAR_DATA', {
        id: this.$route.params.id,
        barName: 'average',
        dateFrom,
        dateTo,
        app: 'radar',
      });
      this.stats = response.data;
    },
    getBarData(data) {
      return [
        {
          icon: 'AlertCircleIcon',
          color: 'light-primary',
          title: (data?.speedLimit ?? '0') + ' km/h',
          subtitle: this.$t('speeding.speed_limit'),
          customClass: 'mb-2 mb-xl-0',
        },
        {
          icon: 'ActivityIcon',
          color: 'light-info',
          title: (data?.avg?.toFixed(1) ?? '0') + ' km/h',
          subtitle: this.$t('speeding.average_speed'),
          customClass: 'mb-2 mb-xl-0',
        },
        {
          icon: 'BarChart2Icon',
          color: 'light-danger',
          title: (data?.max ?? '0') + ' km/h',
          subtitle: this.$t('speeding.max_speed'),
          customClass: 'mb-2 mb-sm-0',
          datetime: data?.date_of_max
            ? this.$moment(data?.date_of_max).format('DD.MM.y HH:mm')
            : '',
        },
      ];
    },
    getSeries(graph) {
      return {
        series: [
          {
            name: this.$t('speeding.average_speed'),
            data:
              graph?.map((d) => {
                return {
                  x: d.dateTime,
                  y: d.avg,
                };
              }) ?? [],
          },
          {
            name: this.$t('speeding.max_speed'),
            data:
              graph?.map((d) => {
                return {
                  x: d.dateTime,
                  y: d.max,
                };
              }) ?? [],
          },
          {
            name: this.$t('speeding.speed_limit'),
            data:
              graph?.map((d) => {
                return {
                  x: d.dateTime,
                  y: d.speedLimit,
                };
              }) ?? [],
          },
        ],
      };
    },
  },
  watch: {
    '$route.params.id': function (id) {
      this.loadData();
    },
  },
};
</script>

<style></style>
