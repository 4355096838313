<template>
  <div>
    <content-header
      datepicker
      @dateChanged="dateChanged"
      @compareChanged="compareChanged"
      @compareDateChanged="compareDateChanged"
      :compare="compare"
      v-if="hideHeader == false"
      ><template slot="additional-item"
        ><v-select
          :reduce="dir => dir.code"
          v-model="direction"
          :options="directionOptions"
          :clearable="false"
          class="per-page-selector d-inline-block mx-50 select-width"
          style="background-color: white"
          @input="loadClasses"
        >
          <div slot="no-options">{{ $t('no-options') }}</div>
        </v-select></template
      ></content-header
    >
    <b-row>
      <b-col md="3">
        <b-card>
          <div class="mb-2">{{ $t('stats_in_range') }}</div>
          <b-table responsive="sm" :items="classesTable" :fields="fields">
            <template #cell(range)="data">
              {{ data.item.range }} km/h
            </template>
          </b-table>
          <div class="mt-3">
            <div class="mb-1">{{ $t('classes.custom_speed') }}</div>
            <b-row class="mb-1">
              <b-col class="text-center" md="6">
                {{ sliderValue[0] }} - {{ sliderValue[1] }} km/h
              </b-col>
              <b-col class="text-center" md="6">
                {{ customValueCount }}
              </b-col>
            </b-row>
            <vue-slider
              v-model="sliderValue"
              :tooltip="'active'"
              class="mb-2 vue-slider-primary"
              direction="ltr"
              :marks="getMarks"
              :min="sliderMin"
              :max="sliderMax"
              :enableCross="false"
              @drag-end="sliderChange"
              @change="sliderChange"
              :interval="5"
            />
          </div>
        </b-card>
      </b-col>
      <b-col md="9">
        <e-chart-bar
          :title="$t('classes.speed_classes')"
          :data="graphData"
          :lables="graphLabels"
          nodatepicker
          v-if="hideHeader == false"
        ></e-chart-bar>
        <e-chart-bar
          :title="$t('classes.temperature_classes')"
          :data="temperatureData"
          :lables="temperatureLabels"
          nodatepicker
        ></e-chart-bar>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import { BTable, BRow, BCol, BCard } from 'bootstrap-vue';
//import ChartjsBarChart from './ChartjsBarChart.vue';
import EChartBar from '@/views/components/EChartBar.vue';
import ContentHeader from '@/views/components/ContentHeader.vue';
import VueSlider from 'vue-slider-component';
import vSelect from 'vue-select';
import { getStartDateFromStore, getEndDateFromStore } from '@/utils';

export default {
  props: {
    id: Number,
    dateFrom: String,
    dateTo: String,
    hideHeader: Boolean,
  },
  components: {
    BTable,
    BCard,
    BRow,
    BCol,
    //ChartjsBarChart,
    EChartBar,
    ContentHeader,
    VueSlider,
    vSelect,
  },
  mounted() {
    this.loadClasses();
  },
  data() {
    return {
      dateRange: [getStartDateFromStore(), getEndDateFromStore()],
      direction: null,
      directionOptions: [
        { label: this.$t('show_both_directions'), code: null },
        { label: this.$t('incoming'), code: '0' },
        { label: this.$t('outgoing'), code: '1' },
      ],
      classesTable: [],
      temperatureTable: [],
      temperatureTableCompare: [],
      deviceData: null,
      sliderMin: 0,
      sliderMax: 40,
      sliderValue: [0, 40],
      customValueCount: 0,
      fields: [
        {
          label: this.$t('classes.classes'),
          key: 'range',
          sortable: false,
        },
        {
          label: this.$t('number'),
          key: 'sum',
          sortable: false,
        },
      ],
      compare: false,
      compareDateRange: [getStartDateFromStore(), getEndDateFromStore()],
      classesTableCompare: [],
    };
  },
  methods: {
    getMarks(val) {
      return val % 5 === 0;
    },
    async sliderChange() {
      if (this.sliderValue[0] != null && this.sliderValue[1] != null) {
        let sum = 0;
        this.deviceData.forEach(k => {
          let high = k.class;
          if (high >= this.sliderValue[0] && high <= this.sliderValue[1]) {
            sum += k.vehicleCount;
          }
        });
        this.customValueCount = sum;
      }
    },
    async loadClasses() {
      let dateFrom = null,
        dateTo = null;
      if (this.dateRange) {
        dateFrom = this.dateRange[0];
        dateTo = this.dateRange[1];
      }
      let response = await this.$store.dispatch('stats/GET_CLASSES_DATA', {
        id: this.id != null ? this.id : this.$route.params.id,
        dateFrom: this.dateFrom != null ? this.dateFrom : this.dateRange[0],
        dateTo: this.dateTo != null ? this.dateTo : this.dateRange[1],
        app: 'cyclist',
        direction: this.direction,
      });
      this.deviceData = response.data.classChart;
      let table = [];
      if (this.deviceData.length > 0) {
        this.deviceData.forEach(k => {
          let high = k.class;
          if (k.vehicleCount > 0)
            table.push({
              range: high - 4 + ' - ' + high,
              sum: k.vehicleCount,
            });
        });
      }
      this.classesTable = table;

      this.temperatureTable = response.data.temperatureChart.map(t => {
        return { range: t.low + ' - ' + t.high, sum: t.sum };
      });
      this.sliderChange();
    },
    dateChanged(dateRange) {
      if (dateRange) {
        const dates = dateRange.split('to');
        this.dateRange = dates;
        this.loadClasses();
      }
    },
    async compareChanged(state) {
      this.compare = state;
    },
    async compareDateChanged(dateRange) {
      if (dateRange) {
        const dates = dateRange.split('to');
        this.compareDateRange = [dates[0], dates[1]];
      }
      let response = await this.$store.dispatch('stats/GET_CLASSES_DATA', {
        id: this.id != null ? this.id : this.$route.params.id,
        dateFrom: this.compareDateRange[0],
        dateTo: this.compareDateRange[1],
        direction: this.direction,
        app: 'cyclist',
      });
      const deviceData = response.data.classChart;
      let table = [];
      if (deviceData.length > 0) {
        deviceData.forEach(k => {
          let high = k.class;
          if (k.vehicleCount > 0)
            table.push({
              range: high - 9 + ' - ' + high,
              sum: k.vehicleCount,
            });
        });
      }
      this.temperatureTableCompare = response.data.temperatureChart.map(t => {
        return { range: t.low + ' - ' + t.high, sum: t.sum };
      });

      this.classesTableCompare = table;
    },
  },
  computed: {
    graphData() {
      let data = [
        {
          name: this.$t('Cyclists') + (this.compare ? ' 1' : ''),
          data: this.classesTable?.map(item => item.sum),
        },
      ];
      if (this.compare && this.classesTableCompare) {
        data = [
          ...data,
          {
            name: this.$t('Cyclists') + ' 2',
            data: this.classesTableCompare?.map(item => item.sum),
          },
        ];
      }
      return data;
    },
    graphLabels() {
      return this.classesTable?.map(item => item.range + ' km/h');
    },
    temperatureData() {
      let data = [
        {
          name: this.$t('Cyclists') + (this.compare ? ' 1' : ''),
          data: this.temperatureTable?.map(item => item.sum),
        },
      ];
      if (this.compare && this.temperatureTableCompare) {
        data = [
          ...data,
          {
            name: this.$t('Cyclists') + ' 2',
            data: this.temperatureTableCompare?.map(item => item.sum),
          },
        ];
      }
      return data;
    },
    temperatureLabels() {
      return this.temperatureTable?.map(item => item.range + ' °C');
    },
  },
  watch: {
    '$route.params.id': function(id) {
      this.loadClasses();
    },
  },
};
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-slider.scss';
</style>
