import Vue from 'vue';
import VueRouter from 'vue-router';
import { canNavigate } from '@/libs/acl/routeProtection';
import {
  isUserLoggedIn,
  getUserData,
  getHomeRouteForLoggedInUser,
} from '@/auth/utils';
import errors from './errors';
import auth from './auth';
import speeding from './speeding';
import settings from './settings';
import store from '@/store';
import cyclist from './cyclist';
import vehicle from './vehicle';
import radar from './radar';
import iframe from './iframe';

Vue.use(VueRouter);

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  scrollBehavior() {
    return { x: 0, y: 0 };
  },
  routes: [
    {
      path: '/',
      name: 'locations',
      component: () => import('@/views/Locations.vue'),
      meta: {
        pageTitle: 'Locations',
        breadcrumb: [
          {
            text: 'Locations',
            active: true,
          },
        ],
        resource: 'Location',
      },
    },
    {
      path: '/profile',
      name: 'profile',
      component: () => import('@/views/profile/Profile.vue'),
      meta: {
        pageTitle: 'Profile',
        breadcrumb: [
          {
            text: 'Profile',
            active: true,
          },
        ],
        resource: 'Basic',
      },
    },
    ...settings,
    ...speeding,
    ...cyclist,
    ...vehicle,
    ...radar,
    ...auth,
    ...errors,
    ...iframe,
  ],
});

router.beforeEach(async (to, _, next) => {
  const isLoggedIn = isUserLoggedIn();

  const userData = getUserData();
  if (to.name == 'iframe' || to.name == 'iframe-locations') {
    return next();
  }
  if (!canNavigate(to)) {
    // Redirect to login if not logged in
    if (!isLoggedIn) return next({ name: 'login' });

    if (!router.resolve(to).resolved.matched.length > 0)
      return next({
        name: 'error-404',
      });
    // If logged in => not authorized
    return next({
      name: 'misc-not-authorized',
    });
  }

  if (to.meta.redirectIfLoggedIn && isLoggedIn && userData.isPasswordSet) {
    next(getHomeRouteForLoggedInUser(userData ? 'admin' : null));
  }

  // redirect if user didn't set new password
  if (
    isLoggedIn &&
    !userData.isPasswordSet &&
    to.name != 'change-password' &&
    store.state.auth.loggedInAs == null
  ) {
    return next({
      name: 'change-password',
    });
  }

  // save current device in store if it is not set
  if (to.meta.preloadDevice) {
    if (
      !store.state.devices.currentDevice ||
      !to.params.id ||
      (store.state.devices.currentDevice &&
        store.state.devices.currentDevice.id != to.params.id)
    ) {
      await store.dispatch('devices/GET_AND_SET_DEVICE', {
        deviceId: to.params.id,
        userId: userData.userId,
      });
    }

    // check for device permissions
    if (
      !(
        (to.meta.read && store.state.devices.currentDevice.read) ||
        (to.meta.manage && store.state.devices.currentDevice.manage)
      )
    )
      return next({
        name: 'misc-not-authorized',
      });
  }

  return next();
});

// ? For splash screen
// Remove afterEach hook if you are not using splash screen
router.afterEach(() => {
  // Remove initial loading
  const appLoading = document.getElementById('loading-bg');
  if (appLoading) {
    appLoading.style.display = 'none';
  }
});

export default router;
