import store from '@/store';
import { Ability } from '@casl/ability';
import { initialAbility } from './config';

//  Read ability from localStorage
// * Handles auto fetching previous abilities if already logged in user
const userData = store.state.auth.userData;
const existingAbility =
  userData && userData.ability && userData.ability.length
    ? userData.ability
    : null;

export default new Ability(existingAbility || initialAbility);
