<template>
  <div>
    <content-header
      @dateChanged="dateChanged"
      datepicker
      vehicleDropdown
      @vehicleTypeChanged="vehicleTypeChanged"
      directionDropdown
      @directionChanged="directionChanged"
      v-if="hideHeader == false"
      @compareChanged="compareChanged"
      @compareDateChanged="compareDateChanged"
      :compare="compare"
    >
    </content-header>
    <bar-statistics title="" :data="items" />
    <apex-line-chart
      :compare="compare"
      :option="graphDataFiltered"
      :title="$t('speeding.average_and_max_speed')"
      nodatepicker
      yUnit="km/h"
    ></apex-line-chart>
  </div>
</template>

<script>
import BarStatistics from '@/views/components/BarStatistics';
import ContentHeader from '@/views/components/ContentHeader.vue';
import ApexLineChart from '@/views/components/ApexLineChart.vue';
import vSelect from 'vue-select';
import DynamicChart from '@/views/components/DynamicChart.vue';
import { getStartDateFromStore, getEndDateFromStore } from '@/utils';

const vehicleTypes = {
  1: 'single_track',
  2: 'car',
  3: 'combined',
  4: 'truck',
  5: 'truck_with_trailer',
};

export default {
  props: {
    id: Number,
    dateFrom: String,
    dateTo: String,
    hideHeader: Boolean,
  },
  components: {
    BarStatistics,
    ContentHeader,
    ApexLineChart,
    vSelect,
    DynamicChart,
  },
  data() {
    return {
      dateRange: [getStartDateFromStore(), getEndDateFromStore()],
      stats: {
        avg: 0,
        max: 0,
        graph: [],
      },
      vehicleType: null,
      direction: null,
      compare: false,
      compareDateRange: [getStartDateFromStore(), getEndDateFromStore()],
      compareStats: { graph: [] },
    };
  },
  async mounted() {
    this.loadData();
  },
  computed: {
    graph() {
      return this.stats.graph;
    },
    barChartData() {
      return [
        {
          name: this.$t('speeding.average_speed'),
          data: this.stats?.graph?.map(item => item.avg),
        },
        {
          name: this.$t('speeding.max_speed'),
          data: this.stats?.graph?.map(item => item.max),
        },
      ];
    },
    items() {
      return [
        {
          icon: 'AlertCircleIcon',
          color: 'light-info',
          title: (Math.round(this.stats.speedLimit) ?? '0') + ' km/h',
          subtitle: this.$t('speeding.speed_limit'),
          customClass: 'mb-2 mb-xl-0 col-md-2',
        },
        {
          icon: 'ActivityIcon',
          color: 'light-info',
          title: (Math.round(this.stats.avg) ?? '0') + ' km/h',
          subtitle: this.$t('speeding.average_speed'),
          customClass: 'mb-2 mb-xl-0 col-md-2',
        },
        {
          icon: 'BarChart2Icon',
          color: 'light-danger',
          title: (this.stats?.max?.max ?? '0') + ' km/h',
          subtitle: this.$t('speeding.max_speed'),
          customClass: 'mb-2 mb-sm-0 col-md-2',
          datetime: this.stats?.max?.date
            ? this.$moment(this.stats?.max?.date).format('DD.MM.y HH:mm')
            : '',
          vehicleType: vehicleTypes[this.stats?.max?.vehicleType]
            ? this.$t('vehicle.' + vehicleTypes[this.stats?.max?.vehicleType])
            : '',
        },
        {
          icon: 'TrendingUpIcon',
          color: 'light-danger',
          title: this.stats.aboveSpeedLimit,
          subtitle: this.$t('speeding.vehicle_number_over_limit'),
          customClass: 'mb-2 mb-xl-0 col-md-2',
        },
        {
          icon: 'TrendingDownIcon',
          color: 'light-success',
          title: this.stats.underSpeedLimit,
          subtitle: this.$t('speeding.vehicle_number_under_limit'),
          customClass: 'mb-2 mb-xl-0 col-md-2',
        },
      ];
    },
    graphDataFiltered() {
      let series = this.getSeries(this.stats.graph, this.compare ? ' 1' : '');
      if (this.compare) {
        const compareGraph = this.getSeries(this.compareStats.graph, ' 2');
        series = [...series, ...compareGraph];
      }
      return {
        series,
      };
    },
  },
  methods: {
    getData(graph, dataName) {
      return (
        graph?.map(d => {
          return {
            x: d.dateTime,
            y: d[dataName] == 0 ? null : d[dataName],
          };
        }) ?? []
      );
    },
    getSeries(graph, additionalTitle = '') {
      return [
        {
          name: this.$t('speeding.average_speed') + additionalTitle,
          data: this.getData(graph, 'avg'),
        },
        {
          name: this.$t('speeding.max_speed') + additionalTitle,
          data: this.getData(graph, 'max'),
        },
        {
          name: this.$t('speeding.speed_limit') + additionalTitle,
          data: this.getData(graph, 'speedLimit'),
        },
      ];
    },
    async compareChanged(state) {
      this.compare = state;
    },
    vehicleTypeChanged(vehicleType) {
      this.vehicleType = vehicleType;
      this.loadData();
      if (this.compare) this.compareDateChanged();
    },
    directionChanged(direction) {
      this.direction = direction;
      this.loadData();
      if (this.compare) this.compareDateChanged();
    },
    async dateChanged(dateRange) {
      const dates = dateRange.split('to');
      this.dateRange = dates;
      this.loadData();
    },
    async compareDateChanged(dateRange) {
      if (dateRange) {
        const dates = dateRange.split('to');
        this.compareDateRange = [dates[0], dates[1]];
      }
      const response = await this.$store.dispatch('stats/GET_BAR_DATA', {
        id: this.id != null ? this.id : this.$route.params.id,
        barName: 'average',
        dateFrom: this.compareDateRange[0],
        dateTo: this.compareDateRange[1],
        app: 'vehicle',
        direction: this.direction,
        vehicleType: this.vehicleType,
      });
      this.compareStats = response.data;
    },
    async loadData() {
      let dateFrom = null,
        dateTo = null;
      if (this.dateRange) {
        dateFrom = this.dateRange[0];
        dateTo = this.dateRange[1];
      }
      //Bar statistics
      const response = await this.$store.dispatch('stats/GET_BAR_DATA', {
        id: this.id != null ? this.id : this.$route.params.id,
        barName: 'average',
        dateFrom: this.dateFrom != null ? this.dateFrom : this.dateRange[0],
        dateTo: this.dateTo != null ? this.dateTo : this.dateRange[1],
        app: 'vehicle',
        direction: this.direction,
        vehicleType: this.vehicleType,
      });
      this.stats = response.data;
    },
  },
  watch: {
    '$route.params.id': function(id) {
      this.loadData();
    },
  },
};
</script>

<style></style>
