import moment from 'moment';
import store from '@/store';

const API_DATE_TIME_FORMAT = 'y-MM-DD HH:mm';
const APP_DATE_TIME_FORMAT = 'DD.MM.YYYY HH:mm';

/**
 * Return formated date for API or start of month date
 * @param {String} date date to format
 */
export const formatOrStartOfMonthDate = date => {
  return date
    ? moment(date, APP_DATE_TIME_FORMAT)
        .utc()
        .format(API_DATE_TIME_FORMAT)
        .toString()
    : moment()
        .subtract(7, 'days')
        .utc()
        .format(API_DATE_TIME_FORMAT)
        .toString();
};

/**
 * Return formated date for API or end of month date
 * @param {String} date date to format
 */
export const formatOrEndOfMonthDate = date => {
  return date
    ? moment(date, APP_DATE_TIME_FORMAT)
        .utc()
        .format(API_DATE_TIME_FORMAT)
        .toString()
    : moment()
        .endOf('day')
        .utc()
        .format(API_DATE_TIME_FORMAT)
        .toString();
};

/**
 * Return formated date for API or end of month date
 * @param {String} date date to format
 */
export const formatDateFromAPI = date => {
  return date
    ? moment(date, API_DATE_TIME_FORMAT)
        .format(APP_DATE_TIME_FORMAT)
        .toString()
    : '';
};

export const getStartDateFromStore = () => {
  return store.state.app.currentDate.startDate
    ? moment(store.state.app.currentDate.startDate)
        .format('DD.MM.y HH:mm')
        .toString()
    : moment()
        .subtract(7, 'days')
        .format('DD.MM.y HH:mm');
};

export const getEndDateFromStore = () => {
  return store.state.app.currentDate.endDate
    ? moment(store.state.app.currentDate.endDate)
        .format('DD.MM.y HH:mm')
        .toString()
    : moment()
        .endOf('day')
        .format('DD.MM.y HH:mm');
};

export const monthNames = [
  'Januar',
  'Februar',
  'Marec',
  'April',
  'Maj',
  'Junij',
  'Julij',
  'Avgust',
  'September',
  'Oktober',
  'November',
  'December',
];
