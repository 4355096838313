<template>
  <div>
    <b-row>
      <b-col md="6">
        <b-form-group label-cols="4" label-cols-lg="2" :label="$t('title')">
          <b-form-input v-model="device.name" />
        </b-form-group>
        <b-form-group
          label-cols="4"
          label-cols-lg="2"
          :label="$t('settings.device_id')"
        >
          <b-form-input v-model="device.location" />
        </b-form-group>
        <b-form-group
          label-cols="4"
          label-cols-lg="2"
          :label="$t('generalSettings.device_type')"
        >
          <b-form-input disabled v-model="device.title" />
        </b-form-group>
        <b-form-group
          label-cols="4"
          label-cols-lg="2"
          :label="$t('settings.firmware_version')"
        >
          <b-form-input disabled v-model="device.firmware" />
        </b-form-group>
        <b-form-group
          label-cols="4"
          label-cols-lg="2"
          :label="$t('settings.serial_num')"
        >
          <b-form-input disabled v-model="device.serialNumber" />
        </b-form-group>
        <b-form-group
          label-cols="4"
          label-cols-lg="2"
          :label="$t('generalSettings.latitude')"
        >
          <b-form-input v-model="device.latitude" />
        </b-form-group>
        <b-form-group
          label-cols="4"
          label-cols-lg="2"
          :label="$t('generalSettings.longitude')"
        >
          <b-form-input v-model="device.longitude" />
        </b-form-group>
        <b-form-group
          label-cols="4"
          label-cols-lg="2"
          :label="$t('settings.measurment_direction')"
        >
          <b-form-select
            class="bordered"
            v-model="device.measureDirection"
            :options="stateOptions"
          />
        </b-form-group>
        <b-form-group
          label-cols="4"
          label-cols-lg="2"
          :label="$t('settings.counter_value') + ' (Danes/Letos)'"
        >
          <!--<b-form-select
            class="bordered"
            v-model="device.counterValue"
            :options="counterOptions"
          />-->
          <div class="d-flex">
            <b-form-input
              v-model="todayCount"
              placeholder="Danes"
              class="mr-1"
            />
            <b-form-input v-model="yearCount" placeholder="Letos" />
          </div>
        </b-form-group>
      </b-col>
      <b-col md="6">
        <geo-settings ref="geoSettings" :device="device"></geo-settings>
      </b-col>
    </b-row>
    <div class="d-flex justify-content-between">
      <!--<span>{{ $t('last_updated') }} {{ lastUpdated }}</span>-->
      <div>
        <b-button @click="updateData" variant="primary"
          >{{ $t('save') }}
        </b-button>
        <b-button @click="refreshData" variant="outline-primary" class="ml-1"
          >{{ $t('refresh') }}
        </b-button>
      </div>
    </div>
  </div>
</template>

<script>
import {
  BFormGroup,
  BFormInput,
  BButton,
  BFormSelect,
  BRow,
  BCol,
} from 'bootstrap-vue';
import { ref, onMounted } from '@vue/composition-api';
import store from '@/store';
import i18n from '@/libs/i18n';

// Notification
import { useToast } from 'vue-toastification/composition';
import GeoSettings from './GeoSettings.vue';
import { dangerToast, successToast } from '@/utils/toast';

export default {
  props: {
    id: {
      type: String,
    },
  },
  components: {
    BFormGroup,
    BFormInput,
    BButton,
    BFormSelect,
    BRow,
    BCol,
    GeoSettings,
  },
  setup(props, context) {
    const toast = useToast();

    const stateOptions = [
      { value: null, text: i18n.t('select_detection_direction') },
      { value: 'O', text: i18n.t('both_directions') },
      { value: 'L', text: i18n.t('outgoing') },
      { value: 'D', text: i18n.t('incoming') },
      { value: '1', text: i18n.t('first_loop') },
      { value: '2', text: i18n.t('second_loop') },
    ];

    const counterOptions = [
      { value: null, text: 'Izberite vrednost števca' },
      { value: 'DANES', text: 'Dnevni' },
      { value: 'LETOS', text: 'Letni' },
    ];

    const todayCount = ref(null);
    const yearCount = ref(null);

    const device = ref({});
    const geoSettings = ref(null);

    const loadDevice = async () => {
      const response = await store.dispatch(
        'devices/GET_CYCLIST_DEVICE',
        context.root.$route.params.id,
      );
      device.value = response.data;
      device.value.measureDirection = response.data.measureDirection.replace(
        '\r',
        '',
      );
      updateCounters();
    };

    onMounted(async () => {
      loadDevice();
    });

    const updateCounters = () => {
      if (device.value.counterValue) {
        const counters = device.value.counterValue.split(',');
        todayCount.value = counters[0];
        yearCount.value = counters[1];
      }
    };

    const updateData = async () => {
      store
        .dispatch('devices/UPDATE', {
          id: context.root.$route.params.id,
          name: device.value.name,
          location: device.value.location,
          longitude: device.value.longitude,
          latitude: device.value.latitude,
        })
        .then(response => {
          successToast(
            toast,
            i18n.t('success'),
            i18n.t('update_success_descirption'),
          );
        })
        .catch(e => {
          dangerToast(toast, i18n.t('failed'), i18n.t(e.response.data.message));
        });

      store
        .dispatch('devices/UPDATE_CYCLIST_SETTINGS', {
          deviceId: context.root.$route.params.id,
          userId: store.state.auth.userData.userId,
          data: {
            direction: device.value.measureDirection,
            counter: todayCount.value + ',' + yearCount.value,
          },
        })
        .then(response => {
          successToast(
            toast,
            i18n.t('success'),
            i18n.t('update_device_success'),
          );
        })
        .catch(e => {
          dangerToast(toast, i18n.t('failed'), i18n.t(e.response.data.message));
        });
    };

    const refreshData = () => {
      store
        .dispatch('devices/GET_CYCLIST_SETTINGS', {
          deviceId: context.root.$route.params.id,
          userId: store.state.auth.userData.userId,
        })
        .then(response => {
          if (response.data) {
            device.value = response.data;
            device.value.measureDirection = response.data.measureDirection.replace(
              '\r',
              '',
            );
            updateCounters();
            successToast(
              toast,
              i18n.t('success'),
              i18n.t('update_success_descirption'),
            );
          }
        })
        .catch(e => {
          dangerToast(toast, i18n.t('failed'), i18n.t(e.response.data.message));
        });
    };

    const updateMap = () => {
      geoSettings.value.updateMap();
    };

    return {
      stateOptions,
      updateData,
      refreshData,

      counterOptions,
      device,
      updateMap,
      geoSettings,

      todayCount,
      yearCount,
    };
  },
};
</script>

<style></style>
