<template>
  <b-card :title="title" style="padding:21px">
    <div class="d-flex justify-content-between flex-wrap">
      <div class="mb-1 mb-sm-0">
        <span class="text-muted">{{ subtitle }}</span>
        <slot name="additional-header"></slot>
      </div>
      <div class="d-flex align-items-center" v-if="!nodatepicker">
        <slot name="additional-tools"></slot>
        <date-range-picker
          class="ml-2"
          @date-changed="dateChanged"
          ref="dateRangePicker"
          id="dateRange"
        >
        </date-range-picker>
      </div>
    </div>

    <!-- echart -->
    <!--<app-echart-line :option-data="option" />-->
    <div style="overflow: hidden;position:relative;">
      <vue-apex-charts
        style="overflow: hidden"
        type="line"
        height="400"
        :options="graphOption"
        :series="option.series"
        ref="apexChart"
      />
      <apex-toolbar
        @change-title="changeTitle"
        :chart="$refs.apexChart"
        :series="option.series"
      ></apex-toolbar>
    </div>
  </b-card>
</template>

<script>
import { BCard, BBadge } from 'bootstrap-vue';
import AppEchartLine from '@core/components/charts/echart/AppEchartLine.vue';
import DateRangePicker from './DateRangePicker.vue';
import VueApexCharts from 'vue-apexcharts';
import ApexToolbar from './ApexToolbar.vue';
const sl = require('apexcharts/dist/locales/sl.json');

export default {
  components: {
    BCard,
    AppEchartLine,
    BBadge,
    DateRangePicker,
    VueApexCharts,
    ApexToolbar,
  },
  props: {
    option: {
      type: Object,
    },
    title: {
      type: String,
    },
    subtitle: {
      type: String,
    },
    dashArray: { type: Array },
    nodatepicker: {
      type: Boolean,
    },
    compare: {
      type: Boolean,
      default: false,
    },
    isDaily: {
      type: Boolean,
      default: false,
    },
    dateFrom: {
      type: Number,
      default: null,
    },
    dateTo: {
      type: Number,
      default: null,
    },
    yUnit: {
      type: String,
      default: null,
    },
  },
  data() {
    return { chartTitle: '', dateTime: this.dateTime };
  },
  computed: {
    graphOption() {
      return {
        title: {
          text: this.chartTitle,
        },
        subtitle: { text: '' },
        stroke: {
          width: 2,
          dashArray: this.dashArray ? this.dashArray : 0,
        },
        chart: {
          width: '100%',
          stacked: false,
          locales: [sl],
          defaultLocale: 'sl',
          toolbar: {
            show: false,
            offsetX: 0,
            offsetY: 0,
            tools: {
              download: false,
            },
          },
          zoom: {
            enabled: false,
          },
        },
        xaxis: {
          min: this.dateFrom ? this.dateFrom : undefined,
          max: this.dateTo ? this.dateTo : undefined,
          type: this.compare ? 'category' : 'datetime',
          tooltip: {
            enabled: true,
          },
          labels: {
            rotate: -70,
            datetimeUTC: false,
          },
          tooltip: {
            enabled: true,
          },
          tickAmount: this.compare ? 20 : undefined,
        },
        yaxis: {
          tooltip: {
            enabled: true,
          },
          labels: {
            formatter: val => {
              return val
                ? this.yUnit
                  ? parseFloat(val).toFixed(0) + this.yUnit
                  : parseFloat(val).toFixed(0)
                : 0;
            },
          },
        },
        tooltip: {
          show: true,
          x: {
            show: true,
            format: 'dd.MM.yyyy HH:mm', //'ddddd',
            formatter: undefined,
          },
        },

        grid: {
          xaxis: {
            lines: {
              show: true,
            },
          },
        },
        legend: {
          show: true,
          showForSingleSeries: true,
        },
      };
    },
  },
  methods: {
    changeTitle(title, subtitle = '') {
      this.chartTitle = title;
      this.dateTime = subtitle;
    },
    dateChanged(dateRange) {
      this.$emit('date-changed', dateRange);
    },
  },
};
</script>
