<template>
  <div>
    <content-header datepicker @dateChanged="dateChanged"></content-header>
    <b-row>
      <b-col md="3">
        <b-card>
          <div class="mb-2">{{ $t('stats_in_range') }}</div>
          <b-table responsive="sm" :items="getTableData" :fields="fields">
            <template #cell(low)="data">
              {{ data.item.low }} - {{ data.item.high }} km/h
            </template>
          </b-table>
          <div class="mt-3">
            <div class="mb-1">{{ $t('classes.custom_speed') }}</div>
            <b-row class="mb-1">
              <b-col class="text-center" md="6">
                {{ sliderValue[0] }} - {{ sliderValue[1] }} km/h
              </b-col>
              <b-col class="text-center" md="6">
                {{ customValueCount }}
              </b-col>
            </b-row>
            <vue-slider
              v-model="sliderValue"
              :tooltip="'active'"
              class="mb-2 vue-slider-primary"
              direction="ltr"
              :marks="val => val % 10 === 0"
              :min="sliderMin"
              :max="sliderMax"
              :enableCross="false"
              @drag-end="sliderChange"
              @change="sliderChange"
              :interval="getSliderInterval"
            />
          </div>
        </b-card>
      </b-col>
      <b-col md="9">
        <e-chart-bar
          :title="$t('classes.speed_classes')"
          :data="graphData"
          :lables="graphLabels"
          nodatepicker
        ></e-chart-bar>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import { BTable, BRow, BCol, BCard } from 'bootstrap-vue';
//import ChartjsBarChart from './ChartjsBarChart.vue';
import EChartBar from '@/views/components/EChartBar.vue';
import ContentHeader from '@/views/components/ContentHeader.vue';
import VueSlider from 'vue-slider-component';

export default {
  props: {
    id: { type: String, required: false },
  },
  components: {
    BTable,
    BCard,
    BRow,
    BCol,
    //ChartjsBarChart,
    EChartBar,
    ContentHeader,
    VueSlider,
  },
  mounted() {
    this.loadClasses();
  },
  data() {
    return {
      sliderMin: 0,
      sliderMax: 100,
      sliderValue: [0, 100],
      customValueCount: 0,
      classes: [],
      fields: [
        {
          label: this.$t('classes.classes'),
          key: 'low',
          sortable: false,
        },
        {
          label: this.$t('number'),
          key: 'sum',
          sortable: false,
        },
      ],

      allData: [],
      lables: [],
    };
  },
  methods: {
    async sliderChange() {
      if (this.sliderValue[0] && this.sliderValue[1]) {
        let response = await this.$store.dispatch(
          'stats/GET_CUSTOM_CLASSES_DATA',
          {
            id: this.$route.params.id,
            dateFrom: this.dateStart,
            dateTo: this.dateEnd,
            min: this.sliderValue[0],
            max: this.sliderValue[1],
            app: 'vehicle',
          },
        );
        this.customValueCount = response.data.sum;
      }
    },
    async loadClasses(dateFrom, dateTo, minLimit, maxLimit) {
      let response = await this.$store.dispatch('stats/GET_CLASSES_DATA', {
        id: this.$route.params.id,
        dateFrom,
        dateTo,
        app: 'vehicle',
        vehicleType: this.vehicleType,
        direction: this.direction,
      });

      this.allData = response.data;
      if (this.allData.length > 0) {
        const { min, max } = this.getMinAndMax();
        this.sliderValue = [min, max];
        this.sliderMin = min;
        this.sliderMax = max;
        this.sliderChange();
      }
    },
    dateChanged(dateRange) {
      if (dateRange) {
        const dates = dateRange.split('to');
        this.dateStart = dates[0];
        this.dateEnd = dates[1];
        this.loadClasses(dates[0], dates[1], 0, 100);
      }
    },
    getMinAndMax() {
      let min = this.allData?.length ? this.allData[0].low : 0;
      let max = this.allData?.length ? this.allData[0].high : 100;

      this.allData.forEach(data => {
        if (data.low < min) min = data.low;
        if (data.high > max) max = data.high;
      });

      return {
        min,
        max,
      };
    },
    getAllHigh() {
      const classes = this.allData.map(data => data.high);
      return [...new Set(classes)].sort();
    },
    getClasses() {
      const classes = this.allData?.map(item => {
        return `${item.low} - ${item.high} km/h`;
      });

      return [...new Set(classes)].sort();
    },
    getChartDataForType(title_translation_key, vehicleType) {
      const uniqueAndSorted = this.getAllHigh();

      let data = [];
      uniqueAndSorted.forEach(high => {
        const entry = this.allData.find(e => {
          return e.high == high && e.vehicleType == vehicleType;
        });
        if (entry) {
          data.push(entry.sum);
        } else {
          data.push(0);
        }
      });

      return {
        name: this.$t(title_translation_key),
        data,
      };
    },
  },
  computed: {
    getTableData() {
      const classes = this.getClasses();
      if (classes == null) return [];

      let classesMap = {};
      classes.forEach(c => (classesMap[c] = { sum: 0 }));

      this.allData.forEach(item => {
        const key = `${item.low} - ${item.high} km/h`;
        classesMap[key] = {
          low: item.low,
          high: item.high,
          sum: classesMap[key].sum + item.sum,
        };
      });

      return Object.values(classesMap);
    },
    getSliderInterval() {
      return 1;
    },
    graphData() {
      return [
        this.getChartDataForType('vehicle.single_track', 1),
        this.getChartDataForType('vehicle.car', 2),
        this.getChartDataForType('vehicle.combined', 3),
        this.getChartDataForType('vehicle.truck', 4),
        this.getChartDataForType('vehicle.truck_with_trailer', 5),
      ];
    },
    graphLabels() {
      return this.getClasses();
    },
  },
  watch: {
    '$route.params.id': function(id) {
      this.loadClasses();
    },
  },
};
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-slider.scss';
</style>
