<template>
  <div>
    <b-form-group
      label-cols="4"
      label-cols-lg="2"
      :label="$t('generalSettings.latitude')"
    >
      <b-form-input v-model="settings.latitude" />
    </b-form-group>
    <b-form-group
      label-cols="4"
      label-cols-lg="2"
      :label="$t('generalSettings.longitude')"
    >
      <b-form-input v-model="settings.longitude" />
    </b-form-group>
    <div class="float-right">
      <b-button @click="updateData" variant="primary">{{
        $t('save')
      }}</b-button>
    </div>
  </div>
</template>

<script>
import { BFormGroup, BFormInput, BButton, BFormSelect } from 'bootstrap-vue';
import store from '@/store';
import i18n from '@/libs/i18n';

// Notification
import { useToast } from 'vue-toastification/composition';
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue';
import useDeviceSetttings from '@/views/apps/speeding/settings/useDeviceSettings';

export default {
  props: {
    id: {
      type: String,
    },
  },
  components: {
    BFormGroup,
    BFormInput,
    BButton,
    BFormSelect,
  },
  setup(props, context) {
    const toast = useToast();

    const { settings } = useDeviceSetttings(context, 'geolocation');

    const updateData = async () => {
      store
        .dispatch('devices/UPDATE', {
          id: context.root.$route.params.id,
          longitude: settings.value.longitude,
          latitude: settings.value.latitude,
        })
        .then(response => {
          toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: i18n.t('success'),
              icon: 'CheckIcon',
              variant: 'success',
              text: i18n.t('update_success_descirption'),
            },
          });
        })
        .catch(e => {
          toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: i18n.t('failed'),
              icon: 'AlertTriangleIcon',
              variant: 'danger',
              text: i18n.t(e.response.data.message),
            },
          });
        });
    };

    return {
      settings,
      updateData,
    };
  },
};
</script>

<style></style>
