import RadarInfo from '@/views/apps/radar/RadarInfo.vue';
import AverageStats from '@/views/apps/radar/AverageStats.vue';
import ClassStats from '@/views/apps/radar/ClassStats.vue';
import CounterStats from '@/views/apps/radar/CounterStats.vue';
import Report from '@/views/apps/shared/Report.vue';
import PercentilStats from '@/views/apps/radar/PercentilStats.vue';
import Settings from '@/views/apps/radar/settings/Settings.vue';

export default [
  {
    path: '/radar/:id',
    name: 'radar.dashboard',
    props: true,
    component: () => import('@/views/apps/Index.vue'),
    children: [
      {
        path: 'info',
        component: RadarInfo,
        meta: {
          preloadDevice: true,
          resource: 'RadarStats',
          read: true,
          manage: false,
        },
      },
      {
        path: 'average',
        component: AverageStats,
        meta: {
          preloadDevice: true,
          resource: 'RadarStats',
          read: true,
          manage: false,
        },
      },
      {
        path: 'classes',
        component: ClassStats,
        meta: {
          preloadDevice: true,
          resource: 'RadarStats',
          read: true,
          manage: false,
        },
      },
      {
        path: 'counter',
        component: CounterStats,
        meta: {
          preloadDevice: true,
          resource: 'RadarStats',
          read: true,
          manage: false,
        },
      },
      {
        path: 'percentil',
        component: PercentilStats,
        meta: {
          preloadDevice: true,
          resource: 'RadarStats',
          read: true,
          manage: false,
        },
      },
      {
        path: 'report',
        component: Report,
        meta: {
          preloadDevice: true,
          resource: 'RadarStats',
          read: true,
          manage: false,
        },
      },
      {
        name: 'radar-settings',
        path: 'settings',
        component: Settings,
        meta: {
          preloadDevice: true,
          resource: 'Device',
          action: 'write',
          read: false,
          manage: true,
        },
      },
    ],
    meta: {
      pageTitle: 'Dashboard',
      breadcrumb: [
        {
          text: 'Dashboard',
          active: true,
        },
      ],
      preloadDevice: true,
    },
  },
];
