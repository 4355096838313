<template>
  <div
    style="max-height: 300px;
    overflow-y: scroll;"
    id="shell-container"
  >
    <v-shell
      :banner="banner"
      :shell_input="send_to_terminal"
      :commands="commands"
      @shell_output="prompt"
    ></v-shell>
    <div class="mt-1">
      <b-button @click="prompt('\x17')" variant="outline-primary"
        >Ctrl + W</b-button
      >
      <b-button @click="prompt('\x15')" variant="outline-primary" class="ml-1"
        >Ctrl + U</b-button
      >
      <b-button @click="prompt('\x1B')" variant="outline-primary" class="ml-1"
        >Escape</b-button
      >
      <b-button @click="prompt('\x0D')" variant="outline-primary" class="ml-1"
        >Enter</b-button
      >
    </div>
  </div>
</template>

<script>
import vshell from 'vue-shell';
import { BButton } from 'bootstrap-vue';
export default {
  name: 'App',
  components: { 'v-shell': vshell, BButton },
  data() {
    return {
      send_to_terminal: '',
      banner: {
        emoji: {
          first: '',
          second: '',
          time: 750,
        },
        sign: `Intermatic $`,
      },
      commands: [
        /* {
          name: 'info',
          get() {
            return `<p>Ukazna vrstica Intermatic</p>`;
          },
        },
        {
          name: 'uname',
          get() {
            return navigator.appVersion;
          },
        },*/
      ],
    };
  },
  methods: {
    async prompt(value) {
      try {
        const response = await this.$store.dispatch('devices/SEND_COMMAND', {
          deviceId: this.$route.params.id,
          command: value,
        });
        this.send_to_terminal = response.data;
      } catch (e) {
        this.send_to_terminal = this.$t(e.response.data.message);
      }
      let objDiv = document.getElementById('shell-container');
      this.$nextTick(() => {
        objDiv.scrollTop = objDiv.scrollHeight;
      });
    },
  },
};
</script>

<style lang="scss">
pre {
  background-color: black !important;
  color: white !important;
}
.cmdline {
  width: 300px !important;
}
.prompt {
  color: $primary !important;
}
</style>
