<template>
  <div>
    <content-header @dateChanged="dateChanged" datepicker> </content-header>
    <b-row>
      <b-col md="6">
        <bar-statistics
          :title="$t('cyclist.temperature')"
          :data="temperatureBar"
        />
      </b-col>
      <b-col md="6">
        <bar-statistics :title="$t('cyclist.voltage')" :data="voltageBar" />
      </b-col>
    </b-row>
    <apex-line-chart
      :option="graphDataFiltered"
      :title="$t('cyclist.temperature_and_voltage')"
      :subtitle="$t('moving_in_range')"
      nodatepicker
    >
    </apex-line-chart>
    <e-chart-bar
      :title="$t('cyclist.number_of_cyclists_per_temperature')"
      :data="graphData"
      :lables="graphLabels"
      nodatepicker
    ></e-chart-bar>
  </div>
</template>

<script>
import BarStatistics from '@/views/components/BarStatistics';
import { BRow, BCol } from 'bootstrap-vue';

import ContentHeader from '@/views/components/ContentHeader.vue';
import ApexLineChart from '@/views/components/ApexLineChart.vue';
import EChartBar from '@/views/components/EChartBar.vue';
import { cyclistLinks } from '@/store/stats/action-links';
export default {
  props: {
    id: { type: String, required: false },
  },
  components: {
    BarStatistics,
    ContentHeader,
    ApexLineChart,
    EChartBar,
    BRow,
    BCol,
  },
  data() {
    return {
      stats: {
        avg: 0,
        speedLimit: 0,
        max: 0,
        underLimit: 0,
        aboveLimit: 0,
      },
    };
  },
  async mounted() {
    this.loadData();
  },
  computed: {
    voltageBar() {
      return [
        {
          icon: 'BarChart2Icon',
          color: 'light-primary',
          title: this.stats?.barData?.avgVoltage?.toFixed(1) ?? '0',
          subtitle: this.$t('cyclist.average_voltage'),
          customClass: 'mb-2 mb-xl-0',
        },
        {
          icon: 'ActivityIcon',
          color: 'light-info',
          title: this.stats?.barData?.maxVoltage ?? '0',
          subtitle: this.$t('cyclist.max_voltage'),
          customClass: 'mb-2 mb-xl-0',
        },
        {
          icon: 'ActivityIcon',
          color: 'light-danger',
          title: this.stats?.barData?.minVoltage ?? '0',
          subtitle: this.$t('cyclist.min_voltage'),
          customClass: 'mb-2 mb-sm-0',
        },
      ];
    },
    temperatureBar() {
      return [
        {
          icon: 'BarChart2Icon',
          color: 'light-primary',
          title: this.stats?.barData?.avgTemperature?.toFixed(1) ?? '0',
          subtitle: this.$t('cyclist.average_temperature'),
          customClass: 'mb-2 mb-xl-0',
        },
        {
          icon: 'ActivityIcon',
          color: 'light-info',
          title: this.stats?.barData?.maxTemperature ?? '0',
          subtitle: this.$t('cyclist.max_temperature'),
          customClass: 'mb-2 mb-xl-0',
        },
        {
          icon: 'ActivityIcon',
          color: 'light-danger',
          title: this.stats?.barData?.minTemperature ?? '0',
          subtitle: this.$t('cyclist.min_temperature'),
          customClass: 'mb-2 mb-sm-0',
        },
      ];
    },
    graphDataFiltered() {
      return {
        series: [
          {
            name: this.$t('cyclist.temperature'),
            data:
              this.stats?.graph?.map(d => {
                return {
                  x: d.date + ' ' + d.hour + ':00',
                  y: d.temperature,
                };
              }) ?? [],
          },
          {
            name: this.$t('cyclist.voltage'),
            data:
              this.stats?.graph?.map(d => {
                return {
                  x: d.date + ' ' + d.hour + ':00',
                  y: d.voltage,
                };
              }) ?? [],
          },
        ],
      };
    },
    graphData() {
      return [
        {
          name: this.$t('Cyclists'),
          data: this.stats?.graphByCyclists?.map(item => item.countAll),
        },
      ];
    },
    graphLabels() {
      return this.stats?.graphByCyclists?.map(item => {
        let low = item.prange * 10;
        if (low < 0) {
          return low - 9 + ' - ' + low + '°C';
        } else return low + 1 + ' - ' + (low + 10) + '°C';
      });
    },
  },
  methods: {
    async dateChanged(dateRange) {
      const dates = dateRange.split('to');
      this.loadData(dates[0], dates[1]);
    },
    async loadData(dateFrom = null, dateTo = null) {
      //Bar statistics
      const response = await this.$store.dispatch('stats/GET_DIAGNOSTIC', {
        id: this.$route.params.id,
        dateFrom,
        dateTo,
      });
      this.stats = response.data;
    },
  },
  watch: {
    '$route.params.id': function(id) {
      this.loadData();
    },
  },
};
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';

.select-width {
  width: 300px;
}
</style>
