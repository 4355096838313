<template>
  <div>
    <content-header
      datepicker
      @dateChanged="dateChanged"
      directionDropdown
      @directionChanged="directionChanged"
      v-if="hideHeader == false"
      @compareChanged="compareChanged"
      @compareDateChanged="compareDateChanged"
      :compare="compare"
    ></content-header>
    <b-row>
      <b-col md="3">
        <b-card>
          <div class="mb-2">{{ $t('stats_in_range') }}</div>
          <b-table responsive="sm" :items="classesTable" :fields="fields">
            <template #cell(range)="data">
              {{ data.item.range }} km/h
            </template>
          </b-table>
          <div class="mt-3">
            <div class="mb-1">{{ $t('classes.custom_speed') }}</div>
            <b-row class="mb-1">
              <b-col class="text-center" md="6">
                {{ sliderValue[0] }} - {{ sliderValue[1] }} km/h
              </b-col>
              <b-col class="text-center" md="6">
                {{ customValueCount }}
              </b-col>
            </b-row>
            <vue-slider
              v-model="sliderValue"
              :tooltip="'active'"
              class="mb-2 vue-slider-primary"
              direction="ltr"
              :marks="getMarks"
              :min="sliderMin"
              :max="sliderMax"
              :enableCross="false"
              @drag-end="sliderChange"
              @change="sliderChange"
              :interval="10"
            />
          </div>
        </b-card>
      </b-col>
      <b-col md="9">
        <e-chart-bar
          :title="$t('classes.speed_classes')"
          :data="graphData"
          :lables="graphLabels"
          nodatepicker
        ></e-chart-bar>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import { BTable, BRow, BCol, BCard } from 'bootstrap-vue';
//import ChartjsBarChart from './ChartjsBarChart.vue';
import EChartBar from '@/views/components/EChartBar.vue';
import ContentHeader from '@/views/components/ContentHeader.vue';
import VueSlider from 'vue-slider-component';
import { getStartDateFromStore, getEndDateFromStore } from '@/utils';

export default {
  props: {
    id: Number,
    dateFrom: String,
    dateTo: String,
    hideHeader: Boolean,
  },
  components: {
    BTable,
    BCard,
    BRow,
    BCol,
    //ChartjsBarChart,
    EChartBar,
    ContentHeader,
    VueSlider,
  },
  mounted() {
    this.loadClasses();
  },
  data() {
    return {
      dateRange: [getStartDateFromStore(), getEndDateFromStore()],
      direction: null,
      classesTable: [],
      deviceData: null,
      sliderMin: 0,
      sliderMax: 200,
      sliderValue: [0, 200],
      customValueCount: 0,
      fields: [
        {
          label: this.$t('classes.classes'),
          key: 'range',
          sortable: false,
        },
        {
          label: this.$t('number'),
          key: 'sum',
          sortable: false,
        },
      ],
      compare: false,
      compareDateRange: [getStartDateFromStore(), getEndDateFromStore()],
      classesTableCompare: [],
    };
  },
  methods: {
    getMarks(val) {
      return val % 30 === 0;
    },
    async sliderChange() {
      if (this.sliderValue[0] != null && this.sliderValue[1] != null) {
        let sum = 0;
        this.deviceData.forEach((k) => {
          let high = k.class;
          if (high >= this.sliderValue[0] && high <= this.sliderValue[1]) {
            sum += k.vehicleCount;
          }
        });
        this.customValueCount = sum;
      }
    },
    async loadClasses() {
      if (this.dateFrom != null && this.dateTo != null) {
        this.dateRange = [this.dateFrom, this.dateTo];
      }
      let response = await this.$store.dispatch('stats/GET_CLASSES_DATA', {
        id: this.id != null ? this.id : this.$route.params.id,
        dateFrom: this.dateFrom != null ? this.dateFrom : this.dateRange[0],
        dateTo: this.dateTo != null ? this.dateTo : this.dateRange[1],
        direction: this.direction,
        app: 'speeding',
      });
      this.deviceData = response.data;
      let table = [];
      if (this.deviceData.length > 0) {
        this.deviceData.forEach((k) => {
          let high = k.class;
          if (k.vehicleCount > 0)
            table.push({
              range: high - 9 + ' - ' + high,
              sum: k.vehicleCount,
            });
        });
      }
      this.classesTable = table;
      this.sliderChange();
    },
    dateChanged(dateRange) {
      if (dateRange) {
        const dates = dateRange.split('to');
        this.dateRange = [dates[0], dates[1]];
        this.loadClasses();
      }
    },
    directionChanged(direction) {
      this.direction = direction;
      this.loadClasses();
      if (this.compare) this.compareDateChanged();
    },
    async compareChanged(state) {
      this.compare = state;
    },
    async compareDateChanged(dateRange) {
      if (dateRange) {
        const dates = dateRange.split('to');
        this.compareDateRange = [dates[0], dates[1]];
      }
      let response = await this.$store.dispatch('stats/GET_CLASSES_DATA', {
        id: this.id != null ? this.id : this.$route.params.id,
        dateFrom: this.compareDateRange[0],
        dateTo: this.compareDateRange[1],
        minLimit: null,
        maxLimit: null,
        direction: this.direction,
        app: 'speeding',
      });
      const deviceData = response.data;
      let table = [];
      if (deviceData.length > 0) {
        deviceData.forEach((k) => {
          let high = k.class;
          if (k.vehicleCount > 0)
            table.push({
              range: high - 9 + ' - ' + high,
              sum: k.vehicleCount,
            });
        });
      }
      this.classesTableCompare = table;
    },
  },
  computed: {
    graphData() {
      let data = [
        {
          name: this.$t('Vehicle') + (this.compare ? ' 1' : ''),
          data: this.classesTable?.map((item) => item.sum),
        },
      ];
      if (this.compare && this.classesTableCompare) {
        data = [
          ...data,
          {
            name: this.$t('Vehicle') + ' 2',
            data: this.classesTableCompare?.map((item) => item.sum),
          },
        ];
      }
      return data;
    },
    graphLabels() {
      return this.classesTable?.map((item) => item.range + ' km/h');
    },
  },
  watch: {
    '$route.params.id': function (id) {
      this.loadClasses();
    },
  },
};
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-slider.scss';
</style>
