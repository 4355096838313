<template>
  <div>
    <b-row>
      <b-col md="6">
        <b-form-group
          label-cols="4"
          label-cols-lg="2"
          :label="$t('settings.device_id')"
        >
          <b-form-input disabled :value="settings.location" />
        </b-form-group>
        <b-form-group
          label-cols="4"
          label-cols-lg="2"
          :label="$t('settings.device_voltage')"
        >
          <b-form-input disabled :value="voltage + 'V'" />
        </b-form-group>
      </b-col>
      <b-col md="6">
        <b-form-group
          label-cols="4"
          label-cols-lg="2"
          :label="$t('settings.light_sensor')"
        >
          <b-form-input disabled :value="light" />
        </b-form-group>
        <b-form-group
          label-cols="4"
          label-cols-lg="2"
          :label="$t('settings.range')"
        >
          <b-form-input type="number" v-model="settings.range" />
        </b-form-group>
      </b-col>
    </b-row>
    <diagnostic ref="diagnosticChart"></diagnostic>
    <span>{{ $t('last_updated') }} {{ lastUpdated }}</span>
    <div class="float-right mb-1">
      <b-button @click="reboot(1)" variant="danger">{{
        $t('settings.reboot_device')
      }}</b-button>
      <b-button @click="reboot(2)" class="ml-1" variant="danger">{{
        $t('settings.reboot_microcontroller')
      }}</b-button>
      <b-button @click="updateData" variant="primary" class="ml-1">{{
        $t('save')
      }}</b-button>
      <b-button
        @click="refreshDataAdditional"
        variant="outline-primary"
        class="ml-1"
        >{{ $t('refresh') }}</b-button
      >
    </div>
  </div>
</template>

<script>
import {
  BFormGroup,
  BFormInput,
  BButton,
  BFormSelect,
  BRow,
  BCol,
} from 'bootstrap-vue';
import useDeviceSetttings from './useDeviceSettings';
import store from '@/store';
import { useToast } from 'vue-toastification/composition';
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue';
import i18n from '@/libs/i18n';
import { computed, ref } from '@vue/composition-api';
import Diagnostic from './DiagnosticChart.vue';

export default {
  props: {
    id: {
      type: String,
    },
  },
  components: {
    BFormGroup,
    BFormInput,
    BButton,
    BFormSelect,
    BRow,
    BCol,
    Diagnostic,
  },
  setup(props, context) {
    const {
      lastUpdated,
      settings,
      //updateData,
      refreshData,
    } = useDeviceSetttings(context, 'diagnostics');

    const toast = useToast();

    const diagnosticChart = ref();

    const updateChart = () => {
      diagnosticChart.value.updateChart();
    };

    const updateData = async () => {
      store
        .dispatch('devices/SET_RANGE', {
          deviceId: context.root.$route.params.id,
          userId: store.state.auth.userData.userId,
          range: settings.value.range,
        })
        .then(response => {
          toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: i18n.t('success'),
              icon: 'CheckIcon',
              variant: 'success',
              text: i18n.t('update_success_descirption'),
            },
          });
        })
        .catch(e => {
          toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: i18n.t('failed'),
              icon: 'AlertTriangleIcon',
              variant: 'danger',
              text: i18n.t(e.response.data.message),
            },
          });
        });
    };

    // 1- reboot device
    // 2- reboot microcontroller
    const reboot = async rebootType => {
      store
        .dispatch('devices/REBOOT', {
          deviceId: context.root.$route.params.id,
          userId: store.state.auth.userData.userId,
          type: rebootType,
        })
        .then(response => {
          toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: i18n.t('success'),
              icon: 'CheckIcon',
              variant: 'success',
              text:
                rebootType == 1
                  ? i18n.t('settings.reboot_in_progress')
                  : i18n.t('settings.micro_reboot_in_progress'),
            },
          });
        })
        .catch(e => {
          toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: i18n.t('failed'),
              icon: 'AlertTriangleIcon',
              variant: 'danger',
              text: i18n.t(e.response.data.message),
            },
          });
        });
    };

    const refreshDataAdditional = async () => {
      await refreshData();
      store
        .dispatch('devices/GET_RANGE', {
          deviceId: context.root.$route.params.id,
          userId: store.state.auth.userData.userId,
        })
        .then(response => {
          settings.value.range = response.data;
        });
    };

    const light = computed(() => {
      const l = (-100 / 1023) * settings.value.light + 100;
      return Math.round(l) + '%';
    });

    const voltage = computed(() => {
      return settings.value.voltage ? settings.value.voltage.toFixed(1) : '0';
    });

    return {
      diagnosticChart,
      light,
      reboot,
      lastUpdated,
      settings,
      updateData,
      refreshData,
      updateChart,
      refreshDataAdditional,
      voltage,
    };
  },
};
</script>

<style></style>
