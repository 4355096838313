import LoginForm from '@/views/login/LoginForm.vue';

import ChangeForm from '@/views/login/ChangeForm.vue';
import ResetForm from '@/views/login/ResetForm.vue';
import ActivationForm from '@/views/login/Activation.vue';
import ForgotPasswordForm from '@/views/login/ForgotPasswordForm.vue';

export default [
  {
    path: '/auth',
    name: 'auth',
    props: true,
    component: () => import('@/views/login/AuthIndex.vue'),
    children: [
      {
        name: 'login',
        path: 'login',
        component: LoginForm,
        meta: {
          layout: 'full',
          resource: 'Auth',
          redirectIfLoggedIn: true,
        },
      },
      {
        name: 'change-password',
        path: 'change',
        component: ChangeForm,
        meta: {
          layout: 'full',
          resource: 'Basic',
        },
      },
      {
        name: 'reset-password',
        path: 'reset/:token',
        component: ResetForm,
        meta: {
          layout: 'full',
          resource: 'Auth',
          redirectIfLoggedIn: true,
        },
      },
      {
        name: 'activation',
        path: 'activation',
        component: ActivationForm,
        meta: {
          layout: 'full',
          resource: 'Auth',
          redirectIfLoggedIn: true,
        },
      },
      {
        path: 'forgot',
        name: 'forgot-password',
        component: ForgotPasswordForm,
        meta: {
          layout: 'full',
          resource: 'Auth',
        },
      },
    ],
  },
  {
    path: '/logout',
    name: 'logout',
    component: () => import('@/views/login/Logout.vue'),
    meta: {
      layout: 'full',
      resource: 'Basic',
      redirectIfLoggedIn: false,
    },
  },
];
