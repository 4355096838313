<template>
  <div>
    <content-header
      @dateChanged="dateChanged"
      datepicker
      directionDropdown
      @directionChanged="directionChanged"
      v-if="hideHeader == false"
      @compareChanged="compareChanged"
      @compareDateChanged="compareDateChanged"
      :compare="compare"
    ></content-header>
    <bar-statistics
      :style="{ border: compare ? '1px solid #0391e5' : 'none' }"
      :data="stats(barData)"
    ></bar-statistics>
    <bar-statistics
      :style="{ border: compare ? '1px solid red' : 'none' }"
      v-if="compare"
      :data="stats(compareStats)"
    />
    <dynamic-chart
      :title="$t('number_of_vehicle_approximately')"
      subtitle="test"
      :lineChartData="lineChartData"
      :barChartData="barChartData"
      :graph="barData.graph"
      :dateRange="dateRange"
    ></dynamic-chart>
  </div>
</template>

<script>
import { BTable, BRow, BCol, BCard } from 'bootstrap-vue';
import ApexLineChart from '@/views/components/ApexLineChart.vue';
import BarStatistics from '@/views/components/BarStatistics';
import {
  formatDateFromAPI,
  getStartDateFromStore,
  getEndDateFromStore,
  matchSeriesDates,
} from '@/utils';
import ContentHeader from '@/views/components/ContentHeader.vue';
import EChartBar from '@/views/components/EChartBar.vue';
import DynamicChart from '../../components/DynamicChart.vue';

export default {
  components: {
    BTable,
    BCard,
    BRow,
    BCol,
    ApexLineChart,
    BarStatistics,
    ContentHeader,
    EChartBar,
    DynamicChart,
  },
  async mounted() {
    this.fetchData();
  },
  data() {
    return {
      direction: null,
      dateRange: [getStartDateFromStore(), getEndDateFromStore()],
      graphData: [],
      barData: {
        min: null,
        max: null,
        avg: null,
        PLDP: null,
      },
      compare: false,
      compareDateRange: [getStartDateFromStore(), getEndDateFromStore()],
      compareStats: {},
    };
  },
  props: {
    id: Number,
    dateFrom: String,
    dateTo: String,
    hideHeader: Boolean,
  },
  methods: {
    getDateByDataGrouping(date) {
      if (date) {
        const daysDiff = this.$moment(
          this.dateRange[1],
          'DD.MM.YYYY HH:mm',
        ).diff(this.$moment(this.dateRange[0], 'DD.MM.YYYY HH:mm'), 'days');

        const m = this.$moment(date, 'DD.MM.YYYY HH:mm');

        if (daysDiff < 7) {
          return m.format('DD.MM.YYYY HH:mm');
        } else if (daysDiff < 30) {
          return m.format('DD.MM.YYYY');
        } else if (daysDiff < 180) {
          return m.format('ww') + ' ' + this.$t('week');
        } else {
          return m.format('MM') + ' ' + this.$t('month');
        }
      }
      return '';
    },
    async fetchData() {
      if (this.dateFrom != null && this.dateTo != null) {
        this.dateRange = [this.dateFrom, this.dateTo];
      }

      const counterData = await this.$store.dispatch('stats/GET_COUNTER_DATA', {
        id: this.id != null ? this.id : this.$route.params.id,
        dateFrom: this.dateFrom != null ? this.dateFrom : this.dateRange[0],
        dateTo: this.dateTo != null ? this.dateTo : this.dateRange[1],
        app: 'speeding',
        direction: this.direction,
      });
      if (counterData) this.barData = counterData.data;
      if (this.compare) this.compareDateChanged();
    },
    dateChanged(dateRange) {
      if (dateRange) {
        const dates = dateRange.split(' to ');
        this.dateRange = dates;
        this.fetchData();
      }
    },
    directionChanged(direction) {
      this.direction = direction;
      this.fetchData();
    },
    async compareDateChanged(dateRange) {
      if (dateRange) {
        const dates = dateRange.split(' to ');
        this.compareDateRange = [dates[0], dates[1]];
      }
      const counterData = await this.$store.dispatch('stats/GET_COUNTER_DATA', {
        id: this.id != null ? this.id : this.$route.params.id,
        dateFrom: this.compareDateRange[0],
        dateTo: this.compareDateRange[1],
        app: 'speeding',
        direction: this.direction,
        //we nedd these two parameters so backend can properly group dates together
        orgDateFrom: this.dateRange[0],
        orgDateTo: this.dateRange[1],
      });
      if (counterData) this.compareStats = counterData.data;
    },
    async compareChanged(state) {
      this.compare = state;
    },
    stats(barData) {
      return [
        {
          icon: 'BarChartIcon',
          color: 'light-primary',
          title: barData?.counter?.count ?? '0',
          subtitle: this.$t('number_of_vehicle'),
          customClass: 'mb-2 mb-xl-0',
        },
        {
          icon: 'TrendingDownIcon',
          color: 'light-secondary',
          title: barData?.counter?.min?.value ?? 0,
          subtitle: this.$t('min_vehicle_num'),
          customClass: 'mb-2 mb-xl-0',
          datetime: this.getDateByDataGrouping(
            formatDateFromAPI(barData.counter?.min?.date),
          ),
        },
        {
          icon: 'ActivityIcon',
          color: 'light-info',
          title: barData?.counter?.max?.value ?? 0,
          subtitle: this.$t('max_vehicle_num'),
          customClass: 'mb-2 mb-xl-0',
          datetime: this.getDateByDataGrouping(
            formatDateFromAPI(barData.counter?.max?.date),
          ),
        },
        {
          icon: 'BarChart2Icon',
          color: 'light-danger',
          title: Math.round(barData?.counter?.avg?.value)
            ? Math.round(barData?.counter?.avg?.value)
            : 0,
          subtitle: this.$t('average_vehicle_num'),
          customClass: 'mb-2 mb-sm-0',
        },
        {
          icon: 'MinusIcon',
          color: 'light-success',
          title: Math.round(barData?.PLDP) ?? '-',
          subtitle: 'PLDP',
          customClass: '',
        },
      ];
    },
  },
  computed: {
    barChartData() {
      let avg = Math.round(
        this.barData.graph?.reduce((a, b) => a + b.vehicleSum, 0) /
          this.barData?.graph?.length || 0,
      );
      let data = [
        {
          name: this.$t('Vehicle') + (this.compare ? ' 1' : ''),
          data: this.barData?.graph?.map((item) => item.vehicleSum),
          /* markLine: {
            data: [{ type: 'average', name: 'Avg' }],
            lineStyle: { color: 'rgb(0, 227, 150)' },
          },*/
        },
        {
          name: this.$t('Average') + (this.compare ? ' 1' : ''),
          data: this.barData?.graph?.map((item) => avg),
          type: 'line',
        },
      ];
      if (this.compare && this.compareStats) {
        let avg2 = Math.round(
          this.compareStats.graph?.reduce((a, b) => a + b.vehicleSum, 0) /
            this.compareStats?.graph?.length || 0,
        );
        data = [
          ...data,
          {
            name: this.$t('Vehicle') + ' 2',
            data: this.compareStats.graph?.map((item) => item.vehicleSum),
          },
          {
            name: this.$t('Average') + ' 2',
            data: this.compareStats.graph?.map((item) => avg2),
            type: 'line',
            lineColor: 'red',
          },
        ];
      }
      return data;
    },
    lineChartData() {
      let avg = null;
      if (this.barData?.graph) {
        avg =
          this.barData.graph.reduce((a, b) => a + b.vehicleSum, 0) /
            this.barData?.graph?.length || 0;
      }
      let series = [
        {
          name: this.$t('number_of_vehicle') + (this.compare ? ' 1' : ''),
          data:
            this.barData?.graph?.map((d) => {
              return {
                x: d.dateTime,
                y: d.vehicleSum,
              };
            }) ?? [],
        },
        {
          name: this.$t('average_of_vehicle') + (this.compare ? ' 1' : ''),
          data:
            this.barData?.graph?.map((d) => {
              return {
                x: d.dateTime,
                y: avg,
              };
            }) ?? [],
        },
      ];
      if (this.compare) {
        let compareGraph =
          this.compareStats?.graph?.map((d) => {
            return {
              x: d.dateTime,
              y: d.vehicleSum,
            };
          }) ?? [];

        let avgCompare = null;
        if (this.compareStats?.graph)
          avgCompare =
            this.compareStats.graph.reduce((a, b) => a + b.vehicleSum, 0) /
              this.compareStats?.graph?.length || 0;

        if (compareGraph && compareGraph.length > 0) {
          compareGraph = matchSeriesDates(series[0].data, compareGraph);
        }
        series = [
          ...series,
          {
            name: this.$t('number_of_vehicle') + ' 2',
            data: compareGraph,
          },
          {
            name: this.$t('average_of_vehicle') + ' 2',
            data: compareGraph.map((d) => {
              return {
                x: d.x,
                y: avgCompare,
              };
            }),
          },
        ];
      }
      return {
        series,
      };
    },
  },
  watch: {
    '$route.params.id': function (id) {
      this.fetchData();
    },
  },
};
</script>

<style>
.hidden_header {
  display: none;
}
</style>
