import axiosIns from '@/libs/axios';
import { formatOrStartOfMonthDate, formatOrEndOfMonthDate } from '@/utils';
import { $GET, $POST } from '../methods';
import moment from 'moment';

const APP_DATE_TIME_FORMAT = 'DD.MM.YYYY HH:mm';
const API_DATE_TIME_FORMAT = 'y-MM-DD HH:mm';

const getDateRangeOrFromState = (rootState, dateFrom, dateTo) => {
  const dStart = rootState.app.currentDate.startDate
    ? moment(rootState.app.currentDate.startDate).format(APP_DATE_TIME_FORMAT)
    : null;
  const dEnd = rootState.app.currentDate.endDate
    ? moment(rootState.app.currentDate.endDate).format(APP_DATE_TIME_FORMAT)
    : null;

  return {
    dateFrom: formatOrStartOfMonthDate(dateFrom ?? dStart),
    dateTo: formatOrEndOfMonthDate(dateTo ?? dEnd),
  };
};

export default {
  namespaced: true,
  getters: {},
  mutations: {},
  actions: {
    GET_PERCENTILE_DATA({ rootState }, payload) {
      const { dateFrom, dateTo } = getDateRangeOrFromState(
        rootState,
        payload.dateFrom,
        payload.dateTo,
      );
      return $GET(
        `stats/${payload.id}/${payload.app}/percentile?dateFrom=${dateFrom}&dateTo=${dateTo}` +
          (payload.direction ? '&direction=' + payload.direction : '') +
          (payload.vehicleType ? '&vehicleType=' + payload.vehicleType : ''),
      );
    },
    GET_CUSTOM_PERCENTILE({ rootState }, payload) {
      const { dateFrom, dateTo } = getDateRangeOrFromState(
        rootState,
        payload.dateFrom,
        payload.dateTo,
      );
      return new Promise((resolve, reject) => {
        axiosIns
          .get(
            'stats/' +
              payload.id +
              '/speeding/custompercentile?dateFrom=' +
              dateFrom +
              '&dateTo=' +
              dateTo +
              '&percentile=' +
              payload.percentile,
          )
          .then(response => resolve(response))
          .catch(error => reject(error));
      });
    },
    GET_BAR_DATA({ rootState }, payload) {
      const { dateFrom, dateTo } = getDateRangeOrFromState(
        rootState,
        payload.dateFrom,
        payload.dateTo,
      );

      return $GET(
        'stats/' +
          payload.id +
          '/' +
          payload.app +
          '/' +
          payload.barName +
          '?dateFrom=' +
          dateFrom +
          '&dateTo=' +
          dateTo +
          (payload.direction ? '&direction=' + payload.direction : '') +
          (payload.vehicleType ? '&vehicleType=' + payload.vehicleType : ''),
      );
    },
    GET_DATA_IN_RANGE({ rootState }, payload) {
      const { dateFrom, dateTo } = getDateRangeOrFromState(
        rootState,
        payload.dateFrom,
        payload.dateTo,
      );
      return new Promise((resolve, reject) => {
        axiosIns
          .get(
            'stats/' +
              payload.id +
              '/' +
              payload.link +
              '?dateFrom=' +
              dateFrom +
              '&dateTo=' +
              dateTo +
              (payload.direction ? '&direction=' + payload.direction : ''),
          )
          .then(response => resolve(response))
          .catch(error => reject(error));
      });
    },
    GET_CLASSES_DATA({ rootState }, data) {
      const { dateFrom, dateTo } = getDateRangeOrFromState(
        rootState,
        data.dateFrom,
        data.dateTo,
      );

      let params =
        '?dateFrom=' +
        dateFrom +
        '&dateTo=' +
        dateTo +
        (data.direction ? '&direction=' + data.direction : '') +
        (data.vehicleType ? '&vehicleType=' + data.vehicleType : '');
      if (data.minLimit != null && data.maxLimit != null) {
        params += '&minLimit=' + data.minLimit + '&maxLimit=' + data.maxLimit;
      }

      return $GET('stats/' + data.id + '/' + data.app + '/classes' + params);
    },
    GET_VOLTAGE({ rootState }, payload) {
      const { dateFrom, dateTo } = getDateRangeOrFromState(
        rootState,
        payload.dateFrom,
        payload.dateTo,
      );
      return $GET(
        `stats/${payload.id}/cyclist/voltage?dateFrom=${dateFrom}&dateTo=${dateTo}`,
      );
    },
    GET_DIAGNOSTIC({ rootState }, payload) {
      const { dateFrom, dateTo } = getDateRangeOrFromState(
        rootState,
        payload.dateFrom,
        payload.dateTo,
      );
      return $GET(
        `stats/${payload.id}/cyclist/diagnostic?dateFrom=${dateFrom}&dateTo=${dateTo}`,
      );
    },
    GET_SPEEDING_DIAGNOSTIC({ rootState }, payload) {
      const { dateFrom, dateTo } = getDateRangeOrFromState(
        rootState,
        payload.dateFrom,
        payload.dateTo,
      );
      return $GET(
        `stats/${payload.id}/speeding/diagnostic/chart?dateFrom=${dateFrom}&dateTo=${dateTo}`,
      );
    },
    GET_CYCLIST_DIAGNOSTIC_CHART({ rootState }, payload) {
      const { dateFrom, dateTo } = getDateRangeOrFromState(
        rootState,
        payload.dateFrom,
        payload.dateTo,
      );
      return $GET(
        `stats/${payload.id}/cyclist/diagnostic/chart?dateFrom=${dateFrom}&dateTo=${dateTo}`,
      );
    },
    GET_VEHICLE_DIAGNOSTIC_CHART({ rootState }, payload) {
      const { dateFrom, dateTo } = getDateRangeOrFromState(
        rootState,
        payload.dateFrom,
        payload.dateTo,
      );
      return $GET(
        `stats/${payload.id}/vehicle/diagnostic/chart?dateFrom=${dateFrom}&dateTo=${dateTo}`,
      );
    },
    GET_TEMPERATURE({ rootState }, payload) {
      const { dateFrom, dateTo } = getDateRangeOrFromState(
        rootState,
        payload.dateFrom,
        payload.dateTo,
      );
      return $GET(
        `stats/${payload.id}/cyclist/temperature?dateFrom=${dateFrom}&dateTo=${dateTo}`,
      );
    },
    GET_COUNTER_DATA({ rootState }, payload) {
      const { dateFrom, dateTo } = getDateRangeOrFromState(
        rootState,
        payload.dateFrom,
        payload.dateTo,
      );
      return $GET(
        `stats/${payload.id}/${payload.app}/counter?dateFrom=${dateFrom}&dateTo=${dateTo}` +
          (payload.direction ? '&direction=' + payload.direction : '') +
          (payload.orgDateFrom
            ? '&orgDateFrom=' +
              moment(payload.orgDateFrom, APP_DATE_TIME_FORMAT)
                .format(API_DATE_TIME_FORMAT)
                .toString()
            : '') +
          (payload.orgDateTo
            ? '&orgDateTo=' +
              moment(payload.orgDateTo, APP_DATE_TIME_FORMAT)
                .format(API_DATE_TIME_FORMAT)
                .toString()
            : ''),
      );
    },
    GET_CUSTOM_CLASSES_DATA({ rootState }, payload) {
      const { dateFrom, dateTo } = getDateRangeOrFromState(
        rootState,
        payload.dateFrom,
        payload.dateTo,
      );
      return $GET(
        `stats/${payload.id}/${payload.app}/classes/custom?dateFrom=${dateFrom}&dateTo=${dateTo}&min=${payload.min}&max=${payload.max}`,
      );
    },
    GENERATE_REPORT(state, payload) {
      const dateFrom = formatOrStartOfMonthDate(payload.dateFrom);
      const dateTo = formatOrEndOfMonthDate(payload.dateTo);
      return $GET(
        'report/' +
          payload.deviceId +
          '/generate' +
          '?dateFrom=' +
          dateFrom +
          '&dateTo=' +
          dateTo +
          '&docType=' +
          payload.type +
          '&docFormat=' +
          payload.docType +
          '&includeRawData=' +
          payload.includeRawData,
      );
    },
    GENERATE_SHORT_REPORT(state, payload) {
      const dateFrom = formatOrStartOfMonthDate(payload.dateFrom);
      const dateTo = formatOrEndOfMonthDate(payload.dateTo);
      return $GET(
        'report/' +
          payload.deviceId +
          '/generate/short' +
          '?dateFrom=' +
          dateFrom +
          '&dateTo=' +
          dateTo +
          '&type=' +
          payload.type,
      );
    },
    GET_AUTO_EXPORT(state, { deviceId }) {
      return $GET(`report/${deviceId}/autoexport`);
    },
    SAVE_AUTO_EXPORT(state, payload) {
      return $POST(`report/${payload.deviceId}/autoexport`, payload);
    },
    GET_RADAR_ACTIVE_TIME(state, { dateFrom, dateTo, deviceId }) {
      return $GET(
        `stats/${deviceId}/radar/active?dateFrom=${dateFrom}&dateTo=${dateTo}`,
      );
    },
  },
};
