export const CommandType = {
  GET_DEVICE_SOFTWARE: 'get_device_software_S',
  GET_DEVICE_FIRMWARE: 'get_device_firmware_S',
  GET_DEVICE_SERIAL: 'get_device_serial_S',
  GET_DEVICE_NAME: 'get_device_name_S',
  GET_DEVICE_MODEL: 'get_device_model_S',
  GET_DEVICE_DATE: 'get_device_date_S',
  GET_DEVICE_TIME: 'get_device_time_S',
  GET_DEVICE_SPEED_LIMIT: 'get_device_speed_limit_S',
  GET_DEVICE_MIN_SPEED_LIMIT: 'get_device_min_speed_limit_S',
  GET_DEVICE_MAX_SPEED_LIMIT: 'get_device_max_speed_limit_S',
  GET_DEVICE_BLINKING_SPEED_LIMIT: 'get_device_blinking_speed_limit_S',
  GET_DEVICE_COLOR_SPEED_LIMIT: 'get_device_color_speed_limit_S',
  GET_DEVICE_LIGHT: 'get_device_light_S',
  GET_DEVICE_VOLTAGE: 'get_device_voltage_S',
  GET_DEVICE_ID: 'get_device_id_S',
  GET_DEVICE_GPS: 'get_device_gps_S',
  GET_DEVICE_STAT: 'get_device_stat_S',
  GET_DEVICE_SYMBOLS: 'get_device_symbols_S',
  SET_DEVICE_DATE: 'set_device_date_S',
  SET_DEVICE_TIME: 'set_device_time_S',
  SET_DEVICE_SPEED_LIMIT: 'set_device_speed_limit_S',
  SET_DEVICE_MIN_SPEED_LIMIT: 'set_device_min_speed_limit_S',
  SET_DEVICE_MAX_SPEED_LIMIT: 'set_device_max_speed_limit_S',
  SET_DEVICE_BLINKING_SPEED_LIMIT: 'set_device_blinking_speed_limit_S',
  SET_DEVICE_COLOR_SPEED_LIMIT: 'set_device_color_speed_limit_S',
  SET_DEVICE_LIGHT: 'set_device_light_S',
  SET_DEVICE_VOLTAGE: 'set_device_voltage_S',
  SET_DEVICE_ID: 'set_device_id_S',
  SET_DEVICE_GPS: 'set_device_gps_S',
  REBOOT_SOFTWARE: 'reboot_software_S',
  REBOOT_DEVICE: 'reboot_device_S',
  GET_CYCLIST_COUNTER: 'get_cyclist_counter_C',
  GET_CYCLIST_DIR: 'get_cyclist_direction_C',
  SET_CYCLIST_COUNTER: 'set_cyclist_counter_C',
  SET_CYCLIST_DIR: 'set_cyclist_direction_C',
  GET_ON_OFF_STATE: 'get_on_off_state_S',
  SET_ON_OFF_STATE: 'set_on_off_state_S',
  GET_CYCLIST_BRIGHTNESS: 'get_cyclist_brightness_C',
  GET_DEVICE_TEMPERATURE: 'get_device_temperature_C',
  GET_RANGE: 'get_range_S',
  SET_RANGE: 'set_range_S',

  //Vehicle
  GET_VEHICLE_ID: 'get_vehicle_id_V',
  GET_VEHICLE_SERIAL_NUMBER: 'get_vehicle_serial_number_V',
  GET_VEHICLE_NAME: 'get_vehicle_name_V',
  GET_VEHICLE_FIRMWARE_VERSION: 'get_vehicle_firmware_version_V',
  GET_VEHICLE_GPS: 'get_vehicle_gps_V',
  GET_VEHICLE_MODULE_GPS: 'get_vehicle_module_gps_V',
  GET_VEHICLE_ICCID_SIM: 'get_vehicle_iccid_sim_V',
  GET_VEHICLE_DATE: 'get_vehicle_date_V',
  SET_VEHICLE_DATE: 'set_vehicle_date_V',
  GET_VEHICLE_TIME: 'get_vehicle_time_V',
  SET_VEHICLE_TIME: 'set_vehicle_time_V',
  GET_VEHICLE_SPEED: 'get_vehicle_speed_V',
  SET_VEHICLE_SPEED: 'set_vehicle_speed_V',
  GET_VEHICLE_VOLTAGE: 'get_vehicle_voltage_V',
  GET_VEHICLE_COUNT: 'get_vehicle_count_V',
  GET_VEHICLE_DISTANCE: 'get_vehicle_distance_V',
  GET_VEHICLE_MEASUREMENT_DIRECTION: 'get_vehicle_measurement_orientation_V',
  SET_VEHICLE_MEASUREMENT_DIRECTION: 'set_vehicle_measurement_orientation_V',
  SET_VEHICLE_CORRECTION_FACTOR: 'set_vehicle_correction_factor_V',
  GET_VEHICLE_CORRECTION_FACTOR: 'get_vehicle_correction_factor_V',
  SET_VEHICLE_DISTANCE: 'set_vehicle_distance_V',
  GET_VEHICLE_DATA: 'get_vehicle_data_V',
};
