<template>
  <div>
    <apex-line-chart
      v-if="!showBarChart && !onlybarchart"
      :option="lineChartData"
      :title="title"
      :dashArray="[0, 5, 0, 5]"
      :subtitle="getSubtitle()"
      nodatepicker
    ></apex-line-chart>
    <e-chart-bar
      :subtitle="getSubtitle()"
      v-if="showBarChart || onlybarchart"
      :title="title"
      :data="barChartData"
      :lables="barChartLabels"
      nodatepicker
      :markLine="false"
    ></e-chart-bar>
  </div>
</template>

<script>
import { BTable, BRow, BCol, BCard } from 'bootstrap-vue';
import ApexLineChart from '@/views/components/ApexLineChart.vue';
import BarStatistics from '@/views/components/BarStatistics';
import ContentHeader from '@/views/components/ContentHeader.vue';
import EChartBar from '@/views/components/EChartBar.vue';

import { monthNames, toCapitalLetter } from '@/utils';

export default {
  components: {
    BTable,
    BCard,
    BRow,
    BCol,
    ApexLineChart,
    BarStatistics,
    ContentHeader,
    EChartBar,
  },
  props: [
    'graph',
    'title',
    'subtitle',
    'lineChartData',
    'barChartData',
    'dateRange',
    'onlybarchart',
  ],
  methods: {
    getSubtitle() {
      const startDate = this.$moment(this.dateRange[0], 'DD.MM.YYYY HH:mm');
      const endDate = this.$moment(this.dateRange[1], 'DD.MM.YYYY HH:mm');
      const dateDiff = endDate.diff(startDate, 'days');

      if (dateDiff < 7) return this.$t('hourly_display');
      else if (dateDiff <= 30) return this.$t('daily_display');
      else if (dateDiff < 180) return this.$t('weekly_display');
      else dateDiff >= 180;
      return this.$t('monthly_display');
    },
  },
  computed: {
    min() {
      return new Date(
        this.$moment(this.dateRange[0], 'DD.MM.YYYY').format('YYYY-MM-DD'),
      ).getTime();
    },
    max() {
      return new Date(
        this.$moment(this.dateRange[1], 'DD.MM.YYYY').format('YYYY-MM-DD'),
      ).getTime();
    },
    showBarChart() {
      return this.dateRange
        ? this.$moment(this.dateRange[1], 'DD.MM.YYYY HH:mm').diff(
            this.$moment(this.dateRange[0], 'DD.MM.YYYY HH:mm'),
            'days',
          ) >= 7
        : false;
    },
    barChartLabels() {
      const timeDiff = this.$moment(this.dateRange[1], 'DD.MM.YYYY HH:mm').diff(
        this.$moment(this.dateRange[0], 'DD.MM.YYYY HH:mm'),
        'days',
      );

      return [
        ...new Set(
          this.graph?.map(item => {
            const d = this.$moment(item.dateTime);
            if (timeDiff < 30) {
              return (
                d.format('DD') +
                ' ' +
                toCapitalLetter(d.format('MMM').replace('.', ' '))
              );
            } else if (timeDiff < 180) {
              return item.dateTime;
            } else if (timeDiff >= 180) {
              const split = item.dateTime.split('-');
              return monthNames[parseInt(split[0]) - 1] + ' ' + split[1];
            }
          }),
        ),
      ];
    },
  },
};
</script>

<style>
.hidden_header {
  display: none;
}
</style>
