<template>
  <div>
    <content-header
      datepicker
      @dateChanged="dateChanged"
      directionDropdown
      @directionChanged="directionChanged"
      v-if="hideHeader == false"
      @compareChanged="compareChanged"
      @compareDateChanged="compareDateChanged"
      :compare="compare"
    ></content-header>
    <bar-statistics
      :style="{ border: compare ? '1px solid #0391e5' : 'none' }"
      :data="items(stats)"
    />
    <bar-statistics
      :style="{ border: compare ? '1px solid red' : 'none' }"
      v-if="compare"
      title=""
      :data="items(compareStats)"
    />
    <apex-line-chart
      :compare="compare"
      :option="graphDataFiltered"
      :title="$t('speeding.average_and_max_speed')"
      nodatepicker
      yUnit="km/h"
    ></apex-line-chart>
  </div>
</template>

<script>
import BarStatistics from '@/views/components/BarStatistics';
import ContentHeader from '@/views/components/ContentHeader.vue';
import ApexLineChart from '@/views/components/ApexLineChart.vue';
import {
  formatDateFromAPI,
  getStartDateFromStore,
  getEndDateFromStore,
  matchSeriesDates,
} from '@/utils';
import DynamicChart from '@/views/components/DynamicChart.vue';

export default {
  props: {
    id: { type: String, required: false },
  },
  components: {
    BarStatistics,
    ContentHeader,
    ApexLineChart,
    DynamicChart,
  },
  data() {
    return {
      direction: null,
      dateRange: [getStartDateFromStore(), getEndDateFromStore()],
      stats: {
        avg: 0,
        speedLimit: 0,
        max: 0,
        underLimit: 0,
        aboveLimit: 0,
        graph: [],
      },
      compare: false,
      compareDateRange: [getStartDateFromStore(), getEndDateFromStore()],
      compareStats: { graph: [] },
    };
  },
  async mounted() {
    this.loadData();
  },

  props: {
    id: Number,
    dateFrom: String,
    dateTo: String,
    hideHeader: Boolean,
  },
  computed: {
    graphDataFiltered() {
      let series = this.getSeries(this.stats.graph, this.compare ? ' 1' : '');
      if (this.compare) {
        const compareGraph = this.getSeries(this.compareStats.graph, ' 2');
        //match dates with original series
        for (let index = 0; index < 3; index++) {
          compareGraph[index].data = matchSeriesDates(
            series[index].data,
            compareGraph[index].data,
          );
        }

        series = [...series, ...compareGraph];
      }
      return {
        series,
      };
    },
  },
  methods: {
    items(stats) {
      let underPercent = Math.round(
        (100 / (stats?.underLimit + stats?.aboveLimit)) * stats?.underLimit,
      );
      underPercent = underPercent ? underPercent : 0;

      let abovePercent = Math.round(
        (100 / (stats?.underLimit + stats?.aboveLimit)) * stats?.aboveLimit,
      );

      abovePercent = abovePercent ? abovePercent : 0;

      return [
        {
          icon: 'AlertCircleIcon',
          color: 'light-primary',
          title: (stats?.speedLimit ?? '0') + ' km/h',
          subtitle: this.$t('speeding.speed_limit'),
          customClass: 'mb-2 mb-xl-0',
        },
        {
          icon: 'ActivityIcon',
          color: 'light-info',
          title:
            (stats?.max?.max < Math.round(stats.avg)
              ? stats?.max?.max
              : Math.round(stats.avg) ?? '0') + ' km/h',
          subtitle: this.$t('speeding.average_speed'),
          customClass: 'mb-2 mb-xl-0',
        },
        {
          icon: 'BarChart2Icon',
          color: 'light-danger',
          title: (stats?.max?.max ?? '0') + ' km/h',
          subtitle: this.$t('speeding.max_speed'),
          customClass: 'mb-2 mb-sm-0',
          datetime: formatDateFromAPI(stats?.max?.date),
        },
        {
          icon: 'TrendingDownIcon',
          color: 'light-success',
          title: (stats?.underLimit ?? '0') + ' (' + underPercent + '%)' ?? '0',
          subtitle: this.$t('speeding.vehicle_number_under_limit'),
          customClass: 'mb-2 mb-xl-0',
        },
        {
          icon: 'TrendingUpIcon',
          color: 'light-danger',
          title: (stats?.aboveLimit ?? '0') + ' (' + abovePercent + '%)' ?? '0',
          subtitle: this.$t('speeding.vehicle_number_over_limit'),
          customClass: 'mb-2 mb-sm-0',
        },
      ];
    },
    getData(graph, dataName) {
      return (
        graph?.map(d => {
          if (dataName == 'avg' && d['max'] < d['avg']) d[dataName] = d['max'];
          return {
            x: d.dateTime,
            y: d[dataName] == 0 ? null : d[dataName],
          };
        }) ?? []
      );
    },
    getSeries(graph, additionalTitle = '') {
      return [
        {
          name: this.$t('speeding.average_speed') + additionalTitle,
          data: this.getData(graph, 'avg'),
        },
        {
          name: this.$t('speeding.max_speed') + additionalTitle,
          data: this.getData(graph, 'max'),
        },
        {
          name: this.$t('speeding.speed_limit') + additionalTitle,
          data: this.getData(graph, 'speedLimit'),
        },
      ];
    },
    async compareChanged(state) {
      this.compare = state;
    },
    directionChanged(direction) {
      this.direction = direction;
      this.loadData();
      if (this.compare) this.compareDateChanged();
    },
    async dateChanged(dateRange) {
      const dates = dateRange.split('to');
      this.dateRange = [dates[0], dates[1]];
      this.loadData();
    },
    async compareDateChanged(dateRange) {
      if (dateRange) {
        const dates = dateRange.split('to');
        this.compareDateRange = [dates[0], dates[1]];
      }
      const response = await this.$store.dispatch('stats/GET_BAR_DATA', {
        id: this.id != null ? this.id : this.$route.params.id,
        barName: 'average',
        dateFrom: this.compareDateRange[0],
        dateTo: this.compareDateRange[1],
        app: 'speeding',
        direction: this.direction,
      });
      this.compareStats = response.data;
    },
    async loadData() {
      if (this.dateFrom != null && this.dateTo != null) {
        this.dateRange = [this.dateFrom, this.dateTo];
      }
      //Bar statistics
      const response = await this.$store.dispatch('stats/GET_BAR_DATA', {
        id: this.id != null ? this.id : this.$route.params.id,
        barName: 'average',
        dateFrom: this.dateFrom != null ? this.dateFrom : this.dateRange[0],
        dateTo: this.dateTo != null ? this.dateTo : this.dateRange[1],
        app: 'speeding',
        direction: this.direction,
      });
      this.stats = response.data;
    },
  },
  watch: {
    '$route.params.id': function(id) {
      this.loadData();
    },
  },
};
</script>

<style></style>
