<template>
  <div class="apexcharts-toolbar" style="top: 0px; right: 3px;">
    <div @click="toggleToolbar" class="apexcharts-menu-icon" title="Menu">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
        viewBox="0 0 24 24"
      >
        <path fill="none" d="M0 0h24v24H0V0z"></path>
        <path d="M3 18h18v-2H3v2zm0-5h18v-2H3v2zm0-7v2h18V6H3z"></path>
      </svg>
    </div>
    <div
      v-if="opened"
      class="apexcharts-menu apexcharts-menu-open"
      style="width: 120px;"
    >
      <div
        @click="exportPNG"
        class="apexcharts-menu-item exportPNG"
        title="Download PNG"
      >
        {{ $t('download') }} PNG
      </div>
      <div
        @click="exportPDF"
        class="apexcharts-menu-item exportPDF"
        title="Download PDF"
      >
        {{ $t('download') }} PDF
      </div>
      <div
        @click="exportCSV"
        class="apexcharts-menu-item exportCSV"
        title="Export CSV"
      >
        {{ $t('download') }} CSV
      </div>
    </div>
  </div>
</template>

<script>
import jsPDF from 'jspdf';

import { getStartDateFromStore, getEndDateFromStore } from '@/utils';

export default {
  props: ['chart', 'series'],
  data() {
    return {
      opened: false,
    };
  },
  methods: {
    toggleToolbar() {
      this.opened = !this.opened;
    },
    exportCSV() {
      let outputArray = [];
      for (let i = 0; i < this.series[0].data.length; i++) {
        let item = [this.series[0].data[i].x];
        this.series.forEach(s => {
          item.push(Math.round(s.data[i].y));
        });
        outputArray.push(item);
      }
      let csvContent =
        'data:text/csv;charset=utf-8,' +
        outputArray.map(e => e.join(',')).join('\n');
      var encodedUri = encodeURI(csvContent);
      var link = document.createElement('a');
      link.setAttribute('href', encodedUri);
      link.setAttribute('download', 'data.csv');
      document.body.appendChild(link); // Required for FF

      link.click(); // This will download the data file named "my_data.csv".
    },
    exportPDF() {
      this.$emit(
        'change-title',
        this.$store.state.devices.currentDevice
          ? this.$store.state.devices.currentDevice.name
          : '',
        getStartDateFromStore() + ' - ' + getEndDateFromStore(),
      );
      this.$nextTick(function() {
        const chart = this.chart;
        const dataURL = chart.dataURI().then(({ imgURI, blob }) => {
          const pdf = new jsPDF('landscape');
          pdf.addImage(imgURI, 'PNG', 0, 0, 290, 80);
          pdf.save('chart.pdf');
          this.$emit('change-title', '');
        });
      });
    },
    exportPNG() {
      this.$emit(
        'change-title',
        this.$store.state.devices.currentDevice
          ? this.$store.state.devices.currentDevice.name
          : '',
        getStartDateFromStore() + ' - ' + getEndDateFromStore(),
      );
      const downloadURI = (uri, name) => {
        var link = document.createElement('a');
        link.download = name;
        link.href = uri;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      };

      this.$nextTick(function() {
        const dataURL = this.chart.dataURI().then(({ imgURI, blob }) => {
          downloadURI(imgURI, 'chart');
          this.$emit('change-title', '');
        });
      });
    },
  },
};
</script>

<style></style>
