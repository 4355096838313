<template>
  <div>
    <content-header
      @dateChanged="dateChanged"
      datepicker
      nomargin
    ></content-header>
    <b-row>
      <b-col md="6"
        ><bar-statistics :title="$t('cars')" :data="carStats"
      /></b-col>
      <b-col md="6"
        ><bar-statistics :title="$t('trucks')" :data="truckStats"
      /></b-col>
    </b-row>
    <dynamic-chart
      :title="$t('number_of_vehicle')"
      :lineChartData="graphDataFiltered"
      :barChartData="barChartData"
      :graph="counterData.graph"
      :dateRange="dateRange"
    ></dynamic-chart>
    <dynamic-chart
      :title="
        $t('number_of_vehicle') +
          ' od ' +
          (counterData.toleranceSpeedLimit
            ? counterData.toleranceSpeedLimit
            : '- ') +
          ' km/h'
      "
      :lineChartData="graphToleranceFiltered"
      :barChartData="barChartDataTolerance"
      :graph="counterData.graphAboveTolerance"
      :dateRange="dateRange"
      :onlybarchart="true"
    ></dynamic-chart>
  </div>
</template>

<script>
import { BTable, BRow, BCol, BCard } from 'bootstrap-vue';
import ApexLineChart from '@/views/components/ApexLineChart.vue';
import BarStatistics from '@/views/components/BarStatistics';
import ContentHeader from '@/views/components/ContentHeader.vue';
import {
  formatDateFromAPI,
  getStartDateFromStore,
  getEndDateFromStore,
} from '@/utils';

import DynamicChart from '@/views/components/DynamicChart.vue';

export default {
  components: {
    BTable,
    BCard,
    BRow,
    BCol,
    ApexLineChart,
    BarStatistics,
    ContentHeader,
    DynamicChart,
  },
  async mounted() {
    this.fetchData();
  },
  data() {
    return {
      dateRange: [getStartDateFromStore(), getEndDateFromStore()],
      counterData: {
        car: {
          min: null,
          max: null,
          avg: null,
        },
        truck: {
          min: null,
          max: null,
          avg: null,
        },
        graph: [],
      },
    };
  },
  methods: {
    async fetchData(dateFrom = null, dateTo = null) {
      const counterData = await this.$store.dispatch('stats/GET_COUNTER_DATA', {
        id: this.$route.params.id,
        dateFrom,
        dateTo,
        app: 'radar',
      });
      if (counterData) this.counterData = counterData.data;
    },
    dateChanged(dateRange) {
      if (dateRange) {
        const dates = dateRange.split('to');
        this.dateRange = [dates[0], dates[1]];
        this.fetchData(dates[0], dates[1]);
      }
    },
    getAboveData(vehicleType) {
      return this.counterData.aboveLimit?.find(
        el => el.vehicleType == vehicleType,
      );
    },
  },
  computed: {
    barChartData() {
      return [
        {
          name: this.$t('cars'),
          data: this.counterData?.graph
            ?.filter(v => v.vehicleType == 'Car')
            .map(item => item.countByHour),
        },
        {
          name: this.$t('cars_above_limit'),
          data: this.counterData?.graphAboveToleranceBar
            ?.filter(v => v.vehicleType == 'Car')
            .map(item => item.countByHour),
        },
        {
          name: this.$t('trucks'),
          data: this.counterData?.graph
            ?.filter(v => v.vehicleType == 'Trk')
            .map(item => item.countByHour),
        },
        {
          name: this.$t('trucks_above_limit'),
          data: this.counterData?.graphAboveToleranceBar
            ?.filter(v => v.vehicleType == 'Trk')
            .map(item => item.countByHour),
        },
      ];
    },
    barChartDataTolerance() {
      return [
        {
          name: this.$t('cars'),
          data: this.counterData?.graphAboveToleranceBar
            ?.filter(v => v.vehicleType == 'Car')
            .map(item => item.countByHour),
        },
        {
          name: this.$t('trucks'),
          data: this.counterData?.graphAboveToleranceBar
            ?.filter(v => v.vehicleType == 'Trk')
            .map(item => item.countByHour),
        },
      ];
    },
    carStats() {
      return [
        {
          icon: 'AlertCircleIcon',
          color: 'light-primary',
          title:
            this.counterData.count?.find(el => el.vehicleType == 'Car')
              ?.count ?? 0,
          subtitle: this.$t('number_of_cars'),
          customClass: 'mb-2 mb-xl-0',
        },
        {
          icon: 'ActivityIcon',
          color: 'light-info',
          title:
            this.counterData.aboveLimit?.find(el => el.vehicleType == 'Car')
              ?.count ?? 0,
          subtitle:
            this.$t('number_of_cars') +
            ' nad ' +
            (this.counterData?.speedLimit ?? '- ') +
            'km/h',
          customClass: 'mb-2 mb-xl-0',
          datetime: formatDateFromAPI(this.counterData.cars?.max?.date),
        },
        {
          icon: 'BarChart2Icon',
          color: 'light-danger',
          title:
            this.counterData.aboveTolerance?.find(el => el.vehicleType == 'Car')
              ?.count ?? 0,
          subtitle:
            this.$t('number_of_cars') +
            ' od ' +
            (this.counterData?.toleranceSpeedLimit ?? '- ') +
            'km/h',
          customClass: 'mb-2 mb-sm-0',
        },
      ];
    },
    truckStats() {
      return [
        {
          icon: 'AlertCircleIcon',
          color: 'light-primary',
          title:
            this.counterData.count?.find(el => el.vehicleType == 'Trk')
              ?.count ?? 0,
          subtitle: this.$t('number_of_trucks'),
          customClass: 'mb-2 mb-xl-0',
          datetime: formatDateFromAPI(this.counterData.trucks?.min?.date),
        },
        {
          icon: 'ActivityIcon',
          color: 'light-info',
          title:
            this.counterData.aboveLimit?.find(el => el.vehicleType == 'Trk')
              ?.count ?? 0,
          subtitle:
            this.$t('number_of_trucks') +
            ' nad ' +
            (this.counterData.speedLimit ?? '- ') +
            'km/h',
          customClass: 'mb-2 mb-xl-0',
        },
        {
          icon: 'BarChart2Icon',
          color: 'light-danger',
          title:
            this.counterData.aboveTolerance?.find(el => el.vehicleType == 'Trk')
              ?.count ?? 0,
          subtitle:
            this.$t('number_of_trucks') +
            ' od ' +
            (this.counterData.toleranceSpeedLimit ?? '- ') +
            'km/h',
          customClass: 'mb-2 mb-sm-0',
        },
      ];
    },
    graphDataFiltered() {
      return {
        series: [
          {
            name: this.$t('cars'),
            data: this.counterData?.graph?.reduce((result, d) => {
              if (d.vehicleType == 'Car')
                result.push({
                  x: d.dateTime,
                  y: d.countByHour,
                });
              return result;
            }, []),
          },
          {
            name: this.$t('cars_above_limit'),
            data: this.counterData?.graphAboveTolerance?.reduce((result, d) => {
              if (d.vehicleType == 'Car')
                result.push({
                  x: d.dateTime,
                  y: d.countByHour,
                });
              return result;
            }, []),
          },
          {
            name: this.$t('trucks'),
            data: this.counterData?.graph?.reduce((result, d) => {
              if (d.vehicleType == 'Trk')
                result.push({
                  x: d.dateTime,
                  y: d.countByHour,
                });
              return result;
            }, []),
          },
          {
            name: this.$t('trucks_above_limit'),
            data: this.counterData?.graphAboveTolerance?.reduce((result, d) => {
              if (d.vehicleType == 'Trk')
                result.push({
                  x: d.dateTime,
                  y: d.countByHour,
                });
              return result;
            }, []),
          },
        ],
      };
    },
    graphToleranceFiltered() {
      return {
        series: [
          {
            name: this.$t('cars'),
            data: this.counterData?.graphAboveTolerance?.reduce((result, d) => {
              if (d.vehicleType == 'Car')
                result.push({
                  x: d.dateTime,
                  y: d.countByHour,
                });
              return result;
            }, []),
          },
          {
            name: this.$t('trucks'),
            data: this.counterData?.graphAboveTolerance?.reduce((result, d) => {
              if (d.vehicleType == 'Trk')
                result.push({
                  x: d.dateTime,
                  y: d.countByHour,
                });
              return result;
            }, []),
          },
        ],
      };
    },
  },
  watch: {
    '$route.params.id': function(id) {
      this.fetchData();
    },
  },
};
</script>

<style>
.hidden_header {
  display: none;
}
</style>
