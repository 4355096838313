<template>
  <div>
    <content-header
      @dateChanged="dateChanged"
      datepicker
      nomargin
      directionDropdown
      @directionChanged="directionChanged"
      v-if="hideHeader == false"
      @compareChanged="compareChanged"
      @compareDateChanged="compareDateChanged"
      :compare="compare"
    ></content-header>
    <bar-statistics
      :style="{ border: compare ? '1px solid #0391e5' : 'none' }"
      :data="barData(stats)"
    ></bar-statistics>
    <bar-statistics
      :style="{ border: compare ? '1px solid red' : 'none' }"
      v-if="compare"
      title=""
      :data="barData(compareStats)"
    />
    <apex-line-chart
      yUnit="km/h"
      :compare="compare"
      :option="lineChartData"
      :title="$t('speed_percentiles')"
      nodatepicker
    ></apex-line-chart>
  </div>
</template>

<script>
import { BTable, BRow, BCol, BCard, BFormInput } from 'bootstrap-vue';
import ApexLineChart from '@/views/components/ApexLineChart.vue';
import BarStatistics from '@/views/components/BarStatistics';
import ContentHeader from '@/views/components/ContentHeader.vue';
import {
  getStartDateFromStore,
  getEndDateFromStore,
  matchSeriesDates,
} from '@/utils';
import DynamicChart from '../../components/DynamicChart.vue';

export default {
  components: {
    BTable,
    BCard,
    BRow,
    BCol,
    ApexLineChart,
    BarStatistics,
    ContentHeader,
    BFormInput,
    DynamicChart,
    BarStatistics,
  },
  mounted() {
    this.loadData();
  },
  data() {
    return {
      direction: null,
      dateRange: [getStartDateFromStore(), getEndDateFromStore()],
      customPercentile: null,
      vCustom: null,
      dateStart: null,
      dateEnd: null,
      stats: {},
      fields: [
        {
          label: '',
          key: 'text',
          sortable: false,
        },
        {
          label: '',
          key: 'number',
          sortable: false,
        },
      ],
      graphData: [],
      graphDataCustom: [],
      compare: false,
      compareStats: { graph: [] },
      compareDateRange: [getStartDateFromStore(), getEndDateFromStore()],
    };
  },

  props: {
    id: Number,
    dateFrom: String,
    dateTo: String,
    hideHeader: Boolean,
  },
  methods: {
    getData(graph, dataName) {
      return (
        graph?.map(d => {
          return {
            x: d.dateTime,
            y: d[dataName],
          };
        }) ?? []
      );
    },
    getSeries(graph, additionalTitle = '') {
      return [
        {
          name: 'v10' + additionalTitle,
          data: this.getData(graph, 'v10'),
        },
        {
          name: 'v30' + additionalTitle,
          data: this.getData(graph, 'v30'),
        },
        {
          name: 'v50' + additionalTitle,
          data: this.getData(graph, 'v50'),
        },
        {
          name: 'v85' + additionalTitle,
          data: this.getData(graph, 'v85'),
        },
      ];
    },
    async compareChanged(state) {
      this.compare = state;
    },
    directionChanged(direction) {
      this.direction = direction;
      this.loadData();
      if (this.compare) this.compareDateChanged();
    },
    dateChanged(dateRange) {
      const dates = dateRange.split('to');
      this.dateStart = dates[0];
      this.dateEnd = dates[1];
      this.dateRange = dates;
      this.loadData();
    },
    async loadData() {
      if (this.dateFrom != null && this.dateTo != null) {
        this.dateRange = [this.dateFrom, this.dateTo];
      }
      const percentileData = await this.$store.dispatch(
        'stats/GET_PERCENTILE_DATA',
        {
          id: this.id != null ? this.id : this.$route.params.id,
          dateFrom: this.dateFrom != null ? this.dateFrom : this.dateRange[0],
          dateTo: this.dateTo != null ? this.dateTo : this.dateRange[1],
          app: 'speeding',
          direction: this.direction,
        },
      );
      this.stats = percentileData.data;
    },
    async compareDateChanged(dateRange) {
      if (dateRange) {
        const dates = dateRange.split('to');
        this.compareDateRange = [dates[0], dates[1]];
      }
      const percentileData = await this.$store.dispatch(
        'stats/GET_PERCENTILE_DATA',
        {
          id: this.id != null ? this.id : this.$route.params.id,
          dateFrom: this.compareDateRange[0],
          dateTo: this.compareDateRange[1],
          app: 'speeding',
          direction: this.direction,
        },
      );
      this.compareStats = percentileData.data;
    },
    barData(stats) {
      return [
        {
          icon: 'PercentIcon',
          color: 'light-secondary',
          title: (Math.round(stats.barData?.v10 ?? 0) ?? '- ') + 'km/h',
          subtitle: this.$t('average_') + ' v10',
          customClass: 'mb-2 mb-xl-0',
        },
        {
          icon: 'PercentIcon',
          color: 'light-primary',
          title: (Math.round(stats.barData?.v30 ?? 0) ?? '- ') + 'km/h',
          subtitle: this.$t('average_') + ' v30',
          customClass: 'mb-2 mb-xl-0',
        },
        {
          icon: 'PercentIcon',
          color: 'light-info',
          title: (Math.round(stats.barData?.v50 ?? 0) ?? '- ') + 'km/h',
          subtitle: this.$t('average_') + ' v50',
          customClass: 'mb-2 mb-xl-0',
        },
        {
          icon: 'PercentIcon',
          color: 'light-danger',
          title: (Math.round(stats.barData?.v85 ?? 0) ?? '- ') + 'km/h',
          subtitle: this.$t('average_') + ' v85',
          customClass: 'mb-2 mb-sm-0',
        },
      ];
    },
  },
  computed: {
    lineChartData() {
      let series = this.getSeries(this.stats.graph, this.compare ? ' (1)' : '');
      if (this.compare) {
        let compareGraph = this.getSeries(this.compareStats.graph, ' (2)');
        if (compareGraph && compareGraph.length > 0) {
          for (let index = 0; index < 4; index++) {
            compareGraph[index].data = matchSeriesDates(
              series[index].data,
              compareGraph[index].data,
            );
          }
        }
        series = [...series, ...compareGraph];
      }
      return {
        series,
      };
    },
  },
  watch: {
    '$route.params.id': function(id) {
      this.loadData();
    },
  },
};
</script>

<style>
.hidden_header {
  display: none;
}
</style>
