<template>
  <div>
    <b-form-group label-cols="4" label-cols-lg="2" :label="$t('title')">
      <b-form-input v-model="settings.name" />
    </b-form-group>
    <b-form-group
      label-cols="4"
      label-cols-lg="2"
      :label="$t('generalSettings.location')"
    >
      <b-form-input v-model="settings.location" />
    </b-form-group>
    <b-form-group label-cols="4" label-cols-lg="2" :label="$t('mac')">
      <b-form-input disabled v-model="settings.mac" />
    </b-form-group>
    <b-form-group
      label-cols="4"
      label-cols-lg="2"
      :label="$t('generalSettings.ip_address')"
    >
      <b-form-input disabled v-model="settings.ip" />
    </b-form-group>
    <b-form-group
      label-cols="4"
      label-cols-lg="2"
      :label="$t('generalSettings.ftp_ip_address')"
      :disabled="ftp == ''"
    >
      <b-form-input v-model="ftp.ip" />
    </b-form-group>
    <b-form-group
      label-cols="4"
      label-cols-lg="2"
      :label="$t('generalSettings.ftp_username')"
      :disabled="ftp == ''"
    >
      <b-form-input v-model="ftp.username" />
    </b-form-group>
    <b-form-group
      label-cols="4"
      label-cols-lg="2"
      :label="$t('generalSettings.ftp_password')"
      :disabled="ftp == ''"
    >
      <b-form-input v-model="ftp.password" />
    </b-form-group>
    <b-form-group
      label-cols="4"
      label-cols-lg="2"
      :label="$t('generalSettings.ip_remote_gui')"
    >
      <b-form-input v-model="settings.ip_remote_gui" />
    </b-form-group>
    <div class="float-right mb-2">
      <b-button @click="updateData" variant="primary"
        >{{ $t('save') }}
      </b-button>
    </div>
  </div>
</template>

<script>
import { BFormGroup, BFormInput, BButton, BFormSelect } from 'bootstrap-vue';
import { onMounted, ref } from '@vue/composition-api';
import store from '@/store';
import i18n from '@/libs/i18n';

// Notification
import { useToast } from 'vue-toastification/composition';
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue';
import useDeviceSetttings from '@/views/apps/speeding/settings/useDeviceSettings';

export default {
  props: {
    id: {
      type: String,
    },
  },
  components: {
    BFormGroup,
    BFormInput,
    BButton,
    BFormSelect,
  },
  setup(props, context) {
    const toast = useToast();

    const ftp = ref({});

    const loadFtp = () => {
      store
        .dispatch('ftp/GET_FTP_BY_MAC', settings.value.mac)
        .then(response => {
          ftp.value = response.data;
        });
    };

    const { settings } = useDeviceSetttings(context, 'general', loadFtp);

    const updateData = async () => {
      store
        .dispatch('devices/UPDATE', {
          id: context.root.$route.params.id,
          name: settings.value.name,
          location: settings.value.location,
          ip: settings.value.ip,
          ip_remote_gui: settings.value.ip_remote_gui,
        })
        .then(async response => {
          await store.dispatch('ftp/UPDATE_FTP', {
            ftpId: ftp.value.id,
            ftp: { ...ftp.value },
          });
          toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: i18n.t('success'),
              icon: 'CheckIcon',
              variant: 'success',
              text: i18n.t('update_success_descirption'),
            },
          });
        })
        .catch(e => {
          toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: i18n.t('failed'),
              icon: 'AlertTriangleIcon',
              variant: 'danger',
              text: i18n.t(e.response.data.message),
            },
          });
        });
    };

    return {
      settings,
      updateData,
      ftp,
    };
  },
};
</script>

<style></style>
