<template>
  <div>
    <b-table responsive="sm" :items="items" :fields="fields">
      <template #cell(numberOfChars)="data">
        {{ data.item.rowText.replaceAll("'", '').length }}
      </template>
      <template #cell(pageNumber)="data">
        {{ data.item.rowNumber == 1 ? data.item.pageNumber : '' }}</template
      >
      <template #cell(rowText)="data">
        <b-form-input
          v-model="data.item.rowText"
          class="form-control-merge"
          type="text"
      /></template>
      <template #cell(rowSpeed)="data">
        <b-form-input
          v-model="data.item.rowSpeed"
          class="form-control-merge"
          type="text"
      /></template>
      <template #cell(color)="data">
        <vi-select
          v-if="data.item.rowNumber == 1"
          v-model="data.item.color"
          :options="options"
          label=""
        ></vi-select>
      </template>
    </b-table>
    <div class=" mb-1">
      <div class="float-right mb-2">
        <b-button @click="updateData" variant="primary">{{
          $t('save')
        }}</b-button>
        <b-button @click="refreshData" variant="outline-primary" class="ml-1">{{
          $t('refresh')
        }}</b-button>
      </div>
    </div>
  </div>
</template>

<script>
import { BTable, BFormInput, BButton } from 'bootstrap-vue';
import ViSelect from '@/views/components/ViSelect.vue';
import { dangerToast, successToast } from '@/utils/toast';
export default {
  components: { BTable, BFormInput, ViSelect, BButton },
  data() {
    return {
      options: [
        { label: 'Zelena', code: 1 },
        { label: 'Rdeča', code: 2 },
        { label: 'Zelena utripajoča', code: 17 },
        { label: 'Rdeča utripajoča', code: 18 },
      ],
      items: [],
      fields: [
        {
          label: this.$t('settings.page_number'),
          key: 'pageNumber',
          sortable: false,
          thClass: 'my-th-background',
        },
        {
          label: this.$t('settings.row_number'),
          key: 'rowNumber',
          sortable: false,
          thClass: 'my-th-background',
        },
        {
          label: this.$t('settings.number_of_chars'),
          key: 'numberOfChars',
          sortable: false,
          thClass: 'my-th-background',
        },
        {
          label: this.$t('settings.text'),
          key: 'rowText',
          sortable: false,
          thClass: 'my-th-background',
        },
        {
          label: this.$t('settings.row_speed'),
          key: 'rowSpeed',
          sortable: false,
          thClass: 'my-th-background',
        },
        {
          label: this.$t('settings.color'),
          key: 'color',
          sortable: false,
          thClass: 'my-th-background',
        },
      ],
    };
  },
  async mounted() {
    const response = await this.$store.dispatch('pagetext/GET_PAGE_TEXT', {
      deviceId: this.$route.params.id,
    });
    this.items = response.data;
  },
  methods: {
    async updateData() {
      try {
        this.items.forEach(
          i => (i.numberOfChars = i.rowText.replaceAll("'", '').length),
        );
        const response = await this.$store.dispatch(
          'pagetext/UPDATE_PAGE_TEXT',
          {
            deviceId: this.$route.params.id,
            pages: this.items,
          },
        );
        successToast(
          this.$toast,
          this.$t('success'),
          this.$t('update_success_descirption'),
        );
      } catch (e) {
        dangerToast(
          this.$toast,
          this.$t('failed'),
          this.$t(e.response.data.message),
        );
      }
    },
    async refreshData() {
      try {
        const response = await this.$store.dispatch(
          'pagetext/REFRESH_PAGE_TEXT',
          {
            deviceId: this.$route.params.id,
          },
        );
        this.items = response.data;
        successToast(
          this.$toast,
          this.$t('success'),
          this.$t('update_success_descirption'),
        );
      } catch (e) {
        dangerToast(
          this.$toast,
          this.$t('failed'),
          this.$t(e.response.data.message),
        );
      }
    },
  },
};
</script>

<style>
.my-table {
  background: white;
}

.my-th-background {
  background-color: transparent !important;
  border-top: 0 !important;
}

@media (min-width: 1200px) and (max-width: 1700px) {
  .col-xl-6 {
    flex: 0 0 100% !important;
    max-width: 100% !important;
  }
}
</style>
