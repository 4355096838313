import store from '@/store';

export const isLocalLayout = () => {
    return store.state.appConfig.layout.isLocalLayout;
}

export const getAppName = () => {
    return isLocalLayout() == null ? '' : (isLocalLayout() ? 'TraffiNet' : 'Vivozite.si');
}

