<template>
  <div>
    <div class="d-flex justify-content-end">
      <date-range-picker @date-changed="dateChanged" />
    </div>
    <apex-line-chart
      :option="graphDataFiltered"
      :title="''"
      :subtitle="''"
      nodatepicker
      :key="updateChartCount"
      :decimalNumbers="2"
      :min="min"
      :max="max"
      :brightnessPercentage="false"
      :temperature="true"
    >
    </apex-line-chart>
  </div>
</template>

<script>
import { BRow, BCol } from 'bootstrap-vue';
import DateRangePicker from '@/views/components/DateRangePicker.vue';

import ApexLineChart from '@/views/components/ApexLineChartDiagnostic.vue';
export default {
  props: {
    id: { type: String, required: false },
  },
  components: {
    ApexLineChart,
    BRow,
    BCol,
    DateRangePicker,
  },
  data() {
    return {
      stats: [],
      updateChartCount: 0,
      min: null,
      max: null,
    };
  },
  async mounted() {
    this.loadData();
  },
  computed: {
    graphDataFiltered() {
      return {
        series: [
          {
            name: this.$t('temperature'),
            data:
              this.stats?.map(d => {
                return {
                  x: d.date,
                  y: d.temperature,
                };
              }) ?? [],
          },
          {
            name: this.$t('cyclist.voltage'),
            data:
              this.stats?.map(d => {
                return {
                  x: d.date,
                  y: d.voltage ? d.voltage : null,
                };
              }) ?? [],
          },
        ],
      };
    },
  },
  methods: {
    updateChart() {
      this.updateChartCount++;
    },
    async dateChanged(dateRange) {
      const dates = dateRange.split('to');
      this.loadData(dates[0], dates[1]);
    },
    async loadData(dateFrom = null, dateTo = null) {
      //Bar statistics
      const response = await this.$store.dispatch(
        'stats/GET_VEHICLE_DIAGNOSTIC_CHART',
        {
          id: this.$route.params.id,
          dateFrom,
          dateTo,
        },
      );
      this.stats = response.data;
      this.min = new Date(
        this.$moment(dateFrom, 'DD.MM.YYYY HH:mm').format('YYYY-MM-DD HH:mm'),
      ).getTime();
      this.max = new Date(
        this.$moment(dateTo, 'DD.MM.YYYY HH:mm')
          .add(8, 'hours')
          .format('YYYY-MM-DD HH:mm'),
      ).getTime();
    },
  },
  watch: {
    '$route.params.id': function(id) {
      this.loadData();
    },
  },
};
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';

.select-width {
  width: 300px;
}
</style>
