<template>
  <b-col sm="8" md="6" lg="12" class="px-xl-2 mx-auto">
    <b-card-title title-tag="h2" class="font-weight-bold mb-1">
      {{ appName() }}
    </b-card-title>
    <b-card-text class="mb-2">
      {{ $t('login.subtitle') }}
    </b-card-text>

    <!-- form -->
    <validation-observer ref="loginValidation">
      <b-form class="auth-login-form mt-2" @submit.prevent>
        <!-- email -->
        <b-form-group :label="$t('email-or-username')" label-for="login-email">
          <validation-provider
            #default="{ errors }"
            name="Email"
            rules="required"
          >
            <b-form-input
              id="login-email"
              v-model="userEmail"
              :state="errors.length > 0 ? false : null"
              name="login-email"
              :placeholder="$t('login.email_placeholder')"
            />
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>

        <!-- forgot password -->
        <b-form-group>
          <div class="d-flex justify-content-between">
            <label for="login-password">{{ $t('password') }}</label>
          </div>
          <validation-provider
            #default="{ errors }"
            name="password"
            rules="required"
          >
            <b-input-group
              class="input-group-merge"
              :class="errors.length > 0 ? 'is-invalid' : null"
            >
              <b-form-input
                id="login-password"
                v-model="password"
                :state="errors.length > 0 ? false : null"
                class="form-control-merge"
                :type="passwordFieldType"
                name="login-password"
                placeholder="············"
              />
              <b-input-group-append is-text>
                <feather-icon
                  class="cursor-pointer"
                  :icon="passwordToggleIcon"
                  @click="togglePasswordVisibility"
                />
              </b-input-group-append>
            </b-input-group>
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>

        <!-- submit buttons -->
        <b-button type="submit" variant="primary" block @click="login">
          {{ $t('login.login') }}
        </b-button>
      </b-form>
    </validation-observer>

    <b-card-text class="text-center mt-2">
      <b-link :to="{ name: 'forgot-password' }">
        <span>&nbsp;{{ $t('forgot.title') }}?</span>
      </b-link>
    </b-card-text>
  </b-col>
</template>

<script>
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue';
/* eslint-disable global-require */
import { ValidationProvider, ValidationObserver } from 'vee-validate';

import {
  BRow,
  BCol,
  BLink,
  BFormGroup,
  BFormInput,
  BInputGroupAppend,
  BInputGroup,
  BFormCheckbox,
  BCardText,
  BCardTitle,
  BImg,
  BForm,
  BButton,
} from 'bootstrap-vue';
import { required, email } from '@validations';
import { togglePasswordVisibility } from '@core/mixins/ui/forms';
import useJwt from '@/auth/jwt/useJwt';
import { getAppName } from '@/utils/layout';

export default {
  components: {
    BRow,
    BCol,
    BLink,
    BFormGroup,
    BFormInput,
    BInputGroupAppend,
    BInputGroup,
    BFormCheckbox,
    BCardText,
    BCardTitle,
    BImg,
    BForm,
    BButton,
    ValidationProvider,
    ValidationObserver,
  },
  mixins: [togglePasswordVisibility],
  data() {
    return {
      status: '',
      password: '',
      userEmail: '',
      // validation rulesimport store from '@/store/index'
      required,
      email,
    };
  },
  computed: {
    passwordToggleIcon() {
      return this.passwordFieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon';
    },
    imgUrl() {
      return this.sideImg;
    },
  },
  methods: {
    appName() {
      return getAppName();
    },
    async login() {
      const success = await this.$refs.loginValidation.validate();
      if (success) {
        let response = null;
        try {
          response = await useJwt
            .login({
              email: this.userEmail,
              password: this.password,
            })
            .catch((error) => {
              throw error;
            });
        } catch (error) {
          console.log(error.response);
          this.$toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: this.$t('login.login_failed'),
              icon: 'AlertTriangleIcon',
              variant: 'danger',
              text: this.$t('login.wrong_credentials'),
            },
          });
        }

        if (response) {
          const userData = response.data;
          useJwt.setToken(response.data.access_token);
          //useJwt.setRefreshToken(response.data.refreshToken);

          this.$store.commit('auth/SET_USER_DATA', userData);
          this.$ability.update(userData.ability);
          this.$i18n.locale = userData.lang;
          //getHomeRouteForLoggedInUser(userData ? 'admin' : null)
          if (userData.isPasswordSet) {
            await this.$router.replace('/');

            this.$toast({
              component: ToastificationContent,
              position: 'top-right',
              props: {
                title: this.$t('login.welcome_user') + ' ' + userData.firstname,
                icon: 'UserCheckIcon',
                variant: 'success',
                text: this.$t('login.user_login_success'),
              },
            });
          } else {
            this.$toast({
              component: ToastificationContent,
              position: 'top-right',
              props: {
                title: this.$t('login.login_success'),
                icon: 'UserCheckIcon',
                variant: 'warning',
                text: this.$t('login.set_new_password'),
              },
            });
            await this.$router.replace('/auth/change-password');
          }
        }
      }
    },
  },
};
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-auth.scss';
</style>
