import useJwt from '@/auth/jwt/useJwt';
import iframeuseJwt from '@/auth/iframejwt/useJwt';
import axiosIns from '@/libs/axios';
import { $GET, $POST, $DELETE, $PATCH } from '../methods';

export default {
  namespaced: true,
  state: {
    userData: null,
    company: null,
  },
  getters: {},
  mutations: {
    SET_USER_DATA(state, data) {
      state.userData = data;
    },
    SET_ACCESS_TOKEN(state, token) {
      state[useJwt.jwtConfig.storageTokenKeyName] = token;
    },
    SET_IFRAME_ACCESS_TOKEN(state, token) {
      state[iframeuseJwt.jwtConfig.storageTokenKeyName] = token;
    },
    USER_PASSWORD_UPDATED(state, status) {
      state.userData.isPasswordSet = status;
    },
    SET_LOGGED_IN_USER(state, userId) {
      state.loggedInAs = userId
        ? {
            userId,
            token: state[useJwt.jwtConfig.storageTokenKeyName],
          }
        : null;
    },
    SET_USER_LANG(state, data) {
      state.userData.lang = data;
    },
  },
  actions: {
    GET_USER(state, payload) {
      return $GET('users/' + payload.userId);
    },
    LOGIN_AS({ state, commit }, payload) {
      commit('SET_LOGGED_IN_USER', state.userData.userId);
      return $POST('auth/loginAs', payload);
    },
    GET_APP_UID(state) {
      return $GET('auth/uid');
    },
    ACTIVATE_APP(state, { key }) {
      return $POST('auth/activation', { key });
    },
    USER_LOGOUT(state) {
      return $POST('auth/logout');
    },
    async GET_AND_SAVE_COMPANY_IN_STORE({ state }, payload) {
      if (state.userData) {
        const response = await $GET('companies/' + state.userData.companyId);
        state.company = response.data;
      }
    },
  },
};
