<template>
  <div>
    <!-- Table Container Card -->
    <b-card no-body class="mb-0">
      <advanced-table
        :tableColumns="tableColumns"
        dataAction="audit/GET_ACTION_AUDIT"
        :dataURLParams="{ locationCode }"
        ref="auditTable"
        :hideHeader="true"
      >
        <template #cell(action)="data">
          {{ getActionName(data.item.action) }}
        </template>
        <!-- Column: Actions -->
      </advanced-table>
    </b-card>
  </div>
</template>

<script>
import {
  BButton,
  BDropdown,
  BDropdownItem,
  BCard,
  BFormCheckbox,
} from 'bootstrap-vue';
import AdvancedTable from '@/views/components/AdvancedTable.vue';
import i18n from '@/libs/i18n';

export default {
  components: {
    BButton,
    BDropdown,
    BDropdownItem,
    BCard,
    BFormCheckbox,
    AdvancedTable,
  },
  props: { locationCode: null },
  data() {
    return {
      tableColumns: [
        { key: 'date', label: i18n.tc('date'), sortable: true },
        { key: 'user', label: i18n.tc('user'), sortable: true },
        { key: 'oldValue', label: i18n.tc('oldValue'), sortable: true },
        { key: 'newValue', label: i18n.tc('newValue'), sortable: true },
        { key: 'action', label: i18n.tc('action'), sortable: true },
      ],
    };
  },
  methods: {
    getActionName(action) {
      return i18n.tc(action);
    },
    getUsername(user) {
      return user ? user.firstName + ' ' + user.lastName : '-';
    },
    formatDate(value) {
      if (value) {
        return this.$moment(value).format('DD.MM.YYYY HH:mm');
      }
    },
    refreshData() {
      this.$refs.auditTable.refresh();
    },
  },
  watch: {
    '$route.params.type': function (id) {
      setTimeout(() => {
        this.$refs.devicesTable.refresh();
      }, 50);
    },
  },
};
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
