<template>
  <b-col sm="8" md="6" lg="12" class="px-xl-2 mx-auto">
    <b-card-title title-tag="h2" class="font-weight-bold mb-1">
      {{ appName() }}
    </b-card-title>
    <b-card-text class="mb-2">
      {{ $t('reset-password.subtitle') }}
    </b-card-text>

    <!-- form -->
    <validation-observer ref="loginValidation">
      <b-form class="auth-login-form mt-2" @submit.prevent>
        <!-- new password -->
        <b-form-group>
          <div class="d-flex justify-content-between">
            <label for="login-password">{{ $t('new-password') }}</label>
          </div>
          <validation-provider
            #default="{ errors }"
            name="new-password"
            rules="required|min:6"
            vid="confirm"
          >
            <b-input-group
              class="input-group-merge"
              :class="errors.length > 0 ? 'is-invalid' : null"
            >
              <b-form-input
                id="login-password"
                v-model="password"
                :state="errors.length > 0 ? false : null"
                class="form-control-merge"
                :type="passwordFieldType"
                name="login-password"
                placeholder="············"
              />
              <b-input-group-append is-text>
                <feather-icon
                  class="cursor-pointer"
                  :icon="passwordToggleIcon"
                  @click="togglePasswordVisibility"
                />
              </b-input-group-append>
            </b-input-group>
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>

        <!-- repeat password -->
        <b-form-group>
          <div class="d-flex justify-content-between">
            <label for="login-password">{{ $t('repeat-password') }}</label>
          </div>
          <validation-provider
            #default="{ errors }"
            name="password"
            rules="required|confirmed:confirm"
          >
            <b-input-group
              class="input-group-merge"
              :class="errors.length > 0 ? 'is-invalid' : null"
            >
              <b-form-input
                id="repeat-password"
                v-model="repeatPassword"
                :state="errors.length > 0 ? false : null"
                class="form-control-merge"
                :type="passwordFieldType"
                name="repeat-password"
                placeholder="············"
              />
              <b-input-group-append is-text>
                <feather-icon
                  class="cursor-pointer"
                  :icon="passwordToggleIcon"
                  @click="togglePasswordVisibility"
                />
              </b-input-group-append>
            </b-input-group>
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>

        <!-- submit buttons -->
        <b-button type="submit" variant="primary" block @click="changePassword">
          {{ $t('reset-password.change-password') }}
        </b-button>
      </b-form>
    </validation-observer>
  </b-col>
</template>

<script>
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue';
/* eslint-disable global-require */
import { ValidationProvider, ValidationObserver } from 'vee-validate';

import {
  BRow,
  BCol,
  BLink,
  BFormGroup,
  BFormInput,
  BInputGroupAppend,
  BInputGroup,
  BFormCheckbox,
  BCardText,
  BCardTitle,
  BImg,
  BForm,
  BButton,
} from 'bootstrap-vue';
import { required, email } from '@validations';
import { togglePasswordVisibility } from '@core/mixins/ui/forms';
import { getUserData, getHomeRouteForLoggedInUser } from '@/auth/utils';
import { getAppName } from '@/utils/layout';

export default {
  components: {
    BRow,
    BCol,
    BLink,
    BFormGroup,
    BFormInput,
    BInputGroupAppend,
    BInputGroup,
    BFormCheckbox,
    BCardText,
    BCardTitle,
    BImg,
    BForm,
    BButton,
    ValidationProvider,
    ValidationObserver,
  },
  mixins: [togglePasswordVisibility],
  data() {
    return {
      status: '',
      password: '',
      repeatPassword: '',
      userEmail: '',
      // validation rulesimport store from '@/store/index'
      required,
      email,
    };
  },
  computed: {
    passwordToggleIcon() {
      return this.passwordFieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon';
    },
    imgUrl() {
      return this.sideImg;
    },
  },
  methods: {
    appName() {
      return getAppName();
    },
    async changePassword() {
      const valid = await this.$refs.loginValidation.validate();
      if (valid) {
        let response = null;
        try {
          response = await this.$http
            .post('/auth/resetPassword', {
              token: this.$route.params.token,
              password: this.password,
            })
            .catch((error) => {
              throw error;
            });
        } catch (error) {
          this.$toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: this.$t('error'),
              icon: 'AlertTriangleIcon',
              variant: 'danger',
              text: this.$t(error.response.data.message),
            },
          });
        }
        if (response) {
          this.$toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: this.$t('new-password'),
              icon: 'UserCheckIcon',
              variant: 'success',
              text: this.$t('login.new-password-success'),
            },
          });
          await this.$router.replace('/auth/login');
        }
      }
    },
  },
};
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-auth.scss';
</style>
