<template>
  <b-form-group class="mb-0" :label="label" :label-for="label.toLowerCase()">
    <validation-provider #default="{ errors }" :name="label" :rules="rules">
      <v-select
        :reduce="dir => dir.code"
        v-model="selectedOption"
        :options="options"
        :clearable="false"
        class="per-page-selector d-inline-block w-100"
        @input="changed"
      >
        <div slot="no-options">{{ $t('no-options') }}</div>
      </v-select>
      <small class="text-danger">{{ errors[0] }}</small>
    </validation-provider>
  </b-form-group>
</template>

<script>
import { BCard, BFormInput, BFormGroup } from 'bootstrap-vue';
import { ValidationProvider, ValidationObserver } from 'vee-validate';
import vSelect from 'vue-select';
export default {
  props: {
    value: { type: Number | String, required: false },
    label: {
      type: String,
      required: true,
    },
    rules: {
      type: String,
      required: false,
    },
    type: {
      type: String,
      default: 'text',
    },
    options: {
      type: Array,
      required: true,
    },
  },
  components: {
    BCard,
    BFormInput,
    BFormGroup,
    ValidationProvider,
    ValidationObserver,
    vSelect,
  },
  computed: {
    selectedOption: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit('input', val);
      },
    },
  },
  methods: {
    changed(data) {
      this.$emit('change', data);
    },
  },
};
</script>

<style></style>
