import { $GET, $PATCH, $POST, $DELETE } from './methods';
import authStore from '../store/auth';

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    GET_COMPANIES(state, payload) {
      return $GET('companies');
    },
    GET_COMPANY(state, id) {
      return $GET('companies/' + id);
    },
    UPDATE_COMPANY(state, payload) {
      return $PATCH('companies/' + payload.companyId, payload.company);
    },
    CREATE_COMPANY(state, payload) {
      return $POST('companies', payload.company);
    },
    DELETE_COMPANY(state, companyId) {
      return $DELETE('companies/' + companyId);
    },
    GET_COMPANY_USERS(state, payload) {
      return $GET('companies/' + payload.companyId + '/users');
    },
    SAVE_USERS(state, payload) {
      return $POST('companies/' + payload.companyId + '/users', payload);
    },
  },
};
