<template>
  <div>
    <b-row>
      <b-col md="6">
        <b-form-group
          label-cols="4"
          label-cols-lg="2"
          :label="$t('settings.firmware_version')"
        >
          <b-form-input disabled :value="settings.firmware" />
        </b-form-group>
        <b-form-group
          label-cols="4"
          label-cols-lg="2"
          :label="$t('settings.type')"
        >
          <b-form-input disabled :value="settings.model" />
        </b-form-group>
        <b-form-group
          label-cols="4"
          label-cols-lg="2"
          :label="$t('settings.serial_num')"
        >
          <b-form-input disabled :value="settings.serialNumber" />
        </b-form-group>
      </b-col>
      <b-col md="6">
        <b-form-group label-cols="4" label-cols-lg="2" :label="$t('title')">
          <b-form-input v-model="settings.name" />
        </b-form-group>
        <b-form-group
          label-cols="4"
          label-cols-lg="2"
          :label="$t('settings.device_id')"
        >
          <b-form-input v-model="settings.location" />
        </b-form-group>
        <b-form-group
          label-cols="4"
          label-cols-lg="2"
          :label="$t('settings.on/off')"
        >
          <b-form-select
            class="bordered"
            v-model="selectedState"
            :options="stateOptions"
          />
        </b-form-group>
      </b-col>
    </b-row>
    <div class="d-flex justify-content-between">
      <span>{{ $t('last_updated') }} {{ lastUpdated }}</span>
      <div>
        <b-button @click="updateData" variant="primary"
          >{{ $t('save') }}
        </b-button>
        <b-button
          @click="refreshDataAdditional"
          variant="outline-primary"
          class="ml-1"
          >{{ $t('refresh') }}
        </b-button>
      </div>
    </div>
  </div>
</template>

<script>
import {
  BFormGroup,
  BFormInput,
  BButton,
  BFormSelect,
  BRow,
  BCol,
} from 'bootstrap-vue';
import useDeviceSetttings from './useDeviceSettings';
import { ref } from '@vue/composition-api';
import store from '@/store';
import i18n from '@/libs/i18n';
import { onMounted } from '@vue/composition-api';

// Notification
import { useToast } from 'vue-toastification/composition';
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue';
import { dangerToast, successToast } from '@/utils/toast';

export default {
  props: {
    id: {
      type: String,
    },
  },
  components: {
    BFormGroup,
    BFormInput,
    BButton,
    BFormSelect,
    BRow,
    BCol,
  },
  setup(props, context) {
    const toast = useToast();
    const selectedState = ref(null);
    const stateOptions = [
      { value: null, text: i18n.t('generalSettings.select_state') },
      { value: '1', text: i18n.t('generalSettings.led_display_on') + ' (1)' },
      {
        value: '0',
        text: i18n.t('generalSettings.led_display_off') + ' (0)',
      },
    ];

    const { lastUpdated, settings, refreshData } = useDeviceSetttings(
      context,
      'general',
    );

    const updateData = async () => {
      store
        .dispatch('devices/UPDATE', {
          id: context.root.$route.params.id,
          name: settings.value.name,
          location: settings.value.location,
        })
        .then(response => {
          successToast(
            toast,
            i18n.t('success'),
            i18n.t('update_success_descirption'),
          );
        })
        .catch(e => {
          dangerToast(toast, i18n.t('failed'), i18n.t(e.response.data.message));
        });

      store
        .dispatch('devices/SET_ON_OFF_STATE', {
          deviceId: context.root.$route.params.id,
          userId: store.state.auth.userData.userId,
          state: selectedState.value,
        })
        .then(response => {})
        .catch(e => {
          dangerToast(toast, i18n.t('failed'), i18n.t(e.response.data.message));
        });
    };

    const refreshDataAdditional = async (withRefresh = true) => {
      if (withRefresh) await refreshData();
      store
        .dispatch('devices/GET_ON_OFF_STATE', {
          deviceId: context.root.$route.params.id,
          userId: store.state.auth.userData.userId,
        })
        .then(response => {
          selectedState.value = response.data;
        });
    };

    onMounted(() => {
      refreshDataAdditional(false);
    });

    return {
      selectedState,
      stateOptions,
      lastUpdated,
      settings,
      updateData,
      refreshDataAdditional,
    };
  },
};
</script>

<style></style>
