import { $GET, $PATCH, $POST, $DELETE } from './methods';

export default {
  namespaced: true,
  state: {
  },
  getters: {},
  mutations: {},
  actions: {
    GET_FTP(state, payload) {
      return $GET('ftp');
    },
    GET_FTP_DETAIL(state, id) {
      return $GET('ftp/' + id);
    },
    UPDATE_FTP(state, payload) {
      return $PATCH('ftp/' + payload.ftpId, payload.ftp);
    },
    CREATE_FTP(state, payload) {
      return $POST('ftp', payload.ftp);
    },
    DELETE_FTP(state, ftpId) {
      return $DELETE('ftp/' + ftpId);
    },
    GET_FTP_BY_MAC(state, mac) {
      return $GET('ftp/' + mac + '/mac');
    },
    RUN_FTP_DATA_FETCH(state) {
      return $POST('ftp/fetch', {});
    },
  },
};
