<template>
  <div>
    <b-form-group
      label-cols="4"
      label-cols-lg="3"
      :label="$t('speeding.speed_limit')"
    >
      <b-form-input placeholder="km/h" v-model="settings.speedLimit" />
    </b-form-group>
    <b-form-group
      label-cols="4"
      label-cols-lg="3"
      :label="$t('settings.min_speed_for_display')"
    >
      <b-form-input placeholder="km/h" v-model="settings.minSpeed" />
    </b-form-group>
    <b-form-group
      label-cols="4"
      label-cols-lg="3"
      :label="$t('settings.max_speed_for_display')"
    >
      <b-form-input placeholder="km/h" v-model="settings.maxSpeed" />
    </b-form-group>

    <b-form-group
      label-cols="4"
      label-cols-lg="3"
      :label="$t('settings.blinking_speed_limit')"
    >
      <b-form-input placeholder="km/h" v-model="settings.speedLimitBlink" />
    </b-form-group>
    <b-form-group
      label-cols="4"
      label-cols-lg="3"
      :label="$t('settings.color_change_speed_limit')"
    >
      <b-form-input placeholder="km/h" v-model="settings.speedLimitColor" />
    </b-form-group>

    <div class="d-flex justify-content-between">
      <span>{{ $t('last_updated') }} {{ lastUpdated }}</span>
      <div class="float-right mb-2">
        <b-button @click="updateData" variant="primary">{{
          $t('save')
        }}</b-button>
        <b-button @click="refreshData" variant="outline-primary" class="ml-1">{{
          $t('refresh')
        }}</b-button>
      </div>
    </div>
  </div>
</template>

<script>
import {
  BFormGroup,
  BFormInput,
  BButton,
  BFormSelect,
  BRow,
  BCol,
} from 'bootstrap-vue';
import useDeviceSetttings from './useDeviceSettings';

export default {
  props: {
    id: {
      type: String,
    },
  },
  components: {
    BFormGroup,
    BFormInput,
    BButton,
    BFormSelect,
    BRow,
    BCol,
  },
  setup(props, context) {
    const {
      lastUpdated,
      settings,
      updateData,
      refreshData,
    } = useDeviceSetttings(context, 'speed');

    return {
      lastUpdated,
      settings,
      updateData,
      refreshData,
    };
  },
};
</script>

<style></style>
