<template>
  <b-card class="p-2">
    <h4 class="mb-2">{{ $t('report.export_data') }}</h4>
    <!-- default label -->
    <b-form-group
      label-cols="4"
      label-cols-lg="1"
      :label="$t('period')"
      label-for="input-default"
    >
      <div style="width:300px">
        <date-range-picker
          :last7Days="true"
          class="pl-2"
          ref="datePicker"
        ></date-range-picker>
      </div>
    </b-form-group>
    <b-form-group
      label-cols="4"
      label-cols-lg="1"
      :label="$t('report.export_data_type')"
      label-for="input-default"
    >
      <div class="d-flex align-items-center ml-2 input-size">
        <b-form-select
          class="bordered"
          v-model="selectedType"
          :options="options"
        />
      </div>
    </b-form-group>
    <b-form-group
      label-cols="4"
      label-cols-lg="1"
      :label="$t('report.raw_data')"
      label-for="input-default"
    >
      <div class="d-flex align-items-center ml-2 input-size">
        <b-form-checkbox
          v-model="includeRawData"
          name="checkbox-1"
          :value="1"
          :unchecked-value="0"
        >
        </b-form-checkbox>
      </div>
    </b-form-group>

    <b-button class="mt-2" variant="primary" @click="generatePDF('short')">{{
      $t('report.export_short_report')
    }}</b-button>
    <b-button
      class="mt-2 ml-1"
      variant="primary"
      @click="generatePDF('long')"
      >{{ $t('report.export_long_report') }}</b-button
    >
    <hr />
    <div class="mt-3">
      <div>
        <div class="d-flex justify-content-between align-items-center pb-1">
          <div class="d-flex align-items-center justify-center">
            <h4>{{ $t('auto-export-option') }}</h4>
          </div>
          <b-form-checkbox
            name="is-vertical-menu-collapsed"
            class="mr-0"
            switch
            inline
            v-model="autoExport.enabled"
            @change="saveAutoExport"
          />
        </div>
      </div>

      <!--<b-form-group
        label-cols="4"
        label-cols-lg="1"
        :label="$t('email')"
        label-for="input-default"
        class="mt-2"
      >
        <div class="d-flex align-items-center ml-2 input-size">
          <b-form-input
            class="bordered"
            v-model="exportEmail"
            :placeholder="$t('email')"
          />
        </div>
      </b-form-group>-->
      <div :class="{ 'not-in-version': !autoExport.enabled }">
        <b-form-group
          label-cols="4"
          label-cols-lg="1"
          :label="$t('report.interval')"
          class="mt-2"
        >
          <div class="d-flex align-items-center ml-2 input-size">
            <b-form-select
              class="bordered"
              v-model="autoExport.interval"
              :options="intervalOptions"
            />
          </div>
        </b-form-group>
        <b-button @click="saveAutoExport" class="mt-2" variant="primary">{{
          $t('save')
        }}</b-button>
      </div>
    </div>
  </b-card>
</template>

<script>
import {
  BCard,
  BButton,
  BFormSelect,
  BFormGroup,
  BFormInput,
  BFormCheckbox,
} from 'bootstrap-vue';
import flatPickr from 'vue-flatpickr-component';
import DateRangePicker from '../../components/DateRangePicker.vue';
import { dangerToast, successToast } from '@/utils/toast';
export default {
  components: {
    BCard,
    flatPickr,
    BButton,
    BFormSelect,
    BFormGroup,
    BFormInput,
    DateRangePicker,
    BFormCheckbox,
  },
  data() {
    return {
      includeRawData: false,
      rangePicker: ['2019-05-01', '2019-05-10'],
      selected: null,
      selectedType: null,
      exportEmail: null,
      options: [
        { value: null, text: this.$t('report.export_type') },
        { value: 'pdf', text: 'PDF' },
        { value: 'word', text: 'Word' },
      ],
      intervalOptions: [
        { value: null, text: this.$t('report.choose_interval') },
        { value: 1, text: this.$t('report.daily') },
        { value: 2, text: this.$t('report.weekly') },
        { value: 3, text: this.$t('report.monthly') },
      ],
      autoExport: {
        id: null,
        interval: null,
        userId: this.$store.state.auth.userData.userId,
        deviceId: this.$route.params.id,
        enabled: false,
      },
    };
  },
  async mounted() {
    this.loadData();
  },
  methods: {
    async loadData() {
      const response = await this.$store.dispatch('stats/GET_AUTO_EXPORT', {
        deviceId: this.$route.params.id,
      });
      if (response && response.data) {
        this.autoExport = response.data;
      } else {
        this.autoExport = {
          id: null,
          interval: null,
          userId: this.$store.state.auth.userData.userId,
          deviceId: this.$route.params.id,
          enabled: false,
        };
      }
    },
    async saveAutoExport() {
      const response = await this.$store.dispatch(
        'stats/SAVE_AUTO_EXPORT',
        this.autoExport,
      );
      if (response && response.data) {
        this.autoExport = response.data;
        successToast(
          this.$toast,
          this.$t('success'),
          this.$t('auto_export_success'),
        );
      }
    },
    async generatePDF(type) {
      if (this.selectedType) {
        const rangePicker = this.$refs.datePicker.dateRange;
        const response = await this.$store.dispatch('stats/GENERATE_REPORT', {
          deviceId: this.$route.params.id,
          dateFrom: rangePicker.startDate,
          dateTo: rangePicker.endDate,
          type: this.selectedType,
          docType: type,
          includeRawData: this.includeRawData,
        });
        if (response) {
          var bytes = new Uint8Array(response.data.data);
          var fileURL = window.URL.createObjectURL(
            new Blob([bytes], {
              type:
                'application/' + (this.selectedType == 'word' ? 'docx' : 'pdf'),
            }),
          );
          var fileLink = document.createElement('a');

          fileLink.href = fileURL;
          fileLink.setAttribute(
            'download',
            `Poročilo-${
              this.$store.state.devices.currentDevice.location
            }-${this.$moment().format('DD-MM-YYYY')}.${
              this.selectedType == 'word' ? 'docx' : 'pdf'
            }`,
          );
          document.body.appendChild(fileLink);

          fileLink.click();
        }
      } else {
        dangerToast(
          this.$toast,
          this.$t('failed'),
          this.$t('choose_export_type'),
        );
      }
    },
  },
  watch: {
    '$route.params.id': function(id) {
      this.loadData();
    },
  },
};
</script>

<style>
.not-in-version {
  pointer-events: none;
  opacity: 0.2;
}

.bordered {
  border: 1px solid #d8d6de;
  border-radius: 5px;
}
.input-size {
  width: 300px;
}
</style>
