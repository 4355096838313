<template>
  <b-card>
    <div>
      <b-form
        ref="form"
        :style="{ height: trHeight }"
        class="repeater-form"
        @submit.prevent="repeateAgain"
      >
        <!-- Row Loop -->
        <b-row
          v-for="(item, index) in items"
          :id="item.id"
          :key="item.id"
          ref="row"
        >
          <!-- Cost -->
          <b-col md="1">
            <b-form-group :label="$t('speed_from')" label-for="speedFrom">
              <b-form-input
                v-model="item.speedFrom"
                type="number"
                placeholder="km/h"
                @change="valueChanged(item)"
              />
            </b-form-group>
          </b-col>

          <!-- Quantity -->
          <b-col md="1">
            <b-form-group :label="$t('speed_to')" label-for="SpeedTo">
              <b-form-input
                v-model="item.speedTo"
                type="number"
                placeholder="km/h"
                @change="valueChanged(item)"
              />
            </b-form-group>
          </b-col>

          <!-- Item Name -->
          <b-col md="2">
            <vi-select
              v-model="item.symbol"
              :options="options"
              :label="
                table == 'speed-symbols'
                  ? $t('settings.symbols')
                  : $t('settings.led_page')
              "
              @change="valueChanged(item, index)"
            />
          </b-col>
          <b-col md="2">
            <b-form-group
              :label="$t('settings.text') + ' (vrstica 1)'"
              :label-for="$t('settings.text')"
            >
              <b-form-input
                :value="pageText(item, 0)"
                @input="changePageText(item, 0, $event)"
                type="text"
                maxlength="8"
              />
            </b-form-group>
          </b-col>
          <b-col md="2">
            <b-form-group
              :label="$t('settings.text') + ' (vrstica 2)'"
              :label-for="$t('settings.text')"
            >
              <b-form-input
                :value="pageText(item, 1)"
                @input="changePageText(item, 1, $event)"
                type="text"
                maxlength="8"
              />
            </b-form-group>
          </b-col>
          <b-col md="2">
            <b-form-group
              :label="$t('settings.color')"
              :label-for="$t('settings.color')"
            >
              <vi-select
                @change="changeColor(item, $event)"
                :value="pageColor(item)"
                :options="colorOptions"
                label=""
              ></vi-select>
            </b-form-group>
          </b-col>

          <!-- Remove Button -->
          <b-col lg="2" md="4" class="mb-50">
            <b-button
              v-ripple.400="'rgba(234, 84, 85, 0.15)'"
              variant="outline-danger"
              class="mt-0 mt-md-2"
              @click="removeItem(index)"
            >
              <!--<feather-icon icon="XIcon" class="mr-25" />-->
              <span>{{ $t('delete') }}</span>
            </b-button>
          </b-col>
        </b-row>
      </b-form>
    </div>
    <span>{{ $t('last_updated') + ':' }} {{ lastUpdated }}</span>
    <div class=" mb-1">
      <br />
      <b-button
        v-ripple.400="'rgba(255, 255, 255, 0.15)'"
        variant="primary"
        @click="repeateAgain"
      >
        <feather-icon icon="PlusIcon" class="mr-25" />
        <span>{{ $t('new_entry') }}</span>
      </b-button>
      <div class="float-right mb-2">
        <b-button @click="updateData" variant="primary">{{
          $t('save')
        }}</b-button>
        <b-button @click="refreshData" variant="outline-primary" class="ml-1">{{
          $t('refresh')
        }}</b-button>
      </div>
    </div>
  </b-card>
</template>

<script>
import {
  BForm,
  BFormGroup,
  BFormInput,
  BFormSelect,
  BRow,
  BCol,
  BButton,
  BCard,
} from 'bootstrap-vue';
import { heightTransition } from '@core/mixins/ui/transition';
import Ripple from 'vue-ripple-directive';
import ViSelect from '@/views/components/ViSelect.vue';
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue';

export default {
  components: {
    BForm,
    BRow,
    BCol,
    BButton,
    BFormGroup,
    BFormInput,
    BFormSelect,
    BCard,
    ViSelect,
  },
  directives: {
    Ripple,
  },
  mixins: [heightTransition],
  props: {
    table: {
      required: true,
    },
  },
  data() {
    return {
      deletedItems: [],
      items: [],
      allItems: [],
      nextTodoId: 2,
      lastUpdated: '',
      options: [],
      ledPages: [],
      colorOptions: [
        { label: 'Zelena', code: 1 },
        { label: 'Rdeča', code: 2 },
        { label: 'Zelena utripajoča', code: 17 },
        { label: 'Rdeča utripajoča', code: 18 },
      ],
    };
  },
  async mounted() {
    const response = await this.$store.dispatch('devices/GET_SPEED_TABLE', {
      deviceId: this.$route.params.id,
      table: this.table,
    });
    if (response.data) {
      this.options = response.data.map(s => {
        return { code: s.symbol, label: s.symbol };
      });
      if (this.options.length > 0) {
        this.lastUpdated = this.$moment(response.data[0].updatedAt)
          .local()
          .format('DD.MM.YYYY HH:mm');
      }
      this.allItems = JSON.parse(JSON.stringify(response.data));
      this.items = response.data.filter(
        s => !(s.speedFrom == 200 && s.speedTo == 200),
      );
    }
    this.$store
      .dispatch('pagetext/GET_PAGE_TEXT', {
        deviceId: this.$route.params.id,
      })
      .then(pages => {
        this.ledPages = pages.data;
      });
  },
  created() {
    window.addEventListener('resize', this.initTrHeight);
  },
  destroyed() {
    window.removeEventListener('resize', this.initTrHeight);
  },
  methods: {
    getLedPage(pageNumber, rowNum) {
      return this.ledPages.find(page => {
        return page.pageNumber == pageNumber && page.rowNumber == rowNum + 1;
      });
    },
    pageText(item, rowNum) {
      if (!item.symbol) return null;
      const pageNumber = item.symbol.replace('LED stran ', '');
      const ledPage = this.getLedPage(pageNumber, rowNum);
      return ledPage ? ledPage.rowText : '';
    },
    pageColor(item) {
      if (!item.symbol) return null;
      const pageNumber = item.symbol.replace('LED stran ', '');
      const ledPage = this.getLedPage(pageNumber, 1);

      return item && this.ledPages.length > 0 ? ledPage.color : '';
    },
    changePageText(item, rowNum, value) {
      if (!item.symbol) return;
      const pageNumber = item.symbol.replace('LED stran ', '');
      const ledPage = this.getLedPage(pageNumber, rowNum);
      ledPage.rowText = value;
    },
    changeColor(item, color) {
      if (!item.symbol) return;
      const pageNumber = item.symbol.replace('LED stran ', '');
      const ledPage = this.getLedPage(pageNumber, 1);
      ledPage.color = color;
    },
    valueChanged(item, index) {
      const findItem = this.allItems.find(st => st.symbol == item.symbol);
      if (findItem) {
        const t = JSON.parse(
          JSON.stringify(this.allItems.find(st => st.symbol == item.symbol)),
        );
        t.speedFrom = item.speedFrom;
        t.speedTo = item.speedTo;

        //add led pages
        const pageNumber = t.symbol.replace('LED stran ', '');
        const ledPages = this.ledPages.filter(
          lp => lp.pageNumber == pageNumber,
        );
        t.ledPages = ledPages;

        this.$set(this.items, index, JSON.parse(JSON.stringify(t)));
      }
    },
    async updateData() {
      this.$store
        .dispatch('devices/UPDATE_SPEED_TABLE', {
          id: this.$route.params.id,
          data: { items: this.items, deletedItems: this.deletedItems },
          table: this.table,
        })
        .then(async response => {
          if (response.data) {
            this.allItems = JSON.parse(JSON.stringify(response.data));
            this.items = response.data.filter(
              s => !(s.speedFrom == 200 && s.speedTo == 200),
            );
            this.deletedItems = [];
            //Update pageTexts
            this.ledPages.forEach(
              i => (i.numberOfChars = i.rowText.replaceAll("'", '').length),
            );
            await this.$store.dispatch('pagetext/UPDATE_PAGE_TEXT', {
              deviceId: this.$route.params.id,
              pages: this.ledPages,
            });
          }
          //this.initTrHeight();
          this.$toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: this.$t('success'),
              icon: 'CheckIcon',
              variant: 'success',
              text: this.$t('update_success_descirption'),
            },
          });
        })
        .catch(e => {
          this.$toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: this.$t('failed'),
              icon: 'AlertTriangleIcon',
              variant: 'danger',
              text: this.$t(e.response.data.message),
            },
          });
        });
    },
    async refreshData() {
      this.$store
        .dispatch('devices/REFRESH_SPEED_SYMBOLS', {
          deviceId: this.$route.params.id,
          table: this.table,
        })
        .then(async response => {
          const r = await this.$store.dispatch('pagetext/REFRESH_PAGE_TEXT', {
            deviceId: this.$route.params.id,
          });
          this.ledPages = r.data;

          this.options = response.data.map(s => {
            return { code: s.symbol, label: s.symbol };
          });
          if (this.options.length > 0) {
            this.lastUpdated = this.$moment(response.data[0].updatedAt)
              .local()
              .format('DD.MM.YYYY HH:mm');
          }
          this.allItems = JSON.parse(JSON.stringify(response.data));
          this.items = response.data.filter(
            s => !(s.speedFrom == 200 && s.speedTo == 200),
          );
          //this.initTrHeight();
          this.$toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: this.$t('success'),
              icon: 'CheckIcon',
              variant: 'success',
              text: this.$t('update_success_descirption'),
            },
          });
        })
        .catch(e => {
          console.log(e);
          this.$toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: this.$t('failed'),
              icon: 'AlertTriangleIcon',
              variant: 'danger',
              text: this.$t(e.response.data.message),
            },
          });
        });
    },
    repeateAgain() {
      this.items.push({});
    },
    removeItem(index) {
      this.deletedItems.push(this.items[index]);
      this.items.splice(index, 1);
    },
    initTrHeight() {},
  },
};
</script>

<style lang="scss" scoped>
.repeater-form {
  transition: 0.25s height;
}
</style>
