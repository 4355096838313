<template>
  <div>
    <b-row>
      <b-col md="6">
        <b-form-group label-cols="4" label-cols-lg="2" :label="$t('time')">
          <div class="d-flex align-items-center bordered">
            <flat-pickr
              v-model="settings.time"
              class="form-control border-0 bg-transparent"
              :config="{
                enableTime: true,
                noCalendar: true,
                dateFormat: 'H:i',
              }"
            />
            <feather-icon
              v-if="!isMobile()"
              class="mr-2"
              icon="ClockIcon"
              size="16"
            />
          </div>
        </b-form-group>
      </b-col>
      <b-col md="6">
        <b-form-group label-cols="4" label-cols-lg="2" :label="$t('date')">
          <div class="d-flex align-items-center bordered">
            <flat-pickr
              v-model="settings.date"
              class="form-control border-0 bg-transparent"
              :config="{ dateFormat: 'd.m.Y' }"
            />
            <feather-icon
              v-if="!isMobile()"
              class="mr-2"
              icon="CalendarIcon"
              size="16"
            />
          </div>
        </b-form-group>
      </b-col>
    </b-row>
    <div class="d-flex justify-content-between">
      <span>{{ $t('last_updated') }} {{ lastUpdated }}</span>
      <div>
        <b-button @click="updateData" variant="primary">{{
          $t('save')
        }}</b-button>
        <b-button @click="refreshData" variant="outline-primary" class="ml-1">{{
          $t('refresh')
        }}</b-button>
      </div>
    </div>
  </div>
</template>

<script>
import {
  BFormGroup,
  BFormInput,
  BButton,
  BFormSelect,
  BRow,
  BCol,
} from 'bootstrap-vue';
import useDeviceSetttings from './useDeviceSettings';
import flatPickr from 'vue-flatpickr-component';
import { isMobile } from '@/utils';

export default {
  props: {
    id: {
      type: String,
    },
  },
  components: {
    BFormGroup,
    BFormInput,
    BButton,
    BFormSelect,
    flatPickr,
    BRow,
    BCol,
  },
  setup(props, context) {
    const {
      lastUpdated,
      settings,
      updateData,
      refreshData,
    } = useDeviceSetttings(context, 'datetime_vehicle');

    return {
      lastUpdated,
      settings,
      updateData,
      refreshData,
      isMobile,
    };
  },
};
</script>

<style></style>
