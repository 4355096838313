import store from '@/store';
import moment from 'moment';

export const locationRedirect = (context, location) => {
  const path = context.root.$route.fullPath;

  switch (location.type) {
    case 1:
      if (path.includes('speeding'))
        return context.root.$route.fullPath.replace(
          context.root.$route.params.id,
          location.id,
        );
      return (
        'speeding/' + location.id + (location.read ? '/average' : '/settings')
      );
    case 2:
      if (path.includes('vehicle'))
        return context.root.$route.fullPath.replace(
          context.root.$route.params.id,
          location.id,
        );
      return (
        'vehicle/' + location.id + (location.read ? '/average' : '/settings')
      );
    case 3:
      if (path.includes('cyclist'))
        return context.root.$route.fullPath.replace(
          context.root.$route.params.id,
          location.id,
        );
      return 'cyclist/' + location.id + '/counter';
    case 4:
      if (path.includes('radar'))
        return context.root.$route.fullPath.replace(
          context.root.$route.params.id,
          location.id,
        );
      return 'radar/' + location.id + '/info';
  }
  return 'locations';
};

export const getLocationStatus = location => {
  let status = 3;
  if (location.type == 1) {
    const onlineDevice = store.state.devices.onlineDevices.find(
      device => device == location.location,
    );
    if (onlineDevice != null) {
      if (
        location.speedingVoltage < 12 ||
        location.speedingVoltage > 15 ||
        (location.speedingCreatedAt &&
          moment(location.speedingCreatedAt).diff(moment(), 'hours') < -24)
      )
        return 2;
      if (location.data24 == 0) return 2;
      else return 1;
    }
    return 3;
  } else if (location.type == 2) {
    const onlineDevice = store.state.devices.onlineDevices.find(
      device => device == location.location,
    );
    if (onlineDevice != null) {
      if (
        location.voltage < 12.2 ||
        location.voltage > 15 ||
        (location.vehicleCreatedAt &&
          moment(location.vehicleCreatedAt).diff(moment(), 'hours') < -24)
      )
        return 2;

      return 1;
    }
    return 3;
  } else if (location.type == 3) {
    const onlineDevice = store.state.devices.onlineDevices.find(
      device => device == location.location,
    );
    if (onlineDevice != null) {
      return 1;
    }
    return 3;
  } else if (location.type == 4) {
    if (
      location.created_at == null ||
      moment(location.created_at).diff(moment(), 'minutes') < -20
    ) {
      status = 5;
    } else if (
      location.lastAlarm?.vibrationActivated ||
      (location.alarms.vibro && !location.alarms.vibro.confirmed) ||
      location.lastAlarm?.doorOpened ||
      (location.alarms.door && !location.alarms.door.confirmed)
    )
      status = 3;
    else if (
      location.lastAlarm?.cameraState ||
      (location.alarms.camera && !location.alarms.camera.confirmed)
    )
      status = 5;
    else if (location.state != 'modeActive') status = 2;
    else status = 1;
  }
  return status;
};
