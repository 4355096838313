<template>
  <b-col sm="8" md="6" lg="12" class="px-xl-2 mx-auto">
    <b-card-title title-tag="h2" class="font-weight-bold mb-1">
      {{ $t('forgot.title') }}
    </b-card-title>
    <b-card-text class="mb-2">
      {{ $t('forgot.subtitle') }}
    </b-card-text>

    <!-- form -->
    <validation-observer ref="emailValidation">
      <b-form class="auth-login-form mt-2" @submit.prevent>
        <!-- new password -->
        <b-form-group>
          <div class="d-flex justify-content-between">
            <label>{{ $t('email') }}</label>
          </div>
          <validation-provider
            #default="{ errors }"
            name="new-password"
            rules="required|email"
            vid="confirm"
          >
            <b-input-group
              class="input-group-merge"
              :class="errors.length > 0 ? 'is-invalid' : null"
            >
              <b-form-input
                v-model="email"
                :state="errors.length > 0 ? false : null"
                class="form-control-merge"
                type="text"
              />
            </b-input-group>
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>

        <!-- submit buttons -->
        <b-button type="submit" variant="primary" block @click="confirm">
          {{ $t('forgot.confirm') }}
        </b-button>
        <b-card-text class="text-center mt-2">
          <b-link to="login">
            <span>&nbsp;{{ $t('login.login') }}</span>
          </b-link>
        </b-card-text>
      </b-form>
    </validation-observer>
  </b-col>
</template>

<script>
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue';
/* eslint-disable global-require */
import { ValidationProvider, ValidationObserver } from 'vee-validate';

import {
  BRow,
  BCol,
  BLink,
  BFormGroup,
  BFormInput,
  BInputGroupAppend,
  BInputGroup,
  BFormCheckbox,
  BCardText,
  BCardTitle,
  BImg,
  BForm,
  BButton,
} from 'bootstrap-vue';
import { required, email } from '@validations';
import { togglePasswordVisibility } from '@core/mixins/ui/forms';

export default {
  components: {
    BRow,
    BCol,
    BLink,
    BFormGroup,
    BFormInput,
    BInputGroupAppend,
    BInputGroup,
    BFormCheckbox,
    BCardText,
    BCardTitle,
    BImg,
    BForm,
    BButton,
    ValidationProvider,
    ValidationObserver,
  },
  mixins: [togglePasswordVisibility],
  data() {
    return {
      email: '',
      required,
    };
  },
  computed: {
    imgUrl() {
      return this.sideImg;
    },
  },
  methods: {
    async confirm() {
      const valid = await this.$refs.emailValidation.validate();
      if (valid) {
        let response = null;
        try {
          response = await this.$http
            .post('/auth/forgotPassword', {
              email: this.email,
            })
            .catch(error => {
              throw error;
            });
        } catch (error) {
          this.$toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: this.$t('error'),
              icon: 'AlertTriangleIcon',
              variant: 'danger',
              text: this.$t(error.response.data.message),
            },
          });
        }

        if (response) {
          this.$toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: `Ponastavitev gesla`,
              icon: 'UserCheckIcon',
              variant: 'success',
              text: `Na email naslov boste prejeli navodila za ponastavitev gesla.`,
            },
          });
          await this.$router.replace('/auth/login');
        }
      }
    },
  },
};
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-auth.scss';
</style>
