<template>
  <div>
    <h4 class="mb-2">{{ $t('settings.settings') }}</h4>
    <b-card>
      <b-row>
        <b-col md="12"
          ><app-collapse>
            <app-collapse-item
              :isVisible="true"
              :title="$t('settings.general_settings')"
            >
              <template slot="header"
                ><feather-icon icon="SettingsIcon" size="16" />
                <span class="font-weight-bold ml-1">{{
                  $t('settings.general_settings')
                }}</span></template
              >
              <general-settings
                :key="updateCount"
                :device="device"
              ></general-settings>
            </app-collapse-item>
            <app-collapse-item
              :isVisible="true"
              :title="$t('settings.geo_location')"
            >
              <template slot="header"
                ><feather-icon icon="MapPinIcon" size="16" />
                <span class="font-weight-bold ml-1">{{
                  $t('settings.geo_location')
                }}</span></template
              >
              <geo-settings :key="updateCount"></geo-settings>
            </app-collapse-item>
          </app-collapse>
        </b-col>
      </b-row>
    </b-card>
    <action-audit-log
      ref="actionAuditLog"
      :locationCode="device.location"
    ></action-audit-log>
  </div>
</template>

<script>
import {
  BFormGroup,
  BFormInput,
  BRow,
  BCol,
  BCard,
  BButton,
  BFormSelect,
  BTable,
} from 'bootstrap-vue';
import AppCollapse from '@core/components/app-collapse/AppCollapse.vue';
import AppCollapseItem from '@core/components/app-collapse/AppCollapseItem.vue';
import GeneralSettings from './GeneralSettings.vue';
import GeoSettings from './GeoSettings.vue';
import FtpCredentials from './FtpCredentials.vue';
import ActionAuditLog from '@/views/settings/general/ActionAuditLog.vue';
//import GeoSettings from './GeoSettings.vue';
export default {
  components: {
    BFormGroup,
    BFormInput,
    BRow,
    BCard,
    BCol,
    AppCollapse,
    AppCollapseItem,
    BButton,
    BFormSelect,
    GeneralSettings,

    BTable,
    GeoSettings,
    FtpCredentials,
    ActionAuditLog,
  },
  data() {
    return {
      updateCount: 0,
      device: {},
      items: [],
    };
  },
  async mounted() {
    this.loadData();
  },
  methods: {
    async loadData() {
      const response = await this.$store.dispatch(
        'devices/GET_DEVICE',
        this.$route.params.id,
      );
      if (response) {
        this.device = response.data;
        this.$nextTick(() => {
          this.$refs.actionAuditLog.refreshData();
        });
      }
    },
  },
  watch: {
    '$route.params.id': function (id) {
      //we need to force update all components
      this.loadData();
      this.updateCount++;
    },
  },
};
</script>

<style lang="scss">
.my-table {
  background: white;
}

.th-background {
  background-color: #e1f2fc !important;
}
</style>
