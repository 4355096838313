export default [
  {
    path: '/iframe/locations/:hash',
    name: 'iframe-locations',
    component: () => import('@/views/iframe/Locations.vue'),
    meta: {
      layout: 'full',
      resource: 'Device',
      redirectIfLoggedIn: false,
    },
  },
  {
    path: '/iframe/:hash/:deviceId',
    name: 'iframe',
    component: () => import('@/views/iframe/Iframe.vue'),
    meta: {
      layout: 'full',
      resource: 'Device',
      redirectIfLoggedIn: false,
    },
  },
];
