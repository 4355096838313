import { $GET, $PATCH, $POST, $DELETE } from './methods';

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    GET_GROUPS(state, payload) {
      return $GET(
        'groups' + (payload.companyId ? '?companyId=' + payload.companyId : ''),
      );
    },
    GET_GROUP(state, id) {
      return $GET('groups/' + id);
    },
    UPDATE_GROUP(state, payload) {
      return $PATCH('groups/' + payload.groupId, payload.group);
    },
    CREATE_GROUP(state, payload) {
      return $POST('groups', payload.group);
    },
    DELETE_GROUP(state, groupId) {
      return $DELETE('groups/' + groupId);
    },
    GET_GROUP_USERS(state, payload) {
      return $GET('groups/' + payload.groupId + '/users');
    },
    SAVE_USERS(state, payload) {
      return $POST('groups/' + payload.groupId + '/users', payload);
    },
  },
};
