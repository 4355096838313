import { formatOrStartOfMonthDate, formatOrEndOfMonthDate } from '@/utils';
import { $GET, $POST, $DELETE, $PATCH } from '../methods';
import { deviceType } from '@/types/deviceType';

export default {
  namespaced: true,
  state: {
    currentDevice: null,
    onlineDevices: [],
  },
  getters: {
    DEVICE_STATUS: (state, location) => {
      return state.onlineDevices.find(
        deviceLocation => deviceLocation == location,
      );
    },
  },
  mutations: {
    SET_DEVICE(state, device) {
      state.currentDevice = device;
    },
    UPDATE_ONLINE_DEVICES(state, devices) {
      // Add the given post to the 'posts' array in our state
      //Vue.set(state.onlineDevices, devices);
      state.onlineDevices = devices;
    },
  },
  actions: {
    GET_DEVICE(state, id) {
      return $GET('devices/' + id);
    },
    GET_CYCLIST_DEVICE(state, id) {
      return $GET('devices/' + id + '/cyclist');
    },
    GET_RADAR_INFO(state, id) {
      return $GET('devices/' + id + '/radar');
    },
    GET_AND_SET_DEVICE({ state, commit }, payload) {
      return $GET(
        'devices/' + payload.deviceId + '/abilities/' + payload.userId,
        response => commit('SET_DEVICE', response.data),
      );
    },
    GET_DEVICES_WITH_ABILITES(state, data) {
      const typeInt = data.type ? deviceType[data.type] : null;
      return $GET(
        'devices/' +
          data.id +
          '/abilities' +
          (typeInt ? '?type=' + typeInt : ''),
      );
    },
    SAVE_DEVICE_USER_ABILITES(state, data) {
      return $POST('devices/' + data.id + '/abilities', data.data);
    },
    GET_DEVICES_WITH_COMPANY_ABILITES(state, data) {
      const typeInt = data.type ? deviceType[data.type] : null;
      return $GET(
        'devices/companies/' +
          data.id +
          '/abilities' +
          (typeInt ? '?type=' + typeInt : ''),
      );
    },
    SAVE_DEVICE_COMPANY_ABILITES(state, data) {
      return $POST('devices/companies/' + data.id + '/abilities', data.data);
    },
    GET_DEVICES_WITH_GROUP_ABILITES(state, data) {
      const typeInt = data.type ? deviceType[data.type] : null;
      return $GET(
        'devices/groups/' +
          data.id +
          '/abilities' +
          (typeInt ? '?type=' + typeInt : ''),
      );
    },
    SAVE_DEVICE_GROUP_ABILITES(state, data) {
      return $POST('devices/groups/' + data.id + '/abilities', data.data);
    },
    GET_DEVICES(state, data) {
      const typeInt = data.type ? deviceType[data.type] : null;
      return $GET('devices' + (typeInt ? '?type=' + typeInt : ''));
    },
    GET_RADAR_DEVICES(state) {
      return $GET('devices/radar');
    },
    SAVE_DEVICE(state, data) {
      data.type = deviceType[data.type];
      return $POST('devices', data);
    },
    DELETE_DEVICE(state, id) {
      return $DELETE('devices/' + id);
    },
    GET_SETTINGS(state, data) {
      return $GET(
        'devices/' + data.deviceId + '/settings/' + data.settingsName,
      );
    },
    UPDATE_SETTINGS(state, data) {
      return $POST(
        'devices/' + data.deviceId + '/settings/' + data.userId,
        data.updateData,
      );
    },
    REFRESH_SETTINGS(state, data) {
      return $GET(
        'devices/' +
          data.deviceId +
          '/settings/' +
          data.settingsName +
          '/refresh/' +
          data.userId,
      );
    },
    GET_GPSHW(state, data) {
      return $GET(
        'devices/' + data.deviceId + '/settings/' + data.userId + '/gpshw',
      );
    },
    REBOOT(state, data) {
      return $GET(
        'devices/' +
          data.deviceId +
          '/settings/' +
          data.userId +
          '/reboot/' +
          data.type,
      );
    },
    GET_ACTIONS_HISTORY(state, id) {
      return $GET('devices/' + id + '/recent-history');
    },
    UPDATE(state, payload) {
      return $PATCH('devices/' + payload.id, payload);
    },
    async UPDATE_ONLINE_DEVICES({ commit }) {
      const response = await $GET('devices/online');
      if (response) commit('UPDATE_ONLINE_DEVICES', response.data);
    },
    GET_RECENT_ALARMS({ commit }) {
      return $GET('devicedata/recentalarms');
    },
    RUN_ALARM_UPDATE(state) {
      debugger;
      return $POST('devicedata/alarmupdate', {});
    },
    CONFIRM_RADAR_ALARM(state, data) {
      return $POST(
        'devices/alarm/' +
          data.id +
          '/' +
          data.alarmType +
          '/' +
          data.confirmedBy,
        null,
      );
    },
    UPDATE_SPEED_TABLE({ state }, data) {
      return $POST('devices/' + data.id + '/table/' + data.table, data.data);
    },
    GET_SPEED_TABLE({ state }, data) {
      return $GET('devices/' + data.deviceId + '/table/' + data.table);
    },
    REFRESH_SPEED_SYMBOLS({ state }, data) {
      return $GET(
        'devices/' + data.deviceId + '/table/' + data.table + '/refresh',
      );
    },
    UPDATE_CYCLIST_SETTINGS({ state }, data) {
      return $POST(
        'devices/' + data.deviceId + '/settings/' + data.userId + '/cyclist',
        data.data,
      );
    },
    GET_CYCLIST_SETTINGS({ state }, data) {
      return $GET(
        'devices/' + data.deviceId + '/settings/' + data.userId + '/cyclist',
      );
    },
    GET_CYCLIST_DIAGNOSTIC({ state }, data) {
      return $GET(
        'devices/' +
          data.deviceId +
          '/settings/cyclist/diagnostic' +
          (data.refresh ? '?refresh=' + data.refresh : ''),
      );
    },
    GET_CYCLIST_BRIGHTNESS({ state }, data) {
      return $GET(
        'devices/' +
          data.deviceId +
          '/settings/' +
          data.userId +
          '/cyclist/brightness',
      );
    },
    GET_RANGE({ state }, payload) {
      return $GET(
        'devices/' +
          payload.deviceId +
          '/settings/' +
          payload.userId +
          '/speeding/range',
      );
    },
    SET_RANGE({ state }, payload) {
      return $POST(
        'devices/' +
          payload.deviceId +
          '/settings/' +
          payload.userId +
          '/speeding/range',
        { range: payload.range },
      );
    },
    GET_ON_OFF_STATE({ state }, payload) {
      return $GET(
        'devices/' +
          payload.deviceId +
          '/settings/' +
          payload.userId +
          '/speeding/onoff',
      );
    },
    SET_ON_OFF_STATE({ state }, payload) {
      return $POST(
        'devices/' +
          payload.deviceId +
          '/settings/' +
          payload.userId +
          '/speeding/onoff',
        { state: payload.state },
      );
    },
    GET_IFRAMES(state, data) {
      const typeInt = data.type ? deviceType[data.type] : null;
      return $GET('iframe' + (typeInt ? '?type=' + typeInt : ''));
    },
    DELETE_IFRAME(state, id) {
      return $DELETE('iframe/' + id);
    },
    DELETE_IFRAME_DATA(state, id) {
      return $DELETE('iframe-data/' + id);
    },
    GET_IFRAMES_DATA(state, params) {
      return $GET('iframe-data/' + params.id);
    },
    GET_IFRAME(state, id) {
      return $GET('iframe/' + id);
    },
    async UPDATE_IFRAME(state, data) {
      await $PATCH('iframe/' + data.iframe.id, data.iframe);
      if (data.iframedata) {
        await data.iframedata.forEach(element => {
          if (element.id != null) {
            $PATCH('iframe-data/' + element.id, element);
          } else {
            $POST('iframe-data/', {
              type: element.type,
              duration: element.duration,
              history: element.history,
              order: element.order,
              iframeId: element.iframeId,
              deviceId: element.deviceId,
            });
          }
        });
      }
    },
    async ADD_IFRAME(state, data) {
      return $POST('iframe', data.iframe);
    },
    SEND_COMMAND({ state }, data) {
      return $POST('commands/' + data.deviceId + '/send', {
        command: data.command,
      });
    },
    IMAK_UPDATE({ state }, data) {
      return $PATCH('imak5/', data);
    },
    GET_VEHICLE_SETTINGS({ state }, { deviceId, data }) {
      return $POST('devices/' + deviceId + '/settings/vehicle/update', data);
    },
    UPDATE_VEHICLE_SETTINGS({ state }, { deviceId, data }) {
      return $PATCH('devices/' + deviceId + '/settings/vehicle', data);
    },
    GET_LAST_UPDATED_DATE({ state }, { deviceId, command }) {
      return $GET(
        'devices/' +
          deviceId +
          '/settings/last/updated' +
          (command ? '?command=' + command : ''),
      );
    },
    UPDATE_VEHICLE_CORRECTION_FACTOR({ state }, { deviceId, data }) {
      return $PATCH('devices/' + deviceId + '/settings/vehicle/layout', data);
    },
    REFRESH_VEHICLE_CORRECTION_FACTOR({ state }, { deviceId, data }) {
      return $PATCH(
        'devices/' + deviceId + '/settings/vehicle/layout/refresh',
        data,
      );
    },
    GET_DEVICE_VEHICLE({ state }, deviceId) {
      return $GET('devices/' + deviceId + '/settings/vehicle/layout');
    },
    GET_VEHICLE_DIAGNOSTIC({ state }, data) {
      return $GET(
        'devices/' +
          data.deviceId +
          '/settings/vehicle/diagnostic' +
          (data.refresh ? '?refresh=' + data.refresh : ''),
      );
    },
  },
};
