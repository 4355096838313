<template>
  <div>
    <b-row>
      <b-col md="6">
        <b-form-group
          label-cols="4"
          label-cols-lg="2"
          :label="$t('settings.device_id')"
        >
          <b-form-input disabled :value="settings.id" />
        </b-form-group>
        <b-form-group
          label-cols="4"
          label-cols-lg="2"
          :label="$t('cyclist.voltage')"
        >
          <b-form-input disabled :value="volt" />
        </b-form-group>
      </b-col>
      <b-col md="6">
        <b-form-group
          label-cols="4"
          label-cols-lg="2"
          :label="$t('cyclist.temperature')"
        >
          <b-form-input disabled :value="temp" />
        </b-form-group>
        <b-form-group
          label-cols="4"
          label-cols-lg="2"
          :label="$t('brightness')"
        >
          <b-form-input disabled :value="settings.brightness" />
        </b-form-group>
      </b-col>
    </b-row>
    <diagnostic-chart ref="diagnosticChart"></diagnostic-chart>
    <div class="float-right mb-1">
      <b-button @click="refresh" variant="outline-primary mr-1" class="ml-1">{{
        $t('refresh')
      }}</b-button>
      <b-button @click="reboot" variant="danger">{{ $t('reboot') }}</b-button>
    </div>
  </div>
</template>

<script>
import {
  BFormGroup,
  BFormInput,
  BButton,
  BFormSelect,
  BRow,
  BCol,
} from 'bootstrap-vue';
import store from '@/store';
import { useToast } from 'vue-toastification/composition';
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue';
import i18n from '@/libs/i18n';
import { ref, onMounted, computed } from '@vue/composition-api';
import DiagnosticChart from './DiagnosticChart.vue';
import { dangerToast, successToast } from '@/utils/toast';

export default {
  props: {
    id: {
      type: String,
    },
  },
  components: {
    BFormGroup,
    BFormInput,
    BButton,
    BFormSelect,
    BRow,
    BCol,
    DiagnosticChart,
  },
  setup(props, context) {
    const toast = useToast();

    const settings = ref({});
    const brightness = ref(null);

    const loadData = () => {
      store
        .dispatch('devices/GET_CYCLIST_DIAGNOSTIC', {
          deviceId: context.root.$route.params.id,
        })
        .then(response => {
          settings.value = response.data;
        });
    };

    const updateChart = () => {
      diagnosticChart.value.updateChart();
    };

    const diagnosticChart = ref();

    onMounted(() => {
      loadData();
    });

    const reboot = async () => {
      store
        .dispatch('devices/REBOOT', {
          deviceId: context.root.$route.params.id,
          userId: store.state.auth.userData.userId,
          type: 2,
        })
        .then(response => {
          toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: i18n.t('success'),
              icon: 'CheckIcon',
              variant: 'success',
              text: i18n.t('settings.reboot_in_progress'),
            },
          });
        })
        .catch(e => {
          toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: i18n.t('failed'),
              icon: 'AlertTriangleIcon',
              variant: 'danger',
              text: i18n.t(e.response.data.message),
            },
          });
        });
    };

    const refresh = async () => {
      store
        .dispatch('devices/GET_CYCLIST_DIAGNOSTIC', {
          deviceId: context.root.$route.params.id,
          refresh: true,
        })
        .then(response => {
          settings.value = response.data;
          successToast(
            toast,
            i18n.t('success'),
            i18n.t('update_success_descirption'),
          );
        })
        .catch(e => {
          dangerToast(toast, i18n.t('error'), i18n.t(e.response.data.message));
        });
    };

    const temp = computed(() => {
      return settings.value.temperature
        ? settings.value.temperature.toString().replace("'C", '') + '°C'
        : '';
    });

    const volt = computed(() => {
      return settings.value.voltage
        ? settings.value.voltage.toString().replace('V', '') + 'V'
        : '';
    });

    return {
      reboot,
      settings,
      updateChart,
      diagnosticChart,
      brightness,
      refresh,
      temp,
      volt,
    };
  },
};
</script>

<style></style>
