<template>
  <div>
    <content-header
      @dateChanged="dateChanged"
      datepicker
      nomargin
      vehicleDropdown
      @vehicleTypeChanged="vehicleTypeChanged"
      directionDropdown
      @directionChanged="directionChanged"
      @compareChanged="compareChanged"
      @compareDateChanged="compareDateChanged"
      :compare="compare"
    ></content-header>
    <b-row>
      <b-col md="12"
        ><bar-statistics :title="getBarLabel" :data="carStats"
      /></b-col>
    </b-row>
    <apex-line-chart
      :compare="compare"
      :option="graphDataFiltered"
      :title="$t('speed_percentiles')"
      nodatepicker
    ></apex-line-chart>
  </div>
</template>

<script>
import { BTable, BRow, BCol, BCard, BFormInput } from 'bootstrap-vue';
import ApexLineChart from '@/views/components/ApexLineChart.vue';
import BarStatistics from '@/views/components/BarStatistics';
import ContentHeader from '@/views/components/ContentHeader.vue';
import {
  formatDateFromAPI,
  getStartDateFromStore,
  getEndDateFromStore,
} from '@/utils';
import DynamicChart from '@/views/components/DynamicChart.vue';
import i18n from '@/libs/i18n';

export default {
  components: {
    BTable,
    BCard,
    BRow,
    BCol,
    ApexLineChart,
    BarStatistics,
    ContentHeader,
    BFormInput,
    DynamicChart,
  },
  mounted() {
    this.loadData();
  },
  data() {
    return {
      dateRange: [getStartDateFromStore(), getEndDateFromStore()],
      customPercentile: null,
      vCustom: null,
      dateStart: null,
      dateEnd: null,
      direction: null,
      vehicleType: null,
      barData: {},
      fields: [
        {
          label: '',
          key: 'text',
          sortable: false,
        },
        {
          label: '',
          key: 'number',
          sortable: false,
        },
      ],
      graphData: [],
      graphDataCustom: [],
      compare: false,
      compareDateRange: [getStartDateFromStore(), getEndDateFromStore()],
      compareStats: { graph: [] },
    };
  },
  methods: {
    dateChanged(dateRange) {
      const dates = dateRange.split('to');
      this.dateStart = dates[0];
      this.dateEnd = dates[1];
      this.dateRange = [dates[0], dates[1]];
      this.loadData(this.dateStart, this.dateEnd);
    },
    async loadData(dateFrom, dateTo) {
      const percentileData = await this.$store.dispatch(
        'stats/GET_PERCENTILE_DATA',
        {
          id: this.$route.params.id,
          dateFrom,
          dateTo,
          app: 'vehicle',
          direction: this.direction,
          vehicleType: this.vehicleType,
        },
      );
      this.barData = percentileData.data;
    },
    vehicleTypeChanged(vehicleType) {
      this.vehicleType = vehicleType;
      this.loadData();
      if (this.compare) this.compareDateChanged();
    },
    directionChanged(direction) {
      this.direction = direction;
      this.loadData();
      if (this.compare) this.compareDateChanged();
    },
    getSeries(graph, percentile, apppendNumber) {
      const appendix = this.compare ? ' (' + apppendNumber + ')' : '';
      return {
        name: 'v' + percentile + appendix,
        data:
          graph?.['v' + percentile]?.map(d => {
            return {
              x: d.dateTime,
              y: d.v,
            };
          }) ?? [],
      };
    },
    getBarStat(icon, percentile) {
      return {
        icon: icon,
        color: 'light-primary',
        title: (this.barData?.['v' + percentile] ?? '- ') + 'km/h',
        subtitle: this.$t('average_') + ' v' + percentile,
        customClass: 'mb-2 mb-xl-0',
      };
    },
    async compareChanged(state) {
      this.compare = state;
    },
    async compareDateChanged(dateRange) {
      if (dateRange) {
        const dates = dateRange.split('to');
        this.compareDateRange = [dates[0], dates[1]];
      }
      const percentileData = await this.$store.dispatch(
        'stats/GET_PERCENTILE_DATA',
        {
          id: this.$route.params.id,
          dateFrom: this.compareDateRange[0],
          dateTo: this.compareDateRange[1],
          app: 'vehicle',
          direction: this.direction,
          vehicleType: this.vehicleType,
        },
      );
      this.compareStats = percentileData.data;
    },
  },
  computed: {
    getBarLabel() {
      if (this.vehicleType) {
        const labels = [
          i18n.t('vehicle.single_track'),
          i18n.t('vehicle.car'),
          i18n.t('vehicle.combined'),
          i18n.t('vehicle.truck'),
          i18n.t('vehicle.truck_with_trailer'),
        ];
        return labels[this.vehicleType - 1];
      }
      return '';
    },
    graphDataFiltered() {
      let comparedSeries = [];
      let series = [
        this.getSeries(this.barData?.graph, 10, 1),
        this.getSeries(this.barData?.graph, 30, 1),
        this.getSeries(this.barData?.graph, 50, 1),
        this.getSeries(this.barData?.graph, 85, 1),
      ];
      if (this.compare) {
        comparedSeries = [
          this.getSeries(this.compareStats?.graph, 10, 2),
          this.getSeries(this.compareStats?.graph, 30, 2),
          this.getSeries(this.compareStats?.graph, 50, 2),
          this.getSeries(this.compareStats?.graph, 85, 2),
        ];
      }

      return {
        series: [...series, ...comparedSeries],
      };
    },
    carStats() {
      return [
        this.getBarStat('AlertCircleIcon', 10),
        this.getBarStat('AlertCircleIcon', 30),
        this.getBarStat('AlertCircleIcon', 50),
        this.getBarStat('AlertCircleIcon', 85),
      ];
    },
  },
  watch: {
    '$route.params.id': function(id) {
      this.loadData();
    },
  },
};
</script>

<style>
.hidden_header {
  display: none;
}
</style>
