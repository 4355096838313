export const isMobile = () => {
  if (
    /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
      navigator.userAgent,
    )
  ) {
    return true;
  } else {
    return false;
  }
};

export const toCapitalLetter = str => {
  return str.charAt(0).toUpperCase() + str.slice(1);
};

export const matchSeriesDates = (series1, series2) => {
  const len = Math.min(series1.length, series2.length);
  for (let i = 0; i < len; i++) {
    series2[i].x = series1[i].x;
  }
  return series2.slice(0, len);
};
