<template>
  <b-row>
    <b-col md="6">
      <content-header></content-header>
      <b-table
        :empty-text="$t('no_data')"
        class="my-table"
        responsive="sm"
        :items="infos"
        :fields="infoFields"
      >
      </b-table>
      <div>
        {{ $t('data-refreshed') }}:
        <span>{{
          device && device.lastDataDate
            ? this.$moment(device.lastDataDate).format('DD.MM.yyyy HH:mm')
            : '-'
        }}</span>
      </div>
    </b-col>
    <b-col md="6">
      <div class="mb-2" style="font-size: 18px">
        {{ $t('alarms') }}
      </div>
      <b-table
        :empty-text="$t('no_data')"
        class="my-table"
        responsive="sm"
        :items="alarms"
        :fields="alarmFields"
      >
        <template #cell(status)="data">
          <span :class="{ red: data.item.statusRaw }">
            {{ checkAlarmTime() ? data.item.status : '-' }}
          </span>
        </template>
        <template #cell(alarm)="data">
          <span :class="{ red: data.item.alarm && !data.item.alarm.confirmed }">
            {{ getAlarmsStatus(data.item.alarm) }}
          </span>
        </template>
        <template #cell(actions)="data">
          <span
            @click="confirmAlarm(data.item.alarm, data.item.type)"
            v-if="data.item.alarm && !data.item.alarm.confirmed"
            class="confirmAlarm"
          >
            {{ $t('confirm') }}
          </span>
        </template>
      </b-table>
      <div>
        {{ $t('data-refreshed') }}:
        <span>{{
          device && device.lastAlarm.created_at
            ? this.$moment(device.lastAlarm.created_at).format(
                'DD.MM.yyyy HH:mm',
              )
            : '-'
        }}</span>
      </div>
      <div v-if="isRemoteGUIActive">
        <div class="mb-2 mt-1" style="font-size: 18px">
          {{ $t('remote_access') }}
        </div>
        <b-table
          :empty-text="$t('no_data')"
          class="my-table"
          responsive="sm"
          :items="remote"
          :fields="remoteFields"
        >
          <template #cell(action)>
            <div class="primary font-weight-bolder">
              <span @click="copyText" style="cursor: pointer">{{
                $t('establish_connection')
              }}</span>
              <feather-icon
                icon="ArrowRightCircleIcon"
                size="20"
                style="margin-left: 5px"
              />
            </div>
          </template>
        </b-table>
      </div>
      <div class="mt-2" style="font-size: 18px">
        {{ $t('active_time') }}
      </div>
      <div style="justify-content: flex-end;display: flex;">
        <date-range-picker
          ref="dateRangePicker"
          id="dateRange"
          @date-changed="dateChanged"
        >
        </date-range-picker>
      </div>
      <b-table
        :empty-text="$t('no_data')"
        class="my-table"
        responsive="sm"
        :items="timeActive"
        :fields="activeTimeFields"
      >
        <template #cell(status)="data">
          <span :class="{ red: data.item.statusRaw }">
            {{ checkAlarmTime() ? data.item.status : '-' }}
          </span>
        </template>
        <template #cell(alarm)="data">
          <span :class="{ red: data.item.alarm && !data.item.alarm.confirmed }">
            {{ getAlarmsStatus(data.item.alarm) }}
          </span>
        </template>
        <template #cell(actions)="data">
          <span
            @click="confirmAlarm(data.item.alarm, data.item.type)"
            v-if="data.item.alarm && !data.item.alarm.confirmed"
            class="confirmAlarm"
          >
            {{ $t('confirm') }}
          </span>
        </template>
      </b-table>
    </b-col>
  </b-row>
</template>

<script>
const API_DATE_TIME_FORMAT = 'y-MM-DD HH:mm';
import {
  BRow,
  BCol,
  BTable,
  BCard,
  BDropdown,
  BDropdownItem,
} from 'bootstrap-vue';
import ContentHeader from '../../components/ContentHeader.vue';
import DateRangePicker from '../../components/DateRangePicker.vue';
import { dangerToast, successToast } from '@/utils/toast';
export default {
  components: {
    BRow,
    BCol,
    BTable,
    BCard,
    BDropdown,
    BDropdownItem,
    ContentHeader,
    DateRangePicker,
  },
  async mounted() {
    this.loadData();
    this.refreshInterval = setInterval(() => {
      this.loadData();
    }, 15000);
    this.getActiveTime();
  },
  beforeDestroy() {
    clearInterval(this.refreshInterval);
  },
  data() {
    return {
      dateFrom: null,
      dateTo: null,
      activeTime: null,
      remoteGUI: null,
      refreshInterval: null,
      device: null,
      remoteFields: [
        {
          label: this.$t('remote_control_to_device'),
          key: 'action',
          sortable: false,
        },
      ],
      remote: [{ action: 'Remote control' }],
      infoFields: [
        {
          label: this.$t('setting'),
          key: 'name',
          sortable: false,
        },
        {
          label: this.$t('value'),
          key: 'value',
          sortable: false,
        },
      ],
      alarmFields: [
        {
          label: '',
          key: 'activity',
          sortable: false,
        },
        {
          label: this.$t('status'),
          key: 'status',
          sortable: false,
        },
        {
          label: this.$t('alarm'),
          key: 'alarm',
          sortable: false,
        },
        {
          label: this.$t('actions'),
          key: 'actions',
          sortable: false,
        },
      ],
      activeTimeFields: [
        {
          label: this.$t('date_from'),
          key: 'dateFrom',
          sortable: false,
        },
        {
          label: this.$t('date_to'),
          key: 'dateTo',
          sortable: false,
        },
        {
          label: this.$t('active_time'),
          key: 'activeTime',
          sortable: false,
        },
      ],
    };
  },
  computed: {
    isRemoteGUIActive() {
      return (
        this.remoteGUI &&
        this.remoteGUI.username != null &&
        this.remoteGUI.username != ''
      );
    },
    timeActive() {
      const secondsToDaysHoursMinutes = seconds => {
        const days = Math.floor(seconds / (3600 * 24));
        seconds -= days * 3600 * 24;
        const hours = Math.floor(seconds / 3600);
        seconds -= hours * 3600;
        const minutes = Math.floor(seconds / 60);

        return { days, hours, minutes };
      };

      const rangePicker = this.$refs.dateRangePicker;
      const { days, hours, minutes } = this.activeTime
        ? secondsToDaysHoursMinutes(this.activeTime)
        : { days: 0, hours: 0, minutes: 0 };
      const getHourText = hours => {
        if (hours == 1) return this.$t('radar_info.hour');
        else if (hours == 2) return this.$t('radar_info.hours');
        else if (hours == 3 || hours == 4) return this.$t('radar_info.hours3');
        else return this.$t('radar_info.hours5');
      };

      const getMinutesText = minutes => {
        if (minutes == 1) return this.$t('radar_info.minute');
        else if (minutes == 2) this.$t('radar_info.minutes');
        else if (hours == 3 || hours == 4)
          return this.$t('radar_info.minutes3');
        else return this.$t('radar_info.minutes5');
      };

      return [
        {
          dateFrom: rangePicker
            ? this.$moment(rangePicker.dateRange.startDate).format('DD.MM.yyyy')
            : '',
          dateTo: rangePicker
            ? this.$moment(rangePicker.dateRange.endDate).format('DD.MM.yyyy')
            : '',
          activeTime: `${days} ${
            days != 1 ? this.$t('radar_info.days') : this.$t('radar_info.day')
          } ${hours} ${getHourText(hours)} ${minutes} ${getMinutesText(
            minutes,
          )}`,
        },
      ];
    },
    alarms() {
      return [
        {
          activity: this.$t('vibrations'),
          statusRaw: this.device?.lastAlarm.vibrationActivated,
          status:
            this.device?.lastAlarm.vibrationActivated == null
              ? this.$t('no-single-data')
              : this.device?.lastAlarm.vibrationActivated
              ? this.$t('activated')
              : this.$t('idle'),
          alarm: this.device?.alarms.vibro,
          type: 'vibro',
          actions: '',
        },
        {
          activity: this.$t('door'),
          statusRaw: this.device?.lastAlarm.doorOpened,
          status:
            this.device?.lastAlarm.doorOpened == null
              ? this.$t('no-single-data')
              : this.device?.lastAlarm.doorOpened
              ? this.$t('opened')
              : this.$t('closed'),
          alarm: this.device?.alarms.door,
          type: 'door',
          actions: '',
        },
        {
          activity: this.$t('camera'),
          statusRaw: this.device?.lastAlarm.cameraState,
          status:
            this.device?.lastAlarm.cameraState == null
              ? this.$t('no-single-data')
              : !this.device?.lastAlarm.cameraState
              ? this.$t('present')
              : this.$t('not_present'),
          alarm: this.device?.alarms.camera,
          type: 'camera',
          actions: '',
        },
      ];
    },
    infos() {
      return [
        { name: this.$t('generalSettings.location'), value: this.device?.name },
        { name: this.$t('location_code'), value: this.device?.location },
        {
          name: this.$t('device'),
          value: this.checkTime() ? this.device?.model : '',
        },
        {
          name: this.$t('serial_number'),
          value: this.checkTime() ? this.device?.serialNumber : '',
        },
        {
          name: this.$t('mac'),
          value: this.checkTime() ? this.device?.mac : '',
        },
        {
          name: this.$t('version'),
          value: this.checkTime() ? this.device?.firmware : '',
        },
        {
          name: this.$t('sensor_type'),
          value: this.checkTime() ? this.device?.sensorType : '',
        },
        {
          name: this.$t('cyclist.voltage'),
          value: this.checkTime()
            ? this.checkNull(this.device?.voltage, 'V')
            : '',
        },
        {
          name: this.$t('cyclist.temperature'),
          value: this.checkTime()
            ? this.checkNull(this.device?.temperature, ' °C')
            : '',
        },
        {
          name: this.$t('work_mode'),
          value: this.checkTime() ? this.$t(this.device?.state) : '',
        },
        {
          name: this.$t('sensor_sensitivity'),
          value: this.checkTime()
            ? this.sensitivityToString(this.device?.sensitivity)
            : '',
        },
        {
          name: this.$t('flash'),
          value:
            this.device?.flash == null || !this.checkTime()
              ? ''
              : this.device?.flash == '1'
              ? this.$t('powered_on')
              : this.$t('powered_off'),
        },
        {
          name: this.$t('detection_direction'),
          value: this.checkTime()
            ? this.directionToString(this.device?.direction)
            : '',
        },
        {
          name: this.$t('cars_limit'),
          value: this.checkTime()
            ? this.checkNull(this.device?.speedLimit, 'km/h')
            : '',
        },
        {
          name: this.$t('trucks_limit'),
          value: this.checkTime()
            ? this.checkNull(this.device?.truckSpeedLimit, 'km/h')
            : '',
        },
        {
          name: this.$t('cars_trigger_speed'),
          value: this.checkTime()
            ? this.checkNull(this.device?.carSpeedTrigger, 'km/h')
            : '',
        },
        {
          name: this.$t('trucks_trigger_speed'),
          value: this.checkTime()
            ? this.checkNull(this.device?.truckSpeedTrigger, 'km/h')
            : '',
        },
        { name: this.$t('generalSettings.ip_address'), value: this.device?.ip },
      ];
    },
  },
  methods: {
    dateChanged() {
      this.getActiveTime();
    },
    async getActiveTime() {
      const rangePicker = this.$refs.dateRangePicker;
      const activeTimeResponse = await this.$store.dispatch(
        'stats/GET_RADAR_ACTIVE_TIME',
        {
          deviceId: this.$route.params.id,
          dateFrom: this.$moment(rangePicker.dateRange.startDate).format(
            API_DATE_TIME_FORMAT,
          ),
          dateTo: this.$moment(rangePicker.dateRange.endDate).format(
            API_DATE_TIME_FORMAT,
          ),
        },
      );
      this.activeTime = activeTimeResponse.data;
    },
    checkTime() {
      return (
        this.$moment(this.device?.lastDataDate).diff(
          this.$moment(),
          'minutes',
        ) > -20
      );
    },
    checkAlarmTime() {
      return (
        this.$moment(this.device?.lastAlarm?.created_at).diff(
          this.$moment(),
          'minutes',
        ) > -20
      );
    },
    async loadData() {
      const response = await this.$store.dispatch(
        'devices/GET_RADAR_INFO',
        this.$route.params.id,
      );
      if (response) {
        this.device = response.data;
      }
      const u = await this.$store.dispatch(
        'users/GET_USER_REMOTEGUI',
        this.$store.state.auth.userData.userId,
      );
      this.remoteGUI = {
        username: u.data.remoteGUIUsername,
        password: u.data.remoteGUIPassword,
      };
    },
    copyText() {
      this.$copyText(
        `@#*$@"C:\\RemoteGUI\\bin\\RemoteGUI_${
          this.device.firmware
        }\\scgui.exe" -host=${
          this.device.ip_remote_gui ? this.device.ip_remote_gui : this.device.ip
        } -user=${this.remoteGUI.username} -pass=${
          this.remoteGUI.password
        } -input=1 -display=1`,
      ).then(
        e => {
          successToast(
            this.$toast,
            this.$t('run_external_program'),
            this.$t('run_external_program_succes'),
          );
          //audit action
          this.$store.dispatch('audit/SAVE_ACTION', {
            action: 1,
            locationCode: this.device.location,
          });
        },
        e => {
          dangerToast(
            this.$toast,
            this.$t('failed'),
            this.$t('run_external_program_failed'),
          );
        },
      );
    },
    async confirmAlarm(alarm, alarmType) {
      let response = await this.$store.dispatch('devices/CONFIRM_RADAR_ALARM', {
        id: alarm.id,
        alarmType,
        confirmedBy: this.$store.state.auth.userData.userId,
      });
      if (response && response.status == 201) {
        this.device.alarms[alarmType] = response.data;
        successToast(
          this.$toast,
          this.$t('success'),
          this.$t('alarm_confirmed'),
        );
      }
    },
    getAlarmsStatus(alarm) {
      if (alarm) {
        const date = alarm.confirmed
          ? this.$moment
              .utc(alarm.confirmed)
              .local()
              .format('DD.MM.yyyy HH:mm')
          : this.$moment(alarm.created_at).format('DD.MM.yyyy HH:mm');
        const confirmed = alarm.confirmed
          ? this.$t('confirmed_by') +
            ' ' +
            (alarm.confirmedBy ? alarm.confirmedBy : ' -')
          : this.$t('triggered');
        return date + ', ' + confirmed;
      }
      return this.$t('no-alarms');
    },
    directionToString(dir) {
      if (dir == '0') return this.$t('incoming_traffic');
      else if (dir == '1') return this.$t('two-way-traffic');
      else if (dir == '2') return this.$t('outgoing_traffic');
    },
    checkNull(value, appendix) {
      return value ? value + ' ' + appendix : '';
    },
    sensitivityToString() {
      let sensitivity = [this.$t('low'), this.$t('medium'), this.$t('high')];
      if (
        this.device &&
        this.device.sensitivity > -1 &&
        this.device.sensitivity < sensitivity.length
      )
        return sensitivity[this.device?.sensitivity];
      return '';
    },
  },
  watch: {
    '$route.params.id': function(id) {
      this.loadData();
      this.getActiveTime();
    },
  },
};
</script>

<style lang="scss">
.primary {
  color: $primary;
}
.red {
  color: red;
}
.confirmAlarm {
  cursor: pointer;
}
.confirmAlarm:hover {
  color: $primary;
}
</style>
