<template>
  <b-col sm="8" md="6" lg="12" class="px-xl-2 mx-auto">
    <b-card-title title-tag="h2" class="font-weight-bold mb-1">
      🔐 {{ appName() }}
    </b-card-title>
    <b-card-text class="mb-2">
      {{ $t('login.activation_description') }}:
      <b style="word-break: break-all">{{ uuid }}</b>
    </b-card-text>

    <!-- form -->
    <validation-observer ref="activationValidation">
      <b-form class="auth-login-form mt-2" @submit.prevent>
        <!-- new password -->
        <b-form-group>
          <div class="d-flex justify-content-between">
            <label for="activation-key">{{ $t('login.activation_key') }}</label>
          </div>
          <validation-provider
            #default="{ errors }"
            name="activation-key"
            rules="required"
            vid="confirm"
          >
            <b-input-group
              class="input-group-merge"
              :class="errors.length > 0 ? 'is-invalid' : null"
            >
              <b-form-input
                id="activation-key"
                v-model="activationKey"
                :state="errors.length > 0 ? false : null"
                class="form-control-merge"
                :type="passwordFieldType"
                name="login-password"
                placeholder="············"
              />
              <b-input-group-append is-text>
                <feather-icon
                  class="cursor-pointer"
                  :icon="passwordToggleIcon"
                  @click="togglePasswordVisibility"
                />
              </b-input-group-append>
            </b-input-group>
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>

        <!-- submit buttons -->
        <b-button type="submit" variant="primary" block @click="activate">
          {{ $t('login.activate') }}
        </b-button>
      </b-form>
    </validation-observer>
  </b-col>
</template>

<script>
/* eslint-disable global-require */
import { ValidationProvider, ValidationObserver } from 'vee-validate';
import { getAppName } from '@/utils/layout';

import {
  BRow,
  BCol,
  BLink,
  BFormGroup,
  BFormInput,
  BInputGroupAppend,
  BInputGroup,
  BFormCheckbox,
  BCardText,
  BCardTitle,
  BImg,
  BForm,
  BButton,
} from 'bootstrap-vue';
import { required, email } from '@validations';
import { togglePasswordVisibility } from '@core/mixins/ui/forms';
import { dangerToast, successToast } from '@/utils/toast';

export default {
  components: {
    BRow,
    BCol,
    BLink,
    BFormGroup,
    BFormInput,
    BInputGroupAppend,
    BInputGroup,
    BFormCheckbox,
    BCardText,
    BCardTitle,
    BImg,
    BForm,
    BButton,
    ValidationProvider,
    ValidationObserver,
  },
  mixins: [togglePasswordVisibility],
  data() {
    return {
      status: '',
      activationKey: '',
      uuid: '',
      userEmail: '',
      // validation rulesimport store from '@/store/index'
      required,
      email,
    };
  },
  async mounted() {
    const response = await this.$store.dispatch('auth/GET_APP_UID');
    if (response) this.uuid = response.data;
  },
  computed: {
    passwordToggleIcon() {
      return this.passwordFieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon';
    },
    imgUrl() {
      return this.sideImg;
    },
  },
  methods: {
    appName() {
      return getAppName();
    },
    async activate() {
      const valid = await this.$refs.activationValidation.validate();
      if (valid) {
        let response = null;
        try {
          response = await this.$store.dispatch('auth/ACTIVATE_APP', {
            key: this.activationKey,
          });
        } catch (error) {
          console.log(error);
          dangerToast(
            this.$toast,
            this.$t('error'),
            this.$t(error.response.data.message),
          );
        }
        if (response && response.data) {
          successToast(
            this.$toast,
            this.$t('success'),
            this.$t('login.activation_success'),
          );
          await this.$router.replace('/auth/login');
        } else {
          dangerToast(
            this.$toast,
            this.$t('error'),
            this.$t('login.wrong_activation_code'),
          );
        }
      }
    },
  },
};
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-auth.scss';
</style>
