<template>
  <b-card :style="{ height: height }">
    <l-map
      :key="mapRendered"
      :zoom="zoom"
      :center="center"
      :bounds="bounds"
      style="height: 100%"
    >
      <l-tile-layer :url="url" />
      <v-marker-cluster :options="clusterOptions">
        <!-- Create image icon (icon) from l-icon tag -->
        <l-marker
          v-for="location in locations"
          v-bind:key="location.id"
          :lat-lng="[location.latitude, location.longitude]"
          @click="markerClicked(location)"
          ref="marker"
        >
          <l-icon
            :className="locationBlinking(location)"
            :icon-size="[47, 47]"
            :icon-url="locationIcon(location)"
          >
          </l-icon>
          <l-tooltip
            className="zindexUp"
            :options="{ direction: 'top', offset: [0, -30] }"
          >
            {{ ' ' + location.name }}<br />
            <span v-if="location.type == 1 && !hideMarker">
              ID: {{ location.location }}<br />
              {{ $t('speeding.speed_limit') }}: {{ location.speedLimit }} <br />
              {{ $t('status') }}: {{ getStatusLabel(location) }}
            </span>
            <span v-if="location.type == 1 && hideMarker">
              {{ $t('speeding.speed_limit') }}: {{ location.speedLimit }}
            </span>
            <span v-if="location.type == 2">
              ID: {{ location.location }}<br />
              {{ $t('speeding.speed_limit') }}:
              {{ location.speedLimit ? location.speedLimit + 'km/h' : '-' }}
              <br />
              {{ $t('vehicle.last_speed') }}:
              {{ location.lastSpeed ? location.lastSpeed + 'km/h' : '-' }}
              <br />
              {{ $t('vehicle.distance_between_vehicles') }}:
              {{
                location.lastDistanceBetween
                  ? location.lastDistanceBetween + 's'
                  : '-'
              }}
              <br />
              {{ $t('vehicle.vehicle_count_in_last_hour') }}:
              {{ location.vehicleCount ? location.vehicleCount : '0' }}<br />
              {{ $t('status') }}: {{ getStatusLabel(location) }}
            </span>
            <span v-if="location.type == 3">
              ID: {{ location.location }}<br />
              {{ $t('cyclist.cyclist_per_day') }}:
              {{ counter(location.counterValue, true) }}<br />
              {{ $t('cyclist.cyclist_per_year') }}:
              {{ counter(location.counterValue, false) }}<br />
              {{ $t('cyclist.temperature') }}:
              {{ (location.temperature ? location.temperature : '-') + '°C' }}
            </span>
            <span
              v-html="tooltipText(location)"
              v-if="location.type == 4 && !hideMarker"
            >
            </span>
          </l-tooltip>
        </l-marker>
      </v-marker-cluster>
    </l-map>
    <b-button class="refresh-button" variant="light" @click="refreshMap">
      {{ $t('reset') }}</b-button
    >
  </b-card>
</template>

<script>
import { BImg, BButton, BCard } from 'bootstrap-vue';
import {
  LMap,
  LTileLayer,
  LCircle,
  LMarker,
  LPopup,
  LIcon,
  LTooltip,
} from 'vue2-leaflet';
import 'leaflet/dist/leaflet.css';
import { Icon } from 'leaflet';
import Vue2LeafletMarkerCluster from 'vue2-leaflet-markercluster';
import router from '@/router';
import { getLocationStatus } from '@/utils';
import { ref, computed, onMounted } from '@vue/composition-api';
import { latLngBounds, latLng } from 'leaflet';
import Vue from 'vue';
import { locationRedirect } from '@/utils';

delete Icon.Default.prototype._getIconUrl;
Icon.Default.mergeOptions({
  iconRetinaUrl: require('leaflet/dist/images/marker-icon-2x.png'),
  iconUrl: require('leaflet/dist/images/marker-icon.png'),
  shadowUrl: require('leaflet/dist/images/marker-shadow.png'),
});

export default {
  components: {
    BButton,
    LMap,
    LTileLayer,
    LCircle,
    BCard,
    LMarker,
    LPopup,
    LIcon,
    BImg,
    LTooltip,
    'v-marker-cluster': Vue2LeafletMarkerCluster,
  },
  props: {
    locations: {
      type: Array,
      default: [],
    },
    height: {
      type: String,
      default: '90vh',
    },
    zoomedIn: {
      type: Boolean,
      default: false,
    },
    hashURL: String,
    hideMarker: Boolean,
  },
  setup(props, context) {
    const markerClicked = location => {
      if (props.hashURL) {
        router.push('/iframe/' + props.hashURL + '/' + location.id);
      } else {
        switch (location.type) {
          case 1:
            router.push(
              '/speeding/' +
                location.id +
                (location.read ? '/average' : '/settings'),
            );
            break;
          case 2:
            router.push('/vehicle/' + location.id + '/average');
            break;
          case 3:
            router.push('/cyclist/' + location.id + '/counter');
            break;
          case 4:
            router.push('/radar/' + location.id + '/info');
            break;
        }
      }
    };

    const zoomedIn = ref(false);
    const zoom = ref(9);
    const center = ref([46.053493, 14.510786]);
    const mapRendered = ref(0);

    const marker = ref(null);

    const forceUpdate = () => {
      mapRendered.value++;
    };

    const zoomIn = location => {
      if (location.latitude && location.longitude) {
        zoomedIn.value = true;
        center.value = [location.latitude, location.longitude];
        zoom.value = 17;
        forceUpdate();

        Vue.nextTick(() => {
          marker.value.forEach(m => {
            if (m.$vnode.key == location.id) m.mapObject.openTooltip();
          });
        });
      } else {
        //if location doesnt have coordinates redirect
        context.root.$router.push(locationRedirect(context, location));
      }
    };

    const bounds = computed(() => {
      let bound = latLngBounds(
        props.locations.map(l => [l.latitude, l.longitude]),
      );
      if (bound.isValid()) bound = bound.pad(0.05);

      return zoomedIn.value == false ? bound : null;
    });

    onMounted(() => {
      context.root.$eventBus.$on('alarm-triggered', alarm => {
        zoomIn({
          id: alarm.device_id,
          latitude: alarm.device_latitude,
          longitude: alarm.device_longitude,
          type: alarm.device_type,
        });
      });
    });

    return {
      markerClicked,
      bounds,
      zoom,
      center,
      mapRendered,
      marker,
      zoomIn,
      forceUpdate,
    };
  },
  data() {
    return {
      url: 'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png',
      clusterOptions: {
        spiderLegPolylineOptions: { weight: 1.5, color: '#111', opacity: 1 },
      },
      icons: [
        [
          require('@/assets/images/icons/map/speed_green.svg'),
          require('@/assets/images/icons/map/speed_orange.svg'),
          require('@/assets/images/icons/map/speed_red.svg'),
          require('@/assets/images/icons/map/speed_yellow.png'),
        ],
        [
          require('@/assets/images/icons/map/vehicle_green.svg'),
          require('@/assets/images/icons/map/vehicle_orange.svg'),
          require('@/assets/images/icons/map/vehicle_red.svg'),
        ],
        [
          require('@/assets/images/icons/map/cyclist_green.svg'),
          require('@/assets/images/icons/map/cyclist_orange.svg'),
          require('@/assets/images/icons/map/cyclist_red.svg'),
        ],
        [
          require('@/assets/images/icons/map/radar_green.svg'),
          require('@/assets/images/icons/map/radar_orange.svg'),
          require('@/assets/images/icons/map/radar_red.svg'),
          require('@/assets/images/icons/map/radar_yellow.svg'),
          require('@/assets/images/icons/map/radar_blue.svg'),
        ],
      ],
    };
  },
  methods: {
    checkTime(location) {
      return (
        this.$moment(location.radar_period_dateTime).diff(
          this.$moment(),
          'minutes',
        ) > -20
      );
    },
    checkAlarmTime(location) {
      return location?.lastAlarm?.created_at
        ? this.$moment(location?.lastAlarm?.created_at).diff(
            this.$moment(),
            'minutes',
          ) > -20
        : false;
    },
    counter(counterValue, daily) {
      const counters = counterValue.split(',');
      if (counters.length > 1) {
        return counters[daily ? 0 : 1];
      }
      return null;
    },

    refreshMap() {
      this.zoom = 9;
      this.center = [46.053493, 14.510786];
      this.forceUpdate();
    },
    tooltipText(location) {
      return `${this.$t('data-refreshed')}: ${this.$moment(
        location.alarm_created_at,
      ).format('DD.MM.yyyy HH:mm')}<br>
          ${this.$t('location_code')}: ${location.location}<br>
           ${this.$t('title')}: ${location.name}<br>
         ${this.$t('settings.serial_num')}: ${
        this.checkTime(location) ? location.serialNumber : ''
      }<br>
          ${this.$t('ov_limit_trigger')}: ${
        this.checkTime(location) ? location.speedLimit : ''
      }/${this.checkTime(location) ? (location.carSpeedTrigger ? location.carSpeedTrigger : "-") : ''} <br>
          ${this.$t('tv_limit_trigger')}: ${
        this.checkTime(location) ? location.truckSpeedLimit : ''
      }/${this.checkTime(location) ? (location.truckSpeedTrigger ? location.truckSpeedTrigger : "-") : ''} <br>
          <span class="${
            location.lastAlarm?.vibrationActivated ||
            (location.alarms.vibro && !location.alarms.vibro.confirmed)
              ? 'red'
              : ''
          }">${this.$t('vibrations')}: ${
        this.checkAlarmTime(location)
          ? location.lastAlarm?.vibrationActivated
            ? this.$t('activated')
            : this.$t('idle')
          : '-'
      } </span><br>
          <span class="${
            location.lastAlarm?.doorOpened ||
            (location.alarms.door && !location.alarms.door.confirmed)
              ? 'red'
              : ''
          }">${this.$t('doors')}: ${
        this.checkAlarmTime(location)
          ? location.lastAlarm?.doorOpened
            ? this.$t('opened')
            : this.$t('closed')
          : '-'
      }</span> <br>
          <span class="${
            location.lastAlarm?.cameraState ? 'red' : ''
          }">${this.$t('camera')}: ${
        this.checkAlarmTime(location)
          ? !location.lastAlarm?.cameraState
            ? this.$t('present')
            : this.$t('not_present')
          : '-'
      }</span><br> `;
    },
    locationIcon(location) {
      let status = getLocationStatus(location);
      return this.icons[location.type - 1][status - 1];
    },
    locationBlinking(location) {
      let status = getLocationStatus(location);
      return status == 3 && location.type == 4 ? 'blinking' : '';
    },
    getStatusLabel(location) {
      const status = getLocationStatus(location);
      if (status == 1) {
        return this.$t('active');
      } else if (status == 2) {
        if (location.type == 1) {
          if (
            this.$moment(location.speedingCreatedAt).diff(
              this.$moment(),
              'hours',
            ) < -24
          )
            return this.$t('no-data-transfer');
          else if (location.speedingVoltage < 12.2)
            return this.$t('accumulator-below-12');
          else if (location.speedingVoltage > 15)
            return this.$t('accumulator-above-12');
        } else if (location.type == 2) {
          //vehicle status
          if (
            this.$moment(location.vehicleCreatedAt).diff(
              this.$moment(),
              'hours',
            ) < -24
          )
            return this.$t('no-data-transfer');
          else if (location.voltage && location.voltage < 12.2)
            return this.$t('accumulator-below-12');
          else if (location.voltage && location.voltage > 15)
            return this.$t('accumulator-above-12');
        }
      } else if (status == 3 || status == 4) {
        return this.$t('no-traffic-data-transfer');
      } else {
        return this.$t('no-communication');
      }
    },
  },
};
</script>

<style lang="scss">
@import '~leaflet.markercluster/dist/MarkerCluster.css';
@import '~leaflet.markercluster/dist/MarkerCluster.Default.css';
.vue2leaflet-map {
  &.leaflet-container {
    height: 700px;
  }
}

.marker-cluster-small {
  background-color: #2c98d4 !important;
}

.marker-cluster-small div {
  background-color: #087bb9 !important;
  color: #fff !important;
}
.red {
  color: red;
}
.refresh-button {
  position: absolute;
  right: 30px;
  top: 30px;
  z-index: 800;
}

.leaflet-top {
  z-index: 800 !important;
}

@keyframes fade {
  from {
    opacity: 0.8;
  }
  to {
    opacity: 1;
    width: 55px;
    height: 55px;
  }
}

.blinking {
  animation: fade 0.8s infinite alternate;
}
</style>
