<template>
  <div id="app" class="h-100" :class="[skinClasses]">
    <component :is="layout">
      <router-view />
    </component>
  </div>
</template>

<script>
// This will be populated in `beforeCreate` hook
import { $themeColors, $themeBreakpoints, $themeConfig } from '@themeConfig';
import { provideToast } from 'vue-toastification/composition';
import { onMounted, watch } from '@vue/composition-api';
import useAppConfig from '@core/app-config/useAppConfig';
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue';
import { useToast } from 'vue-toastification/composition';

import { useWindowSize, useCssVar } from '@vueuse/core';

import store from '@/store';
import useJwt from '@/auth/jwt/useJwt';
import eventsource from 'eventsource';

const LayoutVertical = () => import('@/layouts/vertical/LayoutVertical.vue');
const LayoutHorizontal = () =>
  import('@/layouts/horizontal/LayoutHorizontal.vue');
const LayoutFull = () => import('@/layouts/full/LayoutFull.vue');
import { getAppName } from '@/utils/layout';
import i18n from '@/libs/i18n';

export default {
  components: {
    // Layouts
    LayoutHorizontal,
    LayoutVertical,
    LayoutFull,
  },
  // ! We can move this computed: layout & contentLayoutType once we get to use Vue 3
  // Currently, router.currentRoute is not reactive and doesn't trigger any change
  computed: {
    layout() {
      if (this.$route.meta.layout === 'full') return 'layout-full';
      return `layout-${this.contentLayoutType}`;
    },
    contentLayoutType() {
      return this.$store.state.appConfig.layout.type;
    },
  },
  beforeCreate() {
    // Set colors in theme
    const colors = [
      'primary',
      'secondary',
      'success',
      'info',
      'warning',
      'danger',
      'light',
      'dark',
    ];

    // eslint-disable-next-line no-plusplus
    for (let i = 0, len = colors.length; i < len; i++) {
      $themeColors[colors[i]] = useCssVar(
        `--${colors[i]}`,
        document.documentElement,
      ).value.trim();
    }

    // Set Theme Breakpoints
    const breakpoints = ['xs', 'sm', 'md', 'lg', 'xl'];

    // eslint-disable-next-line no-plusplus
    for (let i = 0, len = breakpoints.length; i < len; i++) {
      $themeBreakpoints[breakpoints[i]] = Number(
        useCssVar(
          `--breakpoint-${breakpoints[i]}`,
          document.documentElement,
        ).value.slice(0, -2),
      );
    }

    // Set RTL
    const { isRTL } = $themeConfig.layout;
    document.documentElement.setAttribute('dir', isRTL ? 'rtl' : 'ltr');
  },
  setup(props, context) {
    const { skin, skinClasses } = useAppConfig();

    // If skin is dark when initialized => Add class to body
    if (skin.value === 'dark') document.body.classList.add('dark-layout');

    // Provide toast for Composition API usage
    // This for those apps/components which uses composition API
    // Demos will still use Options API for ease
    provideToast({
      hideProgressBar: true,
      closeOnClick: false,
      closeButton: false,
      icon: false,
      timeout: 10000,
      transition: 'Vue-Toastification__fade',
    });

    // Set Window Width in store
    store.commit('app/UPDATE_WINDOW_WIDTH', window.innerWidth);
    const { width: windowWidth } = useWindowSize();
    watch(windowWidth, (val) => {
      store.commit('app/UPDATE_WINDOW_WIDTH', val);
    });

    const toast = useToast();

    onMounted(() => {
      if (store.state.auth.userData) {
        i18n.locale = store.state.auth.userData.lang;
      }
      //Get layout
      store.dispatch('appConfig/IS_LOCAL_LAYOUT').then(() => {
        document.title = getAppName();
      });
      //Update status of devices every 10 sec
      if (store.state.auth.userData)
        store.dispatch('devices/UPDATE_ONLINE_DEVICES');
      setInterval(() => {
        if (store.state.auth.userData)
          store.dispatch('devices/UPDATE_ONLINE_DEVICES');
      }, 10000);

      store.dispatch('auth/GET_AND_SAVE_COMPANY_IN_STORE');

      //event source for triggered alarms
      const eventSource = new eventsource(
        process.env.NODE_ENV == 'development'
          ? 'http://localhost:3010/api/devicedata/sse'
          : window.location.origin + '/api/devicedata/sse',
        {
          headers: {
            Authorization: 'Bearer ' + useJwt.getToken(),
          },
        },
      );

      eventSource.onmessage = ({ data }) => {
        if (store.state.auth.userData) {
          const jsonData = JSON.parse(data);

          context.root.$eventBus.$emit('alarm-triggered', jsonData.device);

          //trigger sound
          new Audio(require('@/assets/sounds/alarm.wav')).play();
          toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: 'Alarm',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
              text:
                'Sprožen alarm na napravi z lokacijsko kodo ' +
                jsonData.alarm.locationCode,
            },
          });
        }
      };
    });

    return {
      skinClasses,
    };
  },
};
</script>
<style>
.atlwdg-trigger {
  color: white !important;
  background: red !important;
  z-index: 9999999;
}
</style>
