<template>
  <div>
    <content-header
      @dateChanged="dateChanged"
      datepicker
      directionDropdown
      @directionChanged="directionChanged"
      v-if="hideHeader == false"
      @compareChanged="compareChanged"
      @compareDateChanged="compareDateChanged"
      :compare="compare"
    ></content-header>
    <bar-statistics
      :style="{ border: compare ? '1px solid #0391e5' : 'none' }"
      :data="stats(barData.barStats)"
    ></bar-statistics>
    <bar-statistics
      :style="{ border: compare ? '1px solid red' : 'none' }"
      v-if="compare"
      :data="stats(compareStats.barStats)"
    />
    <dynamic-chart
      :title="$t('number_of_vehicle_approximately')"
      subtitle="test"
      :lineChartData="lineChartData"
      :barChartData="barChartData"
      :graph="barData.graph"
      :dateRange="dateRange"
    ></dynamic-chart>
  </div>
</template>

<script>
import { BTable, BRow, BCol, BCard } from 'bootstrap-vue';
import ApexLineChart from '@/views/components/ApexLineChart.vue';
import BarStatistics from '@/views/components/BarStatistics';
import {
  formatDateFromAPI,
  getStartDateFromStore,
  getEndDateFromStore,
  matchSeriesDates,
} from '@/utils';
import ContentHeader from '@/views/components/ContentHeader.vue';
import EChartBar from '@/views/components/EChartBar.vue';
import DynamicChart from '../../components/DynamicChart.vue';

export default {
  components: {
    BTable,
    BCard,
    BRow,
    BCol,
    ApexLineChart,
    BarStatistics,
    ContentHeader,
    EChartBar,
    DynamicChart,
  },
  async mounted() {
    this.fetchData();
  },
  data() {
    return {
      direction: null,
      dateRange: [getStartDateFromStore(), getEndDateFromStore()],
      graphData: [],
      barData: {
        maxByGroup: null,
        sum: null,
        PLDP: null,
      },
      compare: false,
      compareDateRange: [getStartDateFromStore(), getEndDateFromStore()],
      compareStats: {},
    };
  },
  props: {
    id: Number,
    dateFrom: String,
    dateTo: String,
    hideHeader: Boolean,
  },
  methods: {
    getDateByDataGrouping(date) {
      if (date) {
        const daysDiff = this.$moment(
          this.dateRange[1],
          'DD.MM.YYYY HH:mm',
        ).diff(this.$moment(this.dateRange[0], 'DD.MM.YYYY HH:mm'), 'days');

        const m = this.$moment(date, 'DD.MM.YYYY HH:mm');

        if (daysDiff < 7) {
          return m.format('DD.MM.YYYY HH:mm');
        } else if (daysDiff < 30) {
          return m.format('DD.MM.YYYY');
        } else if (daysDiff < 180) {
          return m.format('ww') + ' ' + this.$t('week');
        } else {
          return m.format('MM') + ' ' + this.$t('month');
        }
      }
      return '';
    },
    async fetchData() {
      if (this.dateFrom != null && this.dateTo != null) {
        this.dateRange = [this.dateFrom, this.dateTo];
      }

      const counterData = await this.$store.dispatch('stats/GET_COUNTER_DATA', {
        id: this.id != null ? this.id : this.$route.params.id,
        dateFrom: this.dateFrom != null ? this.dateFrom : this.dateRange[0],
        dateTo: this.dateTo != null ? this.dateTo : this.dateRange[1],
        app: 'vehicle',
        direction: this.direction,
      });

      console.log(counterData.data);
      if (counterData) this.barData = counterData.data;
      if (this.compare) this.compareDateChanged();
    },
    dateChanged(dateRange) {
      if (dateRange) {
        const dates = dateRange.split(' to ');
        this.dateRange = dates;
        this.fetchData();
      }
    },
    directionChanged(direction) {
      this.direction = direction;
      this.fetchData();
    },
    async compareDateChanged(dateRange) {
      if (dateRange) {
        const dates = dateRange.split(' to ');
        this.compareDateRange = [dates[0], dates[1]];
      }
      const counterData = await this.$store.dispatch('stats/GET_COUNTER_DATA', {
        id: this.id != null ? this.id : this.$route.params.id,
        dateFrom: this.compareDateRange[0],
        dateTo: this.compareDateRange[1],
        app: 'vehicle',
        direction: this.direction,
        //we nedd these two parameters so backend can properly group dates together
        orgDateFrom: this.dateRange[0],
        orgDateTo: this.dateRange[1],
      });
      if (counterData) this.compareStats = counterData.data;
    },
    async compareChanged(state) {
      this.compare = state;
    },
    stats(barData) {
      return [
        {
          icon: 'BarChartIcon',
          color: 'light-primary',
          title: barData?.sum ?? '0',
          subtitle: this.$t('number_of_vehicle'),
          customClass: 'mb-4 mb-xl-0',
        },
        {
          icon: 'ActivityIcon',
          color: 'light-info',
          title: barData?.maxByGroup ?? 0,
          subtitle: this.$t('max_vehicle_num'),
          customClass: 'mb-4 mb-xl-0',
          datetime: null /*this.getDateByDataGrouping(
            formatDateFromAPI(barData.counter?.max?.date),
          )*/,
        },
        {
          icon: 'MinusIcon',
          color: 'light-success',
          title: Math.round(barData?.PLDP) ?? '-',
          subtitle: 'PLDP',
          customClass: '',
        },
      ];
    },
    getGraphSeries(graphData, translation_key, vehicleType, appendNum) {
      return {
        name: this.$t(translation_key) + (this.compare ? ' ' + appendNum : ''),
        data:
          graphData
            ?.filter(d => d.vehicleType == vehicleType)
            .map(d => {
              return {
                x: d.dateTime,
                y: d.countAll,
              };
            }) ?? [],
      };
    },
    getBarSeries(graphData, translation_key, vehicleType, appendNum) {
      return {
        name: this.$t(translation_key) + (this.compare ? ' ' + appendNum : ''),
        data:
          graphData
            ?.filter(d => d.vehicleType == vehicleType)
            .map(item => item.countAll) ?? [],
      };
    },
    getCompareGraph(series, translation_key, vehicleType) {
      let compareGraph =
        this.compareStats?.graph
          ?.filter(d => d.vehicleType == vehicleType)
          .map(d => {
            return {
              x: d.dateTime,
              y: d.countAll,
            };
          }) ?? [];

      if (compareGraph && compareGraph.length > 0) {
        compareGraph = matchSeriesDates(series.data, compareGraph);
      }

      return {
        name: this.$t(translation_key) + ' 2',
        data: compareGraph,
      };
    },
  },
  computed: {
    barChartData() {
      let data = [
        this.getBarSeries(this.barData?.graph, 'vehicle.single_track', 1, 1),
        this.getBarSeries(this.barData?.graph, 'vehicle.car', 2, 1),
        this.getBarSeries(this.barData?.graph, 'vehicle.combined', 3, 1),
        this.getBarSeries(this.barData?.graph, 'vehicle.truck', 4, 1),
        this.getBarSeries(
          this.barData?.graph,
          'vehicle.truck_with_trailer',
          5,
          1,
        ),
      ];

      if (this.compare && this.compareStats) {
        data = [
          ...data,
          this.getBarSeries(
            this.compareStats?.graph,
            'vehicle.single_track',
            1,
            2,
          ),
          this.getBarSeries(this.compareStats?.graph, 'vehicle.car', 2, 2),
          this.getBarSeries(this.compareStats?.graph, 'vehicle.combined', 3, 2),
          this.getBarSeries(this.compareStats?.graph, 'vehicle.truck', 4, 2),
          this.getBarSeries(
            this.compareStats?.graph,
            'vehicle.truck_with_trailer',
            5,
            2,
          ),
        ];
      }
      return data;
    },
    lineChartData() {
      let series = [
        this.getGraphSeries(this.barData?.graph, 'vehicle.single_track', 1, 1),
        this.getGraphSeries(this.barData?.graph, 'vehicle.car', 2, 1),
        this.getGraphSeries(this.barData?.graph, 'vehicle.combined', 3, 1),
        this.getGraphSeries(this.barData?.graph, 'vehicle.truck', 4, 1),
        this.getGraphSeries(
          this.barData?.graph,
          'vehicle.truck_with_trailer',
          5,
          1,
        ),
      ];

      if (this.compare) {
        series = [
          ...series,
          this.getCompareGraph(series[0], 'vehicle.single_track', 1),
          this.getCompareGraph(series[0], 'vehicle.car', 2),
          this.getCompareGraph(series[0], 'vehicle.combined', 3),
          this.getCompareGraph(series[0], 'vehicle.truck', 4),
          this.getCompareGraph(series[0], 'vehicle.truck_with_trailer', 5),
        ];
      }
      return {
        series,
      };
    },
  },
  watch: {
    '$route.params.id': function(id) {
      this.fetchData();
    },
  },
};
</script>

<style>
.hidden_header {
  display: none;
}
</style>
