<template>
  <div>
    <div class="d-flex justify-content-end">
      <date-range-picker @date-changed="dateChanged" />
    </div>
    <apex-line-chart
      :option="graphDataFiltered"
      :title="''"
      :subtitle="''"
      nodatepicker
      :key="updateChartCount"
      :decimalNumbers="2"
      :min="min"
      :max="max"
      :brightnessPercentage="false"
    >
    </apex-line-chart>
  </div>
</template>

<script>
import BarStatistics from '@/views/components/BarStatistics';
import { BRow, BCol } from 'bootstrap-vue';

import ContentHeader from '@/views/components/ContentHeader.vue';
import ApexLineChart from '@/views/components/ApexLineChartDiagnostic.vue';
import EChartBar from '@/views/components/EChartBar.vue';
import DateRangePicker from '@/views/components/DateRangePicker.vue';
export default {
  props: {
    id: { type: String, required: false },
  },
  components: {
    BarStatistics,
    ContentHeader,
    ApexLineChart,
    EChartBar,
    BRow,
    BCol,
    DateRangePicker,
  },
  data() {
    return {
      stats: [],
      updateChartCount: 0,
      min: null,
      max: null,
    };
  },
  async mounted() {
    this.loadData();
  },
  computed: {
    graphDataFiltered() {
      return {
        series: [
          {
            name: this.$t('brightness'),
            data:
              this.stats?.map(d => {
                return {
                  x: d.date,
                  y: d.brightness,
                };
              }) ?? [],
          },
          {
            name: this.$t('cyclist.voltage'),
            data:
              this.stats?.map(d => {
                return {
                  x: d.date,
                  y: d.voltage,
                };
              }) ?? [],
          },
        ],
      };
    },
  },
  methods: {
    updateChart() {
      this.updateChartCount++;
    },
    async dateChanged(dateRange) {
      const dates = dateRange.split('to');
      this.loadData(dates[0], dates[1]);
    },
    async loadData(dateFrom = null, dateTo = null) {
      dateFrom = dateFrom
        ? dateFrom
        : this.$moment()
            .startOf('day')
            .subtract(7, 'days')
            .format('DD.MM.YYYY HH:mm');

      dateTo = dateTo
        ? dateTo
        : this.$moment()
            .endOf('day')
            .format('DD.MM.YYYY HH:mm');

      //Bar statistics
      const response = await this.$store.dispatch(
        'stats/GET_CYCLIST_DIAGNOSTIC_CHART',
        {
          id: this.$route.params.id,
          dateFrom,
          dateTo,
        },
      );
      this.stats = response.data;
      this.min = new Date(
        this.$moment(dateFrom, 'DD.MM.YYYY').format('YYYY-MM-DD'),
      ).getTime();
      this.max = new Date(
        this.$moment(dateTo, 'DD.MM.YYYY')
          .add(30, 'hours')
          .format('YYYY-MM-DD'),
      ).getTime();
    },
  },
  watch: {
    '$route.params.id': function(id) {
      this.loadData();
    },
  },
};
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';

.select-width {
  width: 300px;
}
</style>
