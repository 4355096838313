import { $GET, $PATCH, $POST, $DELETE } from './methods';

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    GET_USER(state, userId) {
      return $GET('users/' + userId);
    },
    GET_USER_REMOTEGUI(state, userId) {
      return $GET('users/' + userId + '/remotegui');
    },
    async GET_USERS(state, payload) {
      const response = await $GET('users');
      response.data = response.data.map(r => {
        return { ...r, email: r.email.replace('@vivozite-ldap.si', '') };
      });
      return response;
    },
    UPDATE_USER(state, payload) {
      return $PATCH('users/' + payload.userId, payload.user);
    },
    CREATE_USER(state, payload) {
      return $POST('users', payload.user);
    },
    DELETE_USER(state, userId) {
      return $DELETE('users/' + userId);
    },
    GET_PROFILE(state, userId) {
      return $GET('users/profile');
    },
    UPDATE_PROFILE(state, payload) {
      return $PATCH('users/profile', payload.user);
    },
    GET_LANGUAGES(state, payload) { return $GET('users/languages'); }

  },
};
