import CounterStats from '@/views/apps/cyclist/CounterStats.vue';
import DirectionStats from '@/views/apps/cyclist/DirectionStats.vue';
import AverageStats from '@/views/apps/cyclist/AverageStats.vue';
import ClassStats from '@/views/apps/cyclist/ClassStats.vue';
import Temperature from '@/views/apps/cyclist/Temperature.vue';
import Diagnostic from '@/views/apps/cyclist/Diagnostic.vue';
import Voltage from '@/views/apps/cyclist/Voltage.vue';
import Settings from '@/views/apps/cyclist/settings/Settings.vue';
import Report from '@/views/apps/shared/Report.vue';
import Import from '@/views/apps/speeding/Import.vue';

export default [
  {
    path: '/cyclist/:id',
    name: 'cyclist.dashboard',
    props: true,
    component: () => import('@/views/apps/Index.vue'),
    children: [
      {
        path: 'counter',
        component: CounterStats,
        meta: {
          preloadDevice: true,
          resource: 'CyclistStats',
          read: true,
          manage: false,
        },
      },
      {
        path: 'direction',
        component: DirectionStats,
        meta: {
          preloadDevice: true,
          resource: 'CyclistStats',
          read: true,
          manage: false,
        },
      },
      {
        path: 'average',
        component: AverageStats,
        meta: {
          preloadDevice: true,
          resource: 'CyclistStats',
          read: true,
          manage: false,
        },
      },
      {
        path: 'import',
        component: Import,
        meta: {
          preloadDevice: true,
          resource: 'Device',
          read: false,
          manage: true,
          type: 'cyclist',
        },
      },
      {
        path: 'classes',
        component: ClassStats,
        meta: {
          preloadDevice: true,
          resource: 'CyclistStats',
          read: true,
          manage: false,
        },
      },
      {
        path: 'diagnostic',
        component: Diagnostic,
        meta: {
          preloadDevice: true,
          resource: 'CyclistStats',
          read: true,
          manage: false,
        },
      },
      {
        path: 'temperature',
        component: Temperature,
        meta: {
          preloadDevice: true,
          resource: 'CyclistStats',
          read: true,
          manage: false,
        },
      },
      {
        path: 'voltage',
        component: Voltage,
        meta: {
          preloadDevice: true,
          resource: 'CyclistStats',
          read: true,
          manage: false,
        },
      },
      {
        path: 'report',
        component: Report,
        meta: {
          preloadDevice: true,
          resource: 'CyclistStats',
          read: true,
          manage: false,
        },
      },
      {
        path: 'settings',
        name: 'cyclist-settings',
        component: Settings,
        meta: {
          preloadDevice: true,
          resource: 'CyclistStats',
          read: false,
          manage: true,
        },
      },
    ],
    meta: {
      pageTitle: 'Dashboard',
      breadcrumb: [
        {
          text: 'Dashboard',
          active: true,
        },
      ],
      preloadDevice: true,
    },
  },
];
