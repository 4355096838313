<template>
  <div>
    <b-form-group
      label-cols="4"
      label-cols-lg="2"
      :label="$t('generalSettings.mac_address')"
    >
      <b-form-input disabled v-model="settings.mac" />
    </b-form-group>
    <b-form-group
      label-cols="4"
      label-cols-lg="2"
      :label="$t('generalSettings.ip_address')"
    >
      <b-form-input v-model="settings.ftpIp" />
    </b-form-group>
    <b-form-group label-cols="4" label-cols-lg="2" :label="$t('username')">
      <b-form-input v-model="settings.ftpUsername" />
    </b-form-group>
    <b-form-group label-cols="4" label-cols-lg="2" :label="$t('password')">
      <b-input-group class="input-group-merge">
        <b-form-input
          v-model="settings.ftpPassword"
          class="form-control-merge"
          name="ftp-password"
          :type="passwordFieldType"
          placeholder="············"
        />
        <b-input-group-append is-text>
          <feather-icon
            class="cursor-pointer"
            :icon="passwordToggleIcon"
            @click="togglePasswordVisibility"
          />
        </b-input-group-append>
      </b-input-group>
    </b-form-group>
    <div class="float-right">
      <b-button @click="updateFTP" variant="primary">{{ $t('save') }}</b-button>
    </div>
  </div>
</template>

<script>
import {
  BFormGroup,
  BFormInput,
  BButton,
  BInputGroupAppend,
  BInputGroup,
} from 'bootstrap-vue';
import { togglePasswordVisibility } from '@core/mixins/ui/forms';
import useDeviceSetttings from '@/views/apps/speeding/settings/useDeviceSettings';
import { dangerToast, successToast } from '@/utils/toast';

export default {
  components: {
    BFormGroup,
    BFormInput,
    BButton,
    BInputGroupAppend,
    BInputGroup,
  },
  mixins: [togglePasswordVisibility],
  setup(props, context) {
    const { settings } = useDeviceSetttings(context, 'ftp');
    return { settings };
  },
  methods: {
    updateFTP() {
      const { ftpUsername, ftpIp, ftpPassword, mac } = this.settings;
      this.$store
        .dispatch('devices/UPDATE', {
          id: this.$route.params.id,
          ftpUsername,
          ftpPassword,
          ftpIp,
          //mac,
        })
        .then(response => {
          successToast(
            this.$toast,
            this.$t('success'),
            this.$t('update_success_descirption'),
          );
        })
        .catch(e => {
          dangerToast(
            this.$toast,
            this.$t('failed'),
            this.$t(e.response.data.message),
          );
        });
    },
  },
  computed: {
    passwordToggleIcon() {
      return this.passwordFieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon';
    },
  },
};
</script>

<style></style>
