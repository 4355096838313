<template>
  <div>
    <div class="d-flex align-items-center pointer" style="position: relative">
      <date-range-picker
        ref="picker"
        :locale-data="dateLocale"
        :ranges="ranges"
        class="datetimeCustom"
        v-model="dateRange"
        :append-to-body="true"
        :always-show-calendars="true"
        :showDropdowns="true"
        :alwaysShowCalendars="true"
        :opens="'left'"
        :timePicker="true"
        :timePicker24Hour="true"
        control-container-class="form-control flat-picker bg-transparent border-0 shadow-none"
        @update="dateChange"
      >
      </date-range-picker>
      <feather-icon
        @click="openDatePicker"
        class="datetimeicon"
        icon="CalendarIcon"
        size="19"
      />
    </div>
  </div>
</template>

<script>
import DateRangePicker from 'vue2-daterange-picker';
//you need to import the CSS manually (in case you want to override it)
import 'vue2-daterange-picker/dist/vue2-daterange-picker.css';
let today = new Date();
today.setHours(0, 0, 0, 0);

let todayEnd = new Date();
todayEnd.setHours(23, 59, 0, 0);

let yesterday = new Date();
yesterday.setDate(today.getDate() - 1);
yesterday.setHours(0, 0, 0, 0);

let yesterdayEnd = new Date();
yesterdayEnd.setDate(today.getDate() - 1);
yesterdayEnd.setHours(23, 59, 0, 0);

let days7 = new Date();
days7.setDate(today.getDate() - 7);
days7.setHours(0, 0, 0, 0);

export default {
  components: {
    DateRangePicker,
  },
  props: {
    last7Days: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      dateRange: {
        startDate:
          this.$store.state.app.currentDate.startDate && this.last7Days == false
            ? this.$store.state.app.currentDate.startDate
            : this.$moment()
                .startOf('day')
                .subtract(7, 'days'),
        endDate:
          this.$store.state.app.currentDate.endDate && this.last7Days == false
            ? this.$store.state.app.currentDate.endDate
            : this.$moment().endOf('day'),
      },
      dateLocale: {
        direction: 'ltr',
        format: 'dd.mm.yyyy',
        separator: ' - ',
        applyLabel: this.$t('confirm'),
        cancelLabel: this.$t('cancel'),
        weekLabel: 'W',
        customRangeLabel: 'Custom Range',
        daysOfWeek: [
          this.$t('calendar.short_sunday'),
          this.$t('calendar.short_monday'),
          this.$t('calendar.short_tuesday'),
          this.$t('calendar.short_wednesday'),
          this.$t('calendar.short_thursday'),
          this.$t('calendar.short_friday'),
          this.$t('calendar.short_saturday'),
        ],
        monthNames: [
          this.$t('calendar.short_january'),
          this.$t('calendar.short_february'),
          this.$t('calendar.short_march'),
          this.$t('calendar.short_april'),
          this.$t('calendar.short_may'),
          this.$t('calendar.short_june'),
          this.$t('calendar.short_july'),
          this.$t('calendar.short_august'),
          this.$t('calendar.short_september'),
          this.$t('calendar.short_october'),
          this.$t('calendar.short_november'),
          this.$t('calendar.short_december'),
        ],
        firstDay: 1,
      },
      ranges: {
        [`${this.$t('calendar.today')}`]: [today, todayEnd],
        [`${this.$t('calendar.yesterday')}`]: [yesterday, yesterdayEnd],
        [`${this.$t('calendar.last_7_days')}`]: [
          new Date(days7),
          new Date(todayEnd),
        ],
        [`${this.$t('calendar.current_month')}`]: [
          new Date(today.getFullYear(), todayEnd.getMonth(), 1),
          new Date(today.getFullYear(), todayEnd.getMonth() + 1, 0, 23, 59),
        ],
        [`${this.$t('calendar.previous_month')}`]: [
          new Date(today.getFullYear(), todayEnd.getMonth() - 1, 1),
          new Date(today.getFullYear(), todayEnd.getMonth(), 0),
        ],
        [`${this.$t('calendar.current_year')}`]: [
          new Date(today.getFullYear(), 0, 1),
          new Date(todayEnd.getFullYear(), 11, 31),
        ],
        [`${this.$t('calendar.previous_year')}`]: [
          new Date(today.getFullYear() - 1, 0, 1),
          new Date(todayEnd.getFullYear() - 1, 11, 31),
        ],
      },
      selected_reason: null,
      selected_location: null,
      selected_user: null,
      items: [],
      reasons: [],
      users: [],
      locations: [],
    };
  },
  methods: {
    dateChange() {
      setTimeout(() => {
        this.$store.commit('app/SET_CURRENT_DATE', {
          startDate: this.dateRange.startDate,
          endDate: this.dateRange.endDate,
        });

        let dateRange =
          this.$moment(this.dateRange.startDate)
            .format('DD.MM.y HH:mm')
            .toString() +
          ' to ' +
          this.$moment(this.dateRange.endDate)
            .format('DD.MM.y HH:mm')
            .toString();

        this.$emit('date-changed', dateRange);
      }, 100);
    },
    openDatePicker() {
      this.$refs.picker._data.open = true;
      this.$refs.picker.togglePicker(true);
    },
  },
};
</script>

<style>
.daterangepicker .applyBtn {
  background-color: #0391e5 !important;
  border-color: #0391e5 !important;
  padding: 0.786rem 1.5rem !important;
  border-radius: 0.358rem;
}
.daterangepicker .applyBtn:focus {
  background-color: #357ebd !important;
}
.daterangepicker .cancelBtn {
  padding: 0.786rem 1.5rem !important;
  border-radius: 0.358rem;
}
.daterangepicker .hourselect,
.daterangepicker .minuteselect {
  padding-left: 10px !important;
}
.daterangepicker .ranges li {
  padding: 13px 12px !important;
}
.daterangepicker .ranges li.active,
.daterangepicker td.active .daterangepicker td.active:hover,
.daterangepicker td.active.in-range.start-date,
.daterangepicker td.active.in-range.end-date,
.daterangepicker td.weekend.active.in-range.end-date {
  background-color: #0391e5 !important;
  color: #fff !important;
}

.flat-picker {
  font-size: 17px !important;
}

.pointer {
  cursor: pointer;
}

.datetimeicon {
  position: absolute;
  right: 30px;
  top: 8px;
}

.datetimeCustom {
  width: 270px;
  z-index: 3;
}
</style>
