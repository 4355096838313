<template>
  <div>
    <content-header
      @dateChanged="dateChanged"
      datepicker
      nomargin
    ></content-header>
    <b-row>
      <b-col md="6"
        ><bar-statistics :title="$t('cars')" :data="carStats"
      /></b-col>
      <b-col md="6"
        ><bar-statistics :title="$t('trucks')" :data="truckStats"
      /></b-col>
    </b-row>
    <apex-line-chart
      :option="graphDataFiltered"
      :title="$t('speed_percentiles') + ' - ' + $t('cars')"
      nodatepicker
    ></apex-line-chart>
    <apex-line-chart
      :option="graphTruckDataFiltered"
      :title="$t('speed_percentiles') + ' - ' + $t('trucks')"
      nodatepicker
    ></apex-line-chart>
    <!--<dynamic-chart
      :title="$t('speed_percentiles') + ' - ' + $t('cars')"
      :lineChartData="graphDataFiltered"
      :barChartData="barChartData"
      :graph="barData.cars ? barData.cars.graph.v30 : []"
      :dateRange="dateRange"
    ></dynamic-chart>
    <dynamic-chart
      :title="$t('speed_percentiles') + ' - ' + $t('trucks')"
      :lineChartData="graphTruckDataFiltered"
      :barChartData="truckBarChartData"
      :graph="barData.trucks ? barData.trucks.graph.v30 : []"
      :dateRange="dateRange"
    ></dynamic-chart>-->
  </div>
</template>

<script>
import { BTable, BRow, BCol, BCard, BFormInput } from 'bootstrap-vue';
import ApexLineChart from '@/views/components/ApexLineChart.vue';
import BarStatistics from '@/views/components/BarStatistics';
import ContentHeader from '@/views/components/ContentHeader.vue';
import {
  formatDateFromAPI,
  getStartDateFromStore,
  getEndDateFromStore,
} from '@/utils';
import DynamicChart from '@/views/components/DynamicChart.vue';

export default {
  components: {
    BTable,
    BCard,
    BRow,
    BCol,
    ApexLineChart,
    BarStatistics,
    ContentHeader,
    BFormInput,
    DynamicChart,
  },
  mounted() {
    this.loadData();
  },
  data() {
    return {
      dateRange: [getStartDateFromStore(), getEndDateFromStore()],
      customPercentile: null,
      vCustom: null,
      dateStart: null,
      dateEnd: null,
      barData: {},
      fields: [
        {
          label: '',
          key: 'text',
          sortable: false,
        },
        {
          label: '',
          key: 'number',
          sortable: false,
        },
      ],
      graphData: [],
      graphDataCustom: [],
    };
  },
  methods: {
    dateChanged(dateRange) {
      const dates = dateRange.split('to');
      this.dateStart = dates[0];
      this.dateEnd = dates[1];
      this.dateRange = [dates[0], dates[1]];
      this.loadData(this.dateStart, this.dateEnd);
    },
    async loadData(dateFrom, dateTo) {
      const percentileData = await this.$store.dispatch(
        'stats/GET_PERCENTILE_DATA',
        { id: this.$route.params.id, dateFrom, dateTo, app: 'radar' },
      );
      this.barData = percentileData.data;
    },
  },
  computed: {
    barChartData() {
      return [
        {
          name: this.$t('v30'),
          data: this.barData?.cars?.graph?.v30.map(item => item.v),
        },
        {
          name: this.$t('v50'),
          data: this.barData?.cars?.graph?.v50.map(item => item.v),
        },
        {
          name: this.$t('v85'),
          data: this.barData?.cars?.graph?.v85.map(item => item.v),
        },
      ];
    },
    truckBarChartData() {
      return [
        {
          name: this.$t('v30'),
          data: this.barData?.trucks?.graph?.v30.map(item => item.v),
        },
        {
          name: this.$t('v50'),
          data: this.barData?.trucks?.graph?.v50.map(item => item.v),
        },
        {
          name: this.$t('v85'),
          data: this.barData?.trucks?.graph?.v85.map(item => item.v),
        },
      ];
    },
    graphDataFiltered() {
      return {
        series: [
          {
            name: 'v30',
            data:
              this.barData?.cars?.graph?.v30.map(d => {
                return {
                  x: d.dateTime,
                  y: d.v,
                };
              }) ?? [],
          },
          {
            name: 'v50',
            data:
              this.barData?.cars?.graph?.v50.map(d => {
                return {
                  x: d.dateTime,
                  y: d.v,
                };
              }) ?? [],
          },
          {
            name: 'v85',
            data:
              this.barData?.cars?.graph?.v85.map(d => {
                return {
                  x: d.dateTime,
                  y: d.v,
                };
              }) ?? [],
          },
        ],
      };
    },
    graphTruckDataFiltered() {
      return {
        series: [
          {
            name: 'v30',
            data:
              this.barData?.trucks?.graph?.v30.map(d => {
                return {
                  x: d.dateTime,
                  y: d.v,
                };
              }) ?? [],
          },
          {
            name: 'v50',
            data:
              this.barData?.trucks?.graph?.v50.map(d => {
                return {
                  x: d.dateTime,
                  y: d.v,
                };
              }) ?? [],
          },
          {
            name: 'v85',
            data:
              this.barData?.trucks?.graph?.v85.map(d => {
                return {
                  x: d.dateTime,
                  y: d.v,
                };
              }) ?? [],
          },
        ],
      };
    },
    carStats() {
      return [
        {
          icon: 'AlertCircleIcon',
          color: 'light-primary',
          title: (this.barData?.cars?.v30 ?? '- ') + 'km/h',
          subtitle: this.$t('average_') + ' v30',
          customClass: 'mb-2 mb-xl-0',
        },
        {
          icon: 'ActivityIcon',
          color: 'light-info',
          title: (this.barData?.cars?.v50 ?? '- ') + 'km/h',
          subtitle: this.$t('average_') + ' v50',
          customClass: 'mb-2 mb-xl-0',
        },
        {
          icon: 'BarChart2Icon',
          color: 'light-danger',
          title: (this.barData?.cars?.v85 ?? '- ') + 'km/h',
          subtitle: this.$t('average_') + ' v85',
          customClass: 'mb-2 mb-sm-0',
        },
      ];
    },
    truckStats() {
      return [
        {
          icon: 'AlertCircleIcon',
          color: 'light-primary',
          title: (this.barData?.trucks?.v30 ?? '- ') + 'km/h',
          subtitle: this.$t('average_') + ' v30',
          customClass: 'mb-2 mb-xl-0',
        },
        {
          icon: 'ActivityIcon',
          color: 'light-info',
          title: (this.barData?.trucks?.v50 ?? '- ') + 'km/h',
          subtitle: this.$t('average_') + ' v50',
          customClass: 'mb-2 mb-xl-0',
        },
        {
          icon: 'BarChart2Icon',
          color: 'light-danger',
          title: (this.barData?.trucks?.v85 ?? '- ') + 'km/h',
          subtitle: this.$t('average_') + ' v85',
          customClass: 'mb-2 mb-sm-0',
        },
      ];
    },
  },
  watch: {
    '$route.params.id': function(id) {
      this.loadData();
    },
  },
};
</script>

<style>
.hidden_header {
  display: none;
}
</style>
