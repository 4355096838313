<template>
  <div>
    <content-header datepicker @dateChanged="dateChanged"></content-header>
    <b-row>
      <b-col md="3">
        <b-card>
          <div class="mb-2">{{ $t('stats_in_range') }} - {{ $t('sum') }}</div>
          <b-table responsive="sm" :items="allData.all" :fields="fields">
            <template #cell(low)="data">
              {{ data.item.low }} - {{ data.item.high }} km/h
            </template>
          </b-table>
          <div class="mt-3">
            <div class="mb-1">{{ $t('classes.custom_speed') }}</div>
            <b-row class="mb-1">
              <b-col class="text-center" md="6">
                {{ sliderValue[0] }} - {{ sliderValue[1] }} km/h
              </b-col>
              <b-col class="text-center" md="6">
                {{ customValueCount }}
              </b-col>
            </b-row>
            <vue-slider
              v-model="sliderValue"
              :tooltip="'active'"
              class="mb-2 vue-slider-primary"
              direction="ltr"
              :marks="true"
              :min="sliderMin"
              :max="sliderMax"
              :enableCross="false"
              @drag-end="sliderChange"
              @change="sliderChange"
              :interval="getSliderInterval"
            />
          </div>
        </b-card>
      </b-col>
      <b-col md="9">
        <e-chart-bar
          :title="$t('classes.speed_classes') + ' - ' + $t('sum')"
          :data="allGraph"
          :lables="graphLabels"
          nodatepicker
        ></e-chart-bar>
        <e-chart-bar
          :title="$t('classes.speed_classes') + ' - ' + $t('individually')"
          :data="carAndTruckGraph"
          :lables="graphLabels"
          nodatepicker
        ></e-chart-bar>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import { BTable, BRow, BCol, BCard } from 'bootstrap-vue';
//import ChartjsBarChart from './ChartjsBarChart.vue';
import EChartBar from '@/views/components/EChartBar.vue';
import ContentHeader from '@/views/components/ContentHeader.vue';
import VueSlider from 'vue-slider-component';

export default {
  props: {
    id: { type: String, required: false },
  },
  components: {
    BTable,
    BCard,
    BRow,
    BCol,
    //ChartjsBarChart,
    EChartBar,
    ContentHeader,
    VueSlider,
  },
  mounted() {
    this.loadClasses();
  },
  data() {
    return {
      sliderMin: 0,
      sliderMax: 100,
      sliderValue: [0, 100],
      customValueCount: 0,
      classes: [],
      fields: [
        {
          label: this.$t('classes.classes'),
          key: 'low',
          sortable: false,
        },
        {
          label: this.$t('number'),
          key: 'sum',
          sortable: false,
        },
      ],

      allData: [],
      lables: [],
    };
  },
  methods: {
    async sliderChange() {
      if (this.sliderValue[0] && this.sliderValue[1]) {
        let response = await this.$store.dispatch(
          'stats/GET_CUSTOM_CLASSES_DATA',
          {
            id: this.$route.params.id,
            dateFrom: this.dateStart,
            dateTo: this.dateEnd,
            min: this.sliderValue[0],
            max: this.sliderValue[1],
            app: 'radar',
          },
        );
        this.customValueCount = response.data.sum;
      }
    },
    async loadClasses(dateFrom, dateTo, minLimit, maxLimit) {
      let response = await this.$store.dispatch('stats/GET_CLASSES_DATA', {
        id: this.$route.params.id,
        dateFrom,
        dateTo,
        minLimit,
        maxLimit,
        app: 'radar',
      });

      this.allData = response.data;
      if (this.allData.all.length > 0) {
        const min = this.allData.all[0].low;
        const max = this.allData.all[this.allData.all.length - 1].high + 1;

        this.sliderValue = [min, max];
        this.sliderMin = min;
        this.sliderMax = max;
        this.sliderChange();
      }
    },
    dateChanged(dateRange) {
      if (dateRange) {
        const dates = dateRange.split('to');
        this.dateStart = dates[0];
        this.dateEnd = dates[1];
        this.loadClasses(dates[0], dates[1], 0, 100);
      }
    },
  },
  computed: {
    getSliderInterval() {
      if (this.graphLabels?.length > 8 && this.graphLabels?.length < 16)
        return 20;
      else if (this.graphLabels?.length > 16) return 30;
      else return 10;
    },
    allGraph() {
      return [
        {
          name: this.$t('sum'),
          data: this.allData.all?.map(item => item.sum),
        },
      ];
    },
    carAndTruckGraph() {
      return [
        {
          name: this.$t('cars'),
          data: this.allData.cars?.map(item => item.sum),
        },
        {
          name: this.$t('trucks'),
          data: this.allData.trucks?.map(item => item.sum),
        },
      ];
    },
    graphLabels() {
      return this.allData.all?.map(item => {
        return `${item.low} - ${item.high} km/h`;
      });
    },
  },
  watch: {
    '$route.params.id': function(id) {
      this.loadClasses();
    },
  },
};
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-slider.scss';
</style>
