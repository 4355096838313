import { $GET, $PATCH, $POST, $DELETE } from './methods';

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    GET_REMOTE_GUI_LIST(state, payload) {
      return $GET('remotegui/' + payload.userId + '/list');
    },
    GET_REMOTE_GUI(state, id) {
      return $GET('remotegui/' + id);
    },
    UPDATE_REMOTE_GUI(state, payload) {
      return $PATCH('remotegui/' + payload.remoteguiId, payload.remotegui);
    },
    CREATE_REMOTE_GUI(state, payload) {
      return $POST('remotegui', payload.remotegui);
    },
    DELETE_REMOTE_GUI(state, remoteguiId) {
      return $DELETE('remotegui/' + remoteguiId);
    },
  },
};
