import jwtService from '@core/auth/jwt/jwtService';
import store from '../../store';

export class myJwtService extends jwtService {
  constructor(axiosIns, jwtOverrideConfig) {
    super(axiosIns, jwtOverrideConfig);
  }

  getToken() {
    return store.state.auth[this.jwtConfig.storageTokenKeyName];
  }

  setToken(value) {
    store.commit('auth/SET_IFRAME_ACCESS_TOKEN', value);
  }
}
