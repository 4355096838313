<template>
  <e-charts
    ref="line"
    autoresize
    :options="option"
    theme="theme-color"
    auto-resize
  />
</template>

<script>
import ECharts from 'vue-echarts';
import 'echarts/lib/component/title';
import 'echarts/lib/component/tooltip';
import 'echarts/lib/component/legend';
import 'echarts/lib/chart/bar';
import 'echarts/lib/component/markLine';
import theme from './theme.json';

ECharts.registerTheme('theme-color', theme);

export default {
  components: {
    ECharts,
  },
  props: {
    optionData: {
      type: Object,
      default: null,
    },
  },
  computed: {
    option() {
      return {
        title: {
          text: this.optionData.title,
          subtext: this.optionData.subtitle,
        },
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            type: 'shadow',
          },
        },
        legend: {
          orient: 'horizontal',
          bottom: 0,
        },
        grid: {
          bottom: '15%',
          ...this.optionData.grid,
        },
        axisPointer: {
          label: {
            formatter: data => {
              const split = data.value.split('-');
              if (split.length == 3) {
                const day = this.$moment(data.value).format('dddd');
                return day.charAt(0).toUpperCase() + day.slice(1);
              }
              return data.value;
            },
          },
        },
        //grid: this.optionData.grid,
        xAxis: this.optionData.xAxis,
        yAxis: this.optionData.yAxis,
        series: this.optionData.series,
      };
    },
  },
};
</script>
