<template>
  <b-card :title="title">
    <div class="d-flex justify-content-between flex-wrap">
      <div class="mb-1 mb-sm-0">
        <span class="text-muted">{{ subtitle }}</span>
      </div>
      <div class="d-flex align-items-center" v-if="!nodatepicker">
        <slot name="additional-tools"></slot>
        <date-range-picker
          class="ml-2"
          @date-changed="dateChanged"
          ref="dateRangePicker"
          id="dateRange"
        >
        </date-range-picker>
      </div>
    </div>

    <!-- echart -->
    <!--<app-echart-line :option-data="option" />-->
    <vue-apex-charts
      type="line"
      height="400"
      :options="graphOption"
      :series="option.series"
    />
  </b-card>
</template>

<script>
import { BCard, BBadge } from 'bootstrap-vue';
import AppEchartLine from '@core/components/charts/echart/AppEchartLine.vue';
import DateRangePicker from './DateRangePicker.vue';
import VueApexCharts from 'vue-apexcharts';
const sl = require('apexcharts/dist/locales/sl.json');

export default {
  components: {
    BCard,
    AppEchartLine,
    BBadge,
    DateRangePicker,
    VueApexCharts,
  },
  props: {
    option: {
      type: Object,
    },
    title: {
      type: String,
    },
    subtitle: {
      type: String,
    },
    nodatepicker: {
      type: Boolean,
    },
    compare: {
      type: Boolean,
      default: false,
    },
    decimalNumbers: {
      type: Number,
      default: 0,
    },
    min: {
      type: Number,
      required: false,
    },
    max: {
      type: Number,
      required: false,
    },
    brightnessPercentage: {
      type: Boolean,
      default: true,
    },
    temperature: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    graphOption() {
      return {
        stroke: {
          width: 2,
        },
        chart: {
          locales: [sl],
          defaultLocale: 'sl',
          toolbar: {
            show: false,
            offsetX: 0,
            offsetY: 0,
            tools: {
              download: true,
            },
            export: {
              csv: {
                title: '',
                filename: undefined,
                columnDelimiter: ',',
                headerCategory: 'category',
                headerValue: 'value',
                dateFormatter(timestamp) {
                  return new Date(timestamp).toDateString();
                },
              },
              svg: {
                filename: undefined,
              },
              png: {
                filename: undefined,
              },
            },
          },
          zoom: {
            enabled: false,
          },
        },
        xaxis: {
          type: 'datetime',
          tooltip: {
            enabled: true,
          },
          tickAmount: 20,
          labels: {
            rotate: -70,
            datetimeUTC: false,
          },
          min: this.min ? this.min : undefined,
          max: this.max ? this.max : undefined,
          //tickAmount: 6,
        },
        yaxis: [
          {
            min: this.temperature ? -20 : 0,
            max: this.brightnessPercentage ? 100 : this.temperature ? 40 : 8,
            seriesName: 'Osvetljenost',
            opposite: true,
            tooltip: {
              enabled: true,
            },
            labels: {
              formatter: val => {
                return val !== null
                  ? parseFloat(val).toFixed(this.brightnessPercentage ? 0 : 1) +
                      (this.brightnessPercentage ? '%' : '')
                  : '-';
              },
            },
          },
          {
            min: 10.5,
            max: 15.5,
            seriesName: 'Napetost',
            tooltip: {
              enabled: true,
            },
            labels: {
              formatter: val => {
                return val !== null ? parseFloat(val).toFixed(2) + 'V' : '-';
              },
            },
          },
        ],
        tooltip: {
          x: {
            show: true,
            format: 'dd.MM.yyyy HH:mm',
            formatter: undefined,
          },
        },

        grid: {
          xaxis: {
            lines: {
              show: true,
            },
          },
        },
        legend: {
          show: true,
        },
      };
    },
  },
  methods: {
    dateChanged(dateRange) {
      this.$emit('date-changed', dateRange);
    },
  },
};
</script>
