import mock from './mock';

/* eslint-disable import/extensions */

// JWT
import './jwt';

// Table
import './data/extensions/good-table';
import './data/extensions/auto-suggest';
import './data/card/card-statistics';
import './data/card/card-analytics';

// Apps

// dashboard
import './data/dashboard/analytics';
import './data/dashboard/ecommerce';

// pages
/* eslint-enable import/extensions */

mock.onAny().passThrough(); // forwards the matched request over network
