<template>
  <div v-if="hasPermission">
    <h4 class="mb-2">{{ $t('settings.settings') }}</h4>
    <b-card>
      <b-row>
        <b-col md="12"
          ><app-collapse>
            <app-collapse-item
              @visible="GeoSettingsVisible"
              :title="$t('settings.general_settings')"
            >
              <template slot="header"
                ><feather-icon icon="SettingsIcon" size="16" />
                <span class="font-weight-bold ml-1">{{
                  $t('settings.general_settings')
                }}</span></template
              >
              <general-settings
                :key="'general' + updateCount"
                @updated="updateHistory"
                :device="device"
              ></general-settings>
              <hr class="mt-1 mb-2" />
              <date-time-settings
                :key="'datetime' + updateCount"
                @updated="updateHistory"
              ></date-time-settings>
              <hr class="mt-1 mb-2" />
              <geo-settings
                :key="'geo' + updateCount"
                @updated="updateHistory"
                ref="geoSettings"
              ></geo-settings>
            </app-collapse-item>
            <app-collapse-item
              class="pt-1"
              :title="$t('settings.speed_settings')"
            >
              <template slot="header"
                ><feather-icon icon="SlidersIcon" size="16" />
                <span class="font-weight-bold ml-1">{{
                  $t('settings.speed_settings')
                }}</span></template
              >

              <b-row>
                <b-col md="6">
                  <speed-settings
                    :key="updateCount"
                    @updated="updateHistory"
                  ></speed-settings>
                </b-col>
                <b-col md="6">
                  <h5>{{ $t('settings.speed_symbols') }}</h5>
                  <speed-symbols
                    table="speed-symbols"
                    :key="updateCount"
                    @updated="updateHistory"
                    ref="speedSymbolsTable"
                  ></speed-symbols>
                </b-col>
              </b-row>
              <hr class="mt-1 mb-2" />
              <b-row v-if="showLedPages">
                <b-col xl="12">
                  <h5>{{ $t('settings.led_pages') }}</h5>
                  <led-pages
                    table="led-pages"
                    :key="updateCount"
                    @updated="updateHistory"
                    ref="speedSymbolsTable"
                  ></led-pages>
                </b-col>
              </b-row>
            </app-collapse-item>

            <app-collapse-item
            v-if="!isBasic"
              @visible="diagnosticExpand"
              class="pt-1"
              :title="$t('settings.diagnostics')"
              ><template slot="header"
                ><feather-icon icon="ToolIcon" size="16" />
                <span class="font-weight-bold ml-1">{{
                  $t('settings.diagnostics')
                }}</span></template
              >
              <diagnostic-settings
                ref="diagnosticSettings"
                :key="updateCount"
                @updated="updateHistory"
              ></diagnostic-settings>
            </app-collapse-item>
            <app-collapse-item
             v-if="!isBasic && !isAdmin"
              @visible="diagnosticExpand"
              class="pt-1"
              :title="$t('settings.diagnostics')"
              ><template slot="header"
                ><feather-icon icon="TerminalIcon" size="16" />
                <span class="font-weight-bold ml-1">{{
                  $t('settings.terminal')
                }}</span></template
              >
              <telnet-shell></telnet-shell>
            </app-collapse-item>
          </app-collapse>
        </b-col>
      </b-row>
    </b-card>
    <div class="mt-3">
      <h4 class="mb-2">{{ $t('settings.recent_history') }}</h4>
      <b-table class="my-table" responsive="sm" :items="items" :fields="fields">
        <template #cell(status)="data">
          <span class="status" :class="statusColor(data.item.status)">{{
            statusText(data.item.status)
          }}</span>
        </template>
        <template #cell(action)="data">
          <span class="action">{{
            $t('speeding_actions.' + data.item.action)
          }}</span>
        </template>
      </b-table>
    </div>
  </div>
</template>

<script>
import {
  BFormGroup,
  BFormInput,
  BRow,
  BCol,
  BCard,
  BButton,
  BFormSelect,
  BTable,
} from 'bootstrap-vue';
import AppCollapse from '@core/components/app-collapse/AppCollapse.vue';
import AppCollapseItem from '@core/components/app-collapse/AppCollapseItem.vue';
import GeneralSettings from './GeneralSettings.vue';
import DateTimeSettings from './DateTimeSettings.vue';
import DiagnosticSettings from './DiagnosticSettings.vue';
import SpeedSettings from './SpeedSettings.vue';
import GeoSettings from './GeoSettings.vue';
import SpeedSymbols from './SpeedTable.vue';
import LedPages from './LedPages.vue';
import PageText from './PageText.vue';
import TelnetShell from '@/views/components/TelnetShell.vue';
export default {
  components: {
    BFormGroup,
    BFormInput,
    BRow,
    BCard,
    BCol,
    AppCollapse,
    AppCollapseItem,
    BButton,
    BFormSelect,
    GeneralSettings,
    DateTimeSettings,
    DiagnosticSettings,
    SpeedSettings,
    GeoSettings,
    BTable,
    SpeedSymbols,
    PageText,
    TelnetShell,
    LedPages,
  },
  data() {
    return {
      updateCount: 0,
      device: {},
      fields: [
        {
          label: this.$t('user'),
          key: 'user',
          sortable: false,
          thClass: 'th-background',
        },
        {
          label: this.$t('action'),
          key: 'action',
          sortable: false,
          thClass: 'th-background',
        },
        {
          label: this.$t('status'),
          key: 'status',
          sortable: false,
          thClass: 'th-background',
        },
      ],
      items: [],
    };
  },
  async mounted() {
    this.loadData();
  },
  methods: {
    GeoSettingsVisible(visible) {
      setTimeout(() => {
        this.$refs.geoSettings.updateMap();
      }, 200);
    },
    isModelPlus() {
      return this.device?.model?.includes('PLUS');
    },
    async loadData() {
      const response = await this.$store.dispatch(
        'devices/GET_DEVICE',
        this.$route.params.id,
      );
      if (response) this.device = response.data;

      this.updateHistory();
    },
    async updateHistory() {
      const history = await this.$store.dispatch(
        'devices/GET_ACTIONS_HISTORY',
        this.$route.params.id,
      );
      this.items = history.data;
    },
    statusText(status) {
      switch (status) {
        case 1:
          return this.$t('in_progress');
        case 2:
          return this.$t('done');
        case 3:
          return this.$t('error');
      }
      return '';
    },
    statusColor(status) {
      switch (status) {
        case 1:
          return 'status-blue';
        case 2:
          return 'status-green';
        case 3:
          return 'status-red';
      }
      return '';
    },
    diagnosticExpand() {
      setTimeout(() => {
        this.$refs.diagnosticSettings.updateChart();
      }, 200);
    },
  },
  computed: {
    showLedPages() {
      if (this.device.location) return this.device.location.charAt(1) == '1';
      return false;
    },
    hasPermission() {
      return this.$store.state.devices.currentDevice.manage;
    },
    isBasic(){
      return this.$store.state.auth.userData.role == "Uporabnik";
    },
    isAdmin(){
      return this.$store.state.auth.userData.role == "Admin";
    }
  },
  watch: {
    '$route.params.id': function(id) {
      //we need to force update all components
      this.loadData();
      this.updateCount++;
    },
  },
};
</script>

<style lang="scss">
.my-table {
  background: white;
}

.th-background {
  background-color: #e1f2fc !important;
}

.status {
  padding: 5px 10px;
  border-radius: 25px;
  font-weight: bold;
}
.status-blue {
  color: #2c98d4;
  background-color: #d5e0fc;
}
.status-green {
  color: #28c76f;
  background-color: #d9f1e4;
}
.status-red {
  color: #ea5455;
  background-color: #f1e1e1;
}
</style>
