<template>
  <div>
    <content-header
      @dateChanged="dateChanged"
      datepicker
      v-if="hideHeader == false"
      @compareChanged="compareChanged"
      @compareDateChanged="compareDateChanged"
      :compare="compare"
    >
      <template slot="additional-item"
        ><v-select
          :reduce="dir => dir.code"
          v-model="direction"
          :options="directionOptions"
          :clearable="false"
          class="per-page-selector d-inline-block mx-50 select-width"
          style="background-color: white"
          @input="loadData"
        >
          <div slot="no-options">{{ $t('no-options') }}</div>
        </v-select>
      </template>
    </content-header>
    <bar-statistics title="" :data="items" />
    <dynamic-chart
      :title="$t('cyclist.number_of_cyclists')"
      :lineChartData="lineChartData"
      :barChartData="barChartData"
      :graph="stats.graph"
      :dateRange="dateRange"
    ></dynamic-chart>
    <div v-if="hideHeader == false">
      <apex-line-chart
        :option="lineChartDataSum"
        :title="$t('cyclist.number_of_cyclists')"
        nodatepicker
        :dateFrom="startDate"
        :dateTo="endDate"
        v-if="!isDailyScale"
      >
        <template slot="additional-header">
          {{ $t('display_by_hour_day') }}
          <b-form-checkbox
            name="is-vertical-menu-collapsed"
            class="ml-1"
            style="padding-top: 3px"
            switch
            inline
            v-model="isDailyScale"
            @change="loadDataByScale"
          />
        </template>
      </apex-line-chart>
      <e-chart-bar
        v-if="isDailyScale"
        :title="$t('cyclist.number_of_cyclists')"
        :data="barGraphData"
        :lables="graphLabels"
        nodatepicker
      >
        <template slot="additional-header">
          {{ $t('display_by_hour_day') }}
          <b-form-checkbox
            name="is-vertical-menu-collapsed"
            class="ml-1"
            style="padding-top: 3px"
            switch
            inline
            v-model="isDailyScale"
            @change="loadDataByScale"
          />
        </template>
      </e-chart-bar>
    </div>
  </div>
</template>

<script>
import BarStatistics from '@/views/components/BarStatistics';

import ContentHeader from '@/views/components/ContentHeader.vue';
import ApexLineChart from '@/views/components/CyclistLineChart.vue';
import EChartBar from '@/views/components/EChartBar.vue';
import { cyclistLinks } from '@/store/stats/action-links';
import vSelect from 'vue-select';
import ApexBarChart from '../../components/ApexBarChart.vue';
import DynamicChart from '../../components/DynamicChart.vue';
import {
  getStartDateFromStore,
  getEndDateFromStore,
  monthNames,
} from '@/utils';
import { BFormCheckbox } from 'bootstrap-vue';
import i18n from '@/libs/i18n';

const weekDays = [
  i18n.t('calendar.sunday'),
  i18n.t('calendar.monday'),
  i18n.t('calendar.tuesday'),
  i18n.t('calendar.wednesday'),
  i18n.t('calendar.thursday'),
  i18n.t('calendar.friday'),
  i18n.t('calendar.saturday'),
];

export default {
  props: {
    id: Number,
    dateFrom: String,
    dateTo: String,
    hideHeader: Boolean,
  },
  components: {
    BarStatistics,
    ContentHeader,
    ApexLineChart,
    EChartBar,
    vSelect,
    ApexBarChart,
    DynamicChart,
    BFormCheckbox,
  },
  data() {
    return {
      isDailyScale: false,
      direction: null,
      directionOptions: [
        { label: this.$t('show_both_directions'), code: null },
        { label: this.$t('incoming'), code: '0' },
        { label: this.$t('outgoing'), code: '1' },
      ],
      stats: {
        avg: 0,
        speedLimit: 0,
        max: 0,
        underLimit: 0,
        aboveLimit: 0,
      },
      graphData: [],
      dateRange: [getStartDateFromStore(), getEndDateFromStore()],
      compare: false,
      compareDateRange: [getStartDateFromStore(), getEndDateFromStore()],
      compareStats: {},
    };
  },
  async mounted() {
    this.loadData();
  },
  computed: {
    startDate() {
      return new Date(
        this.$moment(this.dateRange[0], 'DD.MM.YYYY').format('YYYY-MM-DD'),
      ).getTime();
    },
    endDate() {
      return new Date(
        this.$moment(this.dateRange[1], 'DD.MM.YYYY').format('YYYY-MM-DD'),
      ).getTime();
    },
    barChartData() {
      let data = [
        {
          name: this.$t('Cyclists') + (this.compare ? ' 1' : ''),
          data: this.stats?.graph?.map(item => item.countAll),
        },
      ];
      if (this.compare && this.compareStats) {
        data = [
          ...data,
          {
            name: this.$t('Cyclists') + ' 2',
            data: this.compareStats.graph?.map(item => item.countAll),
          },
        ];
      }
      return data;
    },
    barChartLabels() {
      const groupByMonth = this.dateRange
        ? this.$moment(this.dateRange[1], 'DD.MM.YYYY HH:mm').diff(
            this.$moment(this.dateRange[0], 'DD.MM.YYYY HH:mm'),
            'days',
          ) >= 180
        : false;
      return this.stats.graph?.map(item => {
        if (groupByMonth) {
          const split = item.dateTime.split('-');
          return monthNames[parseInt(split[0]) - 1] + ' ' + split[1];
        } else return item.dateTime;
      });
    },
    items() {
      return [
        {
          icon: 'BarChart2Icon',
          color: 'light-primary',
          title: this.stats?.barStats?.sum ?? '0',
          subtitle: this.$t('cyclist.number_of_cyclists'),
          customClass: 'mb-2 mb-xl-0',
        },
        {
          icon: 'ActivityIcon',
          color: 'light-danger',
          title: this.stats?.barStats?.avgPerYear ?? '0',
          subtitle: this.$t('cyclist.number_of_cyclists_per_year'),
          customClass: 'mb-2 mb-sm-0',
        },
        {
          icon: 'ActivityIcon',
          color: 'light-info',
          title: Math.round(this.stats?.barStats?.avgPerDay)
            ? Math.round(this.stats?.barStats?.avgPerDay)
            : '0',
          subtitle: this.$t('cyclist.avg_cyclist_per_day'),
          customClass: 'mb-2 mb-xl-0',
        },
        {
          icon: 'ActivityIcon',
          color: 'light-danger',
          title: Math.round(this.stats?.barStats?.avgPerWeek)
            ? Math.round(this.stats?.barStats?.avgPerWeek)
            : '0',
          subtitle: this.$t('cyclist.avg_cyclist_per_week'),
          customClass: 'mb-2 mb-sm-0',
        },
        {
          icon: 'ActivityIcon',
          color: 'light-success',
          title: Math.round(this.stats?.barStats?.avgPerMonth)
            ? Math.round(this.stats?.barStats?.avgPerMonth)
            : '0',
          subtitle: this.$t('cyclist.avg_cyclist_per_month'),
          customClass: 'mb-2 mb-xl-0',
        },
      ];
    },
    lineChartData() {
      let series = [
        {
          name: this.$t('cyclist.number_of_cyclists'),
          data:
            this.stats.graph?.map(d => {
              return {
                x: d.dateTime,
                y: d.countAll,
              };
            }) ?? [],
        },
      ];
      if (this.compare) {
        series = [
          ...series,
          [
            {
              name: this.$t('cyclist.number_of_cyclists'),
              data:
                this.compareStats.graph?.map(d => {
                  return {
                    x: d.dateTime,
                    y: d.countAll,
                  };
                }) ?? [],
            },
          ],
        ];
      }
      return {
        series,
      };
    },
    graphLabels() {
      const data = this.getBarGraphData();
      return data?.map(item => item.label);
    },
    barGraphData() {
      const data = this.getBarGraphData();
      return [
        {
          name: this.$t('Cyclists'),
          data: data?.map(item => item.countAll),
        },
      ];
    },
    lineChartDataSum() {
      if (this.stats.graphRaw) {
        let data = JSON.parse(JSON.stringify(this.stats?.graphRaw));
        if (this.isDailyScale) {
          data = this.getBarGraphData();
        } else {
          data = Object.values(
            this.stats.graphRaw?.reduce((r, o) => {
              r[o.hour] = r[o.hour] || { label: o.hour + ':00', countAll: 0 };
              r[o.hour].countAll += +o.countAll;
              return r;
            }, {}),
          );
        }
        let series = [
          {
            name: this.$t('Cyclists'),
            data:
              data?.map(d => {
                return {
                  x: d.label,
                  y: d.countAll,
                };
              }) ?? [],
          },
        ];
        return {
          series,
        };
      }
      return {};
    },
  },
  methods: {
    getBarGraphData() {
      const data = Object.values(
        this.stats.graphRaw?.reduce((r, o) => {
          const date = new Date(o.date);
          const day = weekDays[date.getDay()];
          r[day] = r[day] || { label: day, countAll: 0 };
          r[day].countAll += +o.countAll;
          r[day].index = date.getDay() == 0 ? 7 : date.getDay();
          return r;
        }, {}),
      );
      return data.sort((a, b) => a.index - b.index);
    },
    loadDataByScale() {},
    async dateChanged(dateRange) {
      const dates = dateRange.split('to');
      this.dateRange = dates;
      this.loadData();
    },
    async loadData() {
      //Bar statistics
      const response = await this.$store.dispatch('stats/GET_DATA_IN_RANGE', {
        id: this.id != null ? this.id : this.$route.params.id,
        link: cyclistLinks.GET_COUNTER_BAR,
        direction: this.direction,
        dateFrom: this.dateFrom != null ? this.dateFrom : this.dateRange[0],
        dateTo: this.dateTo != null ? this.dateTo : this.dateRange[1],
      });
      this.stats = response.data;
    },
    async compareDateChanged(dateRange) {
      if (dateRange) {
        const dates = dateRange.split('to');
        this.compareDateRange = [dates[0], dates[1]];
      }
      const response = await this.$store.dispatch('stats/GET_DATA_IN_RANGE', {
        id: this.id != null ? this.id : this.$route.params.id,
        link: cyclistLinks.GET_COUNTER_BAR,
        direction: this.direction,
        dateFrom: this.compareDateRange[0],
        dateTo: this.compareDateRange[1],
      });
      this.compareStats = response.data;
    },
    async compareChanged(state) {
      this.compare = state;
    },
  },
  watch: {
    '$route.params.id': function(id) {
      this.loadData();
    },
  },
};
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';

.select-width {
  width: 300px;
}
</style>
